// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //
import { alpha, darken, lighten, getContrastRatio } from '@mui/material/styles';
import { isLightColor } from '../../utils/colorUtils';

const getContrastText = (background) => {
    const contrastThreshold = 4;
    const lightText = '#fff';
    const darkText = 'rgba(0, 0, 0, 0.87)';

    const contrastWithLight = getContrastRatio(background, lightText);
    return contrastWithLight >= contrastThreshold ? lightText : darkText;
};

const generateColorShades = (mainColor) => {
    const isLight = isLightColor(mainColor);
    return {
        lighter: isLight ? darken(mainColor, 0.5) : lighten(mainColor, 0.9),
        light: isLight ? darken(mainColor, 0.2) : lighten(mainColor, 0.7),
        main: mainColor,
        dark: isLight ? lighten(mainColor, 0.7) : darken(mainColor, 0.2),
        darker: isLight ? lighten(mainColor, 0.9) : darken(mainColor, 0.5),
    };
};


const Theme = (colors, customColors) => {
    const { grey } = colors;
    const greyColors = {
        0: grey[0],
        50: grey[1],
        100: grey[2],
        200: grey[3],
        300: grey[4],
        400: grey[5],
        500: grey[6],
        600: grey[7],
        700: grey[8],
        800: grey[9],
        900: grey[10],
        A50: grey[15],
        A100: grey[11],
        A200: grey[12],
        A400: grey[13],
        A700: grey[14],
        A800: grey[16]
    };

    return {
        primary: generateColorShades(customColors?.primary || '#74E1BF'),
        secondary: generateColorShades(customColors?.secondary || '#4281EE'),
        warning: generateColorShades(customColors?.warning || '#FDC95D'),
        error: generateColorShades(customColors?.error || '#FF6961'),
        grey: greyColors,
        white: '#fff',
        black: {
            light: '#393A3A',
            main: '#26282A',
            dark: '#000000'
        },
    };
};

export default Theme;
/*

return {
        primary: {
            light: '#D3F8E8',
            main: '#74E1BF',
            dark: '#32BC95',
            darker: '#0A725E',
            contrastText
        },
        secondary: {
            lighter: '#F0F6FE',
            light: '#C1DAFC',
            main: '#4281EE',
        },
        error: {
            main: '#FF6961',
        },
        warning: {
            main: '#FDC95D',
            dark: '#CF8E03',
        },
        grey: greyColors,
        white: '#fff',
        black: {
            light: '#393A3A',
            main: '#000000',
            dark: '#000000'
        },
    };
*/