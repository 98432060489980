import './SimulationPreview.css';
import { useGetSimulationByIdQuery } from "../../store/services/simulation-service";
import PropTypes from "prop-types";

SimulationPreview.propTypes = {
    simulationId: PropTypes.string.isRequired
};

export default function SimulationPreview(props) {
    const { simulationId } = props;
    const { data: simData, isLoading: simulationLoading, error: simulationError } = useGetSimulationByIdQuery({
        simId: simulationId,
        includes: '',
    });

    if (simulationLoading) {
        return <div className="simulation-loading">Loading...</div>;
    }

    if (simulationError) {
        return <div className="simulation-error">Error: {simulationError}</div>;
    }

    if (simData) {
        return (
            <div className="email-simulation">
                <div className="email-header">
                    <div className="email-subject">{simData.emailSubject || ""}</div>
                    <div className="email-from">
                        <span className="email-display-name">{simData.emailDisplayName || ""}</span>
                        <span className="email-address"> &lt;{simData.emailFromAddress || ''}&gt;</span>
                    </div>
                </div>
                <div className="email-body" dangerouslySetInnerHTML={{ __html: simData.content }} />
            </div>
        );
    }

    return null;
};
