import { useCallback, useEffect, useRef, useState } from 'react';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { useLazyGetQuicksightEmbedUrlQuery } from "../../store/services/quicksight-service";
import MainCard from "../../components/cards/MainCard";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTheme } from "@mui/material/styles";


export default function ReportsDefault() {
    const [getQuicksightDashboardEmbed, { data: embed, isLoading: isEmbedLoading, error: isEmbedError }] = useLazyGetQuicksightEmbedUrlQuery();
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";

    const quicksightContainer = useRef(null);
    const [embedUrl, setEmbedUrl] = useState(null);
    const [embedError, setEmbedError] = useState(null);
    const [isEmbedded, setIsEmbedded] = useState(false);

    const setEmbed = useCallback(async () => {
        try {
            const frameOptions = {
                url: embedUrl,
                container: quicksightContainer.current,
                width: "100%",
                height: "100%",
                resizeHeightOnSizeChangedEvent: true,
            };
            const contentOptions = {
                toolbarOptions: {
                    export: true,
                    undoRedo: true,
                },
            };
            const embeddingContext = await createEmbeddingContext();
            await embeddingContext.embedDashboard(frameOptions, contentOptions);
            setIsEmbedded(true);
        } catch (err) {
            setEmbedError(err.message || 'An unknown error occurred while embedding the dashboard.');
        }
    }, [embedUrl]);

    useEffect(() => {
        getQuicksightDashboardEmbed().unwrap()
        .then((r) => { setEmbedUrl(r.url) })
        .catch(() => { console.log("Failed") });
    }, [getQuicksightDashboardEmbed]);

    useEffect(() => {
        if (embed && embed.url && embedUrl && !isEmbedded) {
            setEmbed()
            .then(() => { console.log("Success") })
            .catch(() => { console.log("Failed") });
        }
    }, [embed, embedUrl, isEmbedded, setEmbed]);

    if (isEmbedError) {
        return (
            <MainCard boxShadow={true} border={false} sx={{ minHeight: 'calc(100vh - 100px)', display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'background.default' }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={8}>
                        <Stack direction="column" spacing={4} alignItems="center">
                            <ErrorOutlineIcon color="error" style={{ fontSize: 50 }} />
                            <Typography variant="h4" align="center">
                                An error occurred when trying to fetch the embed link.
                            </Typography>
                            <Typography variant="body1" align="center">
                                Please refresh the page or try again later.
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
        );
    }

    if (isEmbedLoading || !embedUrl) {
        return (
            <MainCard boxShadow={true} border={false} sx={{ minHeight: 'calc(100vh - 100px)', display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'background.default'}}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={12}>
                        <Stack direction="column" spacing={4} alignItems="center" width={"100%"}>
                            <CircularProgress color="inherit" size={50} />
                            <Typography variant="h4" align="center">
                                Loading the dashboard...
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
        );
    }

    if (embedError) {
        return (
            <MainCard boxShadow={true} border={false} sx={{ minHeight: 'calc(100vh - 100px)', display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'background.default' }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={8}>
                        <Stack direction="column" spacing={4} alignItems="center">
                            <ErrorOutlineIcon color="error" style={{ fontSize: 50 }} />
                            <Typography variant="h4" align="center">
                                An error occurred when trying to embed the dashboard.
                            </Typography>
                            <Typography variant="body1" align="center">
                                Error: {embedError}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
        );
    }

    return (
        <Box sx={{ width: '100%', height: 'calc(100vh - 140px)' }} ref={quicksightContainer} />
    );
};
