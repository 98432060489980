import MainLayout from "../layout/MainLayout";
import Default from "../pages/landing-page";
import Feed from "../pages/Feed";
import TakeCourse from "../pages/landing-page/TakeCourse";

const UserRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
        {
            path: "/",
            element: <Default />
        },
        {
            path: "/feed",
            element: <Feed />
        }
    ]
};

export default UserRoutes;
