import React, { useState } from "react";
import { Modal, Box, Button, Typography, CircularProgress, IconButton, Snackbar, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useUploadDeletionCsvToS3Mutation } from "../../../store/services/user-service";
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

DeleteCsvModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default function DeleteCsvModal({ open, onClose }) {
    const theme = useTheme();
    const { tenancyId } = useSelector((state) => state.tenancy);
    const [csvFile, setCsvFile] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const [uploadDeletionCsvToS3] = useUploadDeletionCsvToS3Mutation();

    const handleFileChange = (event) => {
        setCsvFile(event.target.files[0]);
    };

    const handleDownloadCSV = () => {
        const link = document.createElement('a');
        link.href = "/csv-files/delete-template.csv";
        link.download = 'delete-users-template.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDeleteUsers = async () => {
        if (!csvFile) {
            setSnackbarMessage("Please select a CSV file.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        setIsDeleting(true);
        if (csvFile.type === "application/vnd.ms-excel" || csvFile.type === "text/csv") {
            try {
                const response = await uploadDeletionCsvToS3({ tenancyId, file: csvFile }).unwrap();
                if (response.success) {
                    setSnackbarMessage("User deletion CSV uploaded successfully.");
                    setSnackbarSeverity("success");
                } else {
                    throw new Error("Upload failed");
                }
            } catch (error) {
                console.log(`Error uploading user deletion CSV: ${error}`);
                setSnackbarMessage("Error uploading user deletion CSV.");
                setSnackbarSeverity("error");
            }
        } else {
            setSnackbarMessage("Invalid file type. Please upload a CSV file.");
            setSnackbarSeverity("error");
        }
        setSnackbarOpen(true);
        setIsDeleting(false);
        onClose();
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="delete-csv-modal-title"
                aria-describedby="delete-csv-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80%",
                        maxWidth: 650,
                        boxShadow: theme.customShadows.z1,
                        backgroundColor: theme.palette.background.default,
                        borderRadius: theme.borderRadius.sm,
                        p: "26px 32px"
                    }}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography id="delete-csv-modal-title" variant="h4" gutterBottom>
                            DELETE USERS VIA CSV
                        </Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <input
                        type="file"
                        accept=".csv,application/vnd.ms-excel,text/csv"
                        onChange={handleFileChange}
                        style={{
                            width: "100%",
                            marginBottom: '20px',
                            border: '2px dashed #000',  
                            padding: '10px',
                            borderRadius: '4px'
                        }}
                    />
                    <Box mt={3} display="flex" justifyContent="flex-start">
                        <Button
                            onClick={handleDownloadCSV}
                            variant="outlined"
                            color="inherit"
                            sx={{ mr: 2, flexGrow: 1 }}
                        >
                            Download CSV Template
                            <DownloadIcon sx={{ ml: 1 }} />
                        </Button>
                        <Button
                            onClick={handleDeleteUsers}
                            variant="contained"
                            color="error"
                            disabled={isDeleting}
                            sx={{ flexGrow: 1 }}
                        >
                            {isDeleting ? <CircularProgress size={24} /> : <>Delete Users <DeleteIcon sx={{ ml: 1 }} /></>}
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }} variant="filled">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}