// ==============================|| OVERRIDES - BUTTON ||============================== //

import { getContrastRatio } from '@mui/material/styles';
import { isLightColor, getTextColor } from '../../utils/colorUtils';


export default function Button(theme) {
    const disabledStyle = {
        backgroundColor: `${theme.palette.grey[200]}`,
        color: `${theme.palette.black.main} !important`, // Ensure black color
    };

    const createButtonStyles = (bgColor, hoverColor) => {
        const textColor = getTextColor(bgColor);
        const hoverTextColor = getTextColor(hoverColor);

        return {
            backgroundColor: bgColor,
            color: textColor,
            textTransform: 'uppercase',
            transition: 'background-color 0.3s, color 0.3s', // Smooth transition for color changes
            '&:hover': {
                backgroundColor: hoverColor,
                color: hoverTextColor,
            },
            '&.Mui-disabled': disabledStyle,
            '&.Mui-selected': {
                backgroundColor: theme.palette.primary.main,
                color: getTextColor(theme.palette.primary.main),
                '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                    color: getTextColor(theme.palette.primary.dark),
                },
            },
        };
    };

    return {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    borderRadius: theme.borderRadius['sm'],
                    fontFamily: 'Inter, sans-serif',
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    '&:active': {
                        boxShadow: 'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    },
                    '&.Mui-disabled': disabledStyle,
                },
                contained: {
                    '&.MuiButton-containedWarning': createButtonStyles(theme.palette.warning.main, theme.palette.warning.light),
                    '&.MuiButton-containedPrimary': createButtonStyles(theme.palette.primary.main, theme.palette.primary.light),
                    '&.MuiButton-containedSecondary': createButtonStyles(theme.palette.secondary.light, theme.palette.secondary.main),
                    '&.MuiButton-containedBlack': createButtonStyles(theme.palette.black.main, theme.palette.black.light),
                },
                outlined: {
                    '&.MuiButton-outlinedBlack': {
                        ...createButtonStyles(theme.palette.white, theme.palette.black.light),
                        borderColor: theme.palette.black.main,
                        '&.Mui-disabled': {
                            ...disabledStyle,
                            borderColor: `${theme.palette.grey}`,
                        },
                    },
                }
            }
        }
    };
}