import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SCHEDULE_API_URL } from "config";

const baseQuery = fetchBaseQuery({
    baseUrl: SCHEDULE_API_URL,
    prepareHeaders: (headers) => {
        const user = localStorage.getItem("user");

        if (user) {
            let userToken = JSON.parse(user).token;
            headers.set("authorization", `Bearer ${userToken}`);
        }
        return headers;
    },
    credentials: "omit"
});

export const schedulerApi = createApi({
    reducerPath: "schedulerApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getSchedulerSettings: builder.query({
            query: ({ tenancyId }) => `scheduler/settings/${tenancyId}`,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status.query(),
            providesTags: (result, error, id) => [{ type: "SchedulerSettings", id }]
        }),
        getUserSchedule: builder.query({
            query: ({ userEmail }) => `scheduler/${userEmail}`,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status.query(),
            providesTags: (result, error, id) => [{ type: "UserSchedule", id }]
        }),
        updateSchedulerSettings: builder.mutation({
            query: ({ tenancyId, data }) => ({
                url: `scheduler/settings/${tenancyId}`,
                method: "PUT",
                body: data
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status.query(),
            invalidatesTags: (result, error, id) => [{ type: "SchedulerSettings", id: id }]
        }),
        updateUserJourney: builder.mutation({
            query: ({ userEmail, data }) => ({
                url: `scheduler/${userEmail}/reschedule`,
                method: "POST",
                body: data
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status.query(),
            invalidatesTags: (result, error, id) => [{ type: "UserSchedule", id: id }]
        })
    })
});

export const {
    useGetSchedulerSettingsQuery,
    useGetUserScheduleQuery,
    useUpdateSchedulerSettingsMutation,
    useUpdateUserJourneyMutation
} = schedulerApi;
