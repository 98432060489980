import { Box, Button, Modal, Typography } from "@mui/material";
import { useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import CourseForm from "../../courses/CourseForm";
import { useCreateCourseMutation } from "../../../store/services/university-service";

export default function AddCourseModal({ open, handleClose }) {
    const theme = useTheme();
    const { tenancyId } = useSelector((state) => state.tenancy);
    const [success, setSuccess] = useState(false);

    const [createCourse] = useCreateCourseMutation();

    const initialData =  {
        courseName: "",
        scoredOutOf: "",
        courseContentPath: "",
        languageCode: "",
        format: "",
        published: false,
        tenancyId: tenancyId
    };


    const handleSaveCourse = async (data) => {
        try {
            data.name = data.courseName;
            const response = await createCourse(data);
            if (response.data && response.data.message === "Ok") {
                setSuccess(true);
            } else {
                setSuccess(false);
            }
        } catch (e) {
            setSuccess(false);
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="add-user-modal-title"
            aria-describedby="add-user-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "60%",
                    height: "70%",
                    boxShadow: theme.customShadows.z1,
                    p: 4,
                    backgroundColor: theme.palette.background.default,
                    borderRadius: theme.borderRadius.sm,
                    overflow: "scroll",
                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                }}
            >
                {success ? (
                    <Box display={"flex"} justifyContent={"center"} flexDirection="column" alignItems={"center"} m={4}>
                        <CheckCircleIcon sx={{ color: "primary.main", fontSize: 80, mb: 2 }} />
                        <Typography variant="h5">Simulation added successfully</Typography>

                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mt={4} width={"100%"}>
                            <Button
                                sx={{ width: "100%" }}
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                ) : (

                    <CourseForm initialData={initialData} onSave={handleSaveCourse} mode={"add-on-demand"} />
                )}
            </Box>

        </Modal>

    );
}