import { Grid, Stack, Typography, Button } from "@mui/material";
import MainCard from "../../components/cards/MainCard";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";

// ==============================|| PAGE NOT FOUND ||============================== //
export default function PageNotFound() {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate("/");
    };

    return (
        <MainCard boxShadow={true} border={false}
                  sx={{ minHeight: "calc(100vh)", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12} md={8}>
                    <Stack direction="column" spacing={4} alignItems="center">
                        <ErrorOutlineIcon color="error" style={{ fontSize: 80 }} />
                        <Typography variant="h3" align="center" color="textPrimary">
                            404 - Page Not Found
                        </Typography>
                        <Typography variant="body1" align="center" color="textSecondary">
                            The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
                        </Typography>
                        <Stack direction="row" spacing={2} justifyContent="center">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<HomeIcon />}
                                onClick={handleGoHome}
                            >
                                Go Home
                            </Button>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </MainCard>
    );
};
