import { Alert, Button, Grid, Snackbar, TextField, CircularProgress } from "@mui/material";
import { useState } from "react";
import {useUpdateTenancyMutation } from "../../store/services/tenancy.service";
import PropTypes from "prop-types";
import { APP_URL } from "../../config";

CompanyInformation.propTypes = {
    data: PropTypes.object.isRequired
};

export default function CompanyInformation(props) {
    const { data } = props;
    const user = JSON.parse(localStorage.getItem("user"));
    const isAdmin = user.role === "Administrators";

    const [updateTenancy] = useUpdateTenancyMutation();

    const [tenancyData, setTenancyData] = useState(data);
    const [isSavingData, setIsSavingData] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSave = (tenancyData) => {
        setIsSavingData(true);
        updateTenancy({ data: tenancyData, tenancyId: tenancyData.tenancyId }).unwrap()
        .then(() => {
            setSnackbarMessage("Company information saved successfully");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        })
        .catch((error) => {
            console.log("Error saving company information:", error);
            setSnackbarMessage("Error saving company information");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        })
        .finally(() => {
            setIsSavingData(false);
        })
    };



    return (
        <Grid container spacing={2}>
            {tenancyData.logo && (
                <Grid item xs={12} sx={{ mr: 1, mb: 2 }}>
                    <img src={`${APP_URL}/${tenancyData.logo}`} alt="Logo" width={160} height={'auto'} className='logo-image' style={{ objectFit: "contain", alignSelf: "center"}} />
                </Grid>
            )}

            <Grid item xs={12} sx={{ mr: 1 }}>
                <TextField
                    label="Company Name"
                    value={tenancyData.name}
                    size="small"
                    disabled={!isAdmin}
                    onChange={(e) => setTenancyData({ ...tenancyData, name: e.target.value })}
                    sx={{ mb: 2, width: "50%" }}
                />
            </Grid>
            <Grid item xs={12} sx={{ mr: 1 }}>
                <TextField
                    label="Address Line 1"
                    value={tenancyData.addressLine1}
                    size="small"
                    onChange={(e) => setTenancyData({ ...tenancyData, addressLine1: e.target.value })}
                    sx={{ mb: 2, width: "50%" }}
                />
            </Grid>
            <Grid item xs={12} sx={{ mr: 1 }}>
                <TextField
                    label="Address Line 2"
                    value={tenancyData.addressLine2}
                    size="small"
                    onChange={(e) => setTenancyData({ ...tenancyData, addressLine2: e.target.value })}
                    sx={{ mb: 2, width: "50%" }}
                />
            </Grid>
            <Grid item xs={12} sx={{ mr: 1 }}>
                <TextField
                    label="Address Line 3"
                    value={tenancyData.addressLine3}
                    size="small"
                    onChange={(e) => setTenancyData({ ...tenancyData, addressLine3: e.target.value })}
                    sx={{ mb: 2, width: "50%" }}
                />
            </Grid>
            <Grid item xs={12} sx={{ mr: 1 }}>
                <TextField
                    label="Address Line 4"
                    value={tenancyData.addressLine4}
                    size="small"
                    onChange={(e) => setTenancyData({ ...tenancyData, addressLine4: e.target.value })}
                    sx={{ mb: 2, width: "50%" }}
                />
            </Grid>
            <Grid item xs={12} sx={{ mr: 1 }}>
                <TextField
                    label="Postcode"
                    value={tenancyData.postcode}
                    size="small"
                    onChange={(e) => setTenancyData({ ...tenancyData, postcode: e.target.value })}
                    sx={{ mb: 2, width: "50%" }}
                />
            </Grid>
            <Grid item xs={12} sx={{ mr: 1 }}>
                <TextField
                    label="Parent Tenancy"
                    value={tenancyData.parentTenancyId}
                    size="small"
                    disabled={!isAdmin}
                    onChange={(e) => setTenancyData({ ...tenancyData, parentTenancyId: e.target.value })}
                    sx={{ mb: 2, width: "50%" }}
                />
            </Grid>

            <Grid item xs={12}>
                <Button variant="outlined" color="black" onClick={() => {window.location.reload()}} sx={{ width: "10%" }}>
                    Cancel
                </Button>
                <Button onClick={() => handleSave(tenancyData)} variant="contained" color="warning" sx={{ ml: 2, width: "10%" }}>
                    {isSavingData ? < CircularProgress size={24} color="inherit" sx={{ mr: 1 }} /> : "Save"}
                </Button>
            </Grid>


            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={snackbarOpen} autoHideDuration={3000}
                      onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled" sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </Grid>
    );
}