import React, { useRef, useEffect } from 'react';
import PropTypes from "prop-types";
import {
    Box, Card, CardContent,
    FormControlLabel, Switch,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    TextField,
    InputAdornment
} from "@mui/material";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MainCard from "../../../components/cards/MainCard";
import { useTheme } from "@mui/material/styles";

DeliverySection.propTypes = {
    delivery: PropTypes.string.isRequired,
    handleDeliveryChange: PropTypes.func.isRequired,
    scheduledDate: PropTypes.instanceOf(Date),
    setScheduledDate: PropTypes.func.isRequired,
    startDate: PropTypes.instanceOf(Date),
    setStartDate: PropTypes.func.isRequired,
    endDate: PropTypes.instanceOf(Date),
    setEndDate: PropTypes.func.isRequired,
    pauseOnWeekends: PropTypes.bool.isRequired,
    setPauseOnWeekends: PropTypes.func.isRequired
};

export default function DeliverySection({
                                            delivery,
                                            handleDeliveryChange,
                                            scheduledDate,
                                            setScheduledDate,
                                            startDate,
                                            setStartDate,
                                            endDate,
                                            setEndDate,
                                            pauseOnWeekends,
                                            setPauseOnWeekends
                                        }) {
    const scheduledDateRef = useRef(null);
    const startDateRef = useRef(null);
    const endDateRef = useRef(null);

    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";

    const flatpickrOptions = {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        time_24hr: true,
        minuteIncrement: 1,
        allowInput: true,
        onOpen: (selectedDates, dateStr, instance) => {
            if (instance.input.value) {
                instance.setDate(instance.input.value, false);
            }
        },
        onClose: (selectedDates, dateStr, instance) => {
            if (selectedDates[0]) {
                instance.setDate(selectedDates[0], false);
            }
        }
    };

    const formatDate = (date) => {
        return date instanceof Date ? flatpickr.formatDate(date, "d MMM Y H:i") : "";
    };

    useEffect(() => {
        const initFlatpickr = (ref, defaultDate, setDate) => {
            if (ref.current) {
                const fp = flatpickr(ref.current, {
                    ...flatpickrOptions,
                    defaultDate: defaultDate instanceof Date ? defaultDate : undefined,
                    onChange: ([date]) => setDate(date),
                });
                return fp;
            }
        };

        const scheduledFp = initFlatpickr(scheduledDateRef, scheduledDate, setScheduledDate);
        const startFp = initFlatpickr(startDateRef, startDate, setStartDate);
        const endFp = initFlatpickr(endDateRef, endDate, setEndDate);

        return () => {
            [scheduledFp, startFp, endFp].forEach(fp => fp && fp.destroy());
        };
    }, [scheduledDate, startDate, endDate, setScheduledDate, setStartDate, setEndDate]);

    const isScheduledDateValid = scheduledDate && scheduledDate > new Date();
    const isBatchSendValid = startDate && endDate && startDate < endDate && (endDate.getTime() - startDate.getTime()) >= 10800000;

    const renderDateTimeField = (label, ref, value, sx = {}, error = false) => (
        <TextField
            label={label}
            inputRef={ref}
            defaultValue={formatDate(value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            sx={sx}
            error={error}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <CalendarTodayIcon 
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (ref.current && ref.current._flatpickr) {
                                    ref.current._flatpickr.open();
                                }
                            }}
                        />
                    </InputAdornment>
                ),
            }}
        />
    );

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
                <ToggleButtonGroup
                    exclusive
                    value={delivery}
                    onChange={(event, newDelivery) => {
                        if (newDelivery !== null) {
                            handleDeliveryChange(event, newDelivery);
                        }
                    }}
                    aria-label="delivery"
                    sx={{
                        '& .MuiToggleButton-root': {
                            whiteSpace: 'nowrap'
                        }
                    }}
                >
                    <ToggleButton value="sendNow" aria-label="Send Now" >
                        Send Immediately
                    </ToggleButton>
                    <ToggleButton value="schedule" aria-label="Schedule">
                        Send later
                    </ToggleButton>
                    <ToggleButton value="batchSend" aria-label="Batch Send">
                        Send in batches
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>

            {(delivery === "sendNow" )&& (
                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: 'center', mt: 12 }}>
                    <MainCard sx={{
                        width: "50%",
                        textAlign: "center",
                        mt: 5,
                        borderRadius: 2,
                        bgcolor: lightMode ? theme.palette.white : theme.palette.grey[700],
                    }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Courses/Simulations will be sent immediately.
                            </Typography>
                        </CardContent>
                    </MainCard>
                </Box>
            )}

            {delivery === "schedule" && (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {renderDateTimeField("Scheduled Date and Time", scheduledDateRef, scheduledDate, { width: "50%" }, !isScheduledDateValid)}
                    {!isScheduledDateValid && (
                        <Typography variant="caption" sx={{ color: "error.main", mt: 1 }}>
                            Scheduled date and time must be in the future.
                        </Typography>
                    )}
                </Box>
            )}

            {delivery === "batchSend" && (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Box sx={{ width: "50%", mb: 2 }}>
                        {renderDateTimeField("Start Date and Time", startDateRef, startDate, { mb: 2 }, !isBatchSendValid)}
                        {renderDateTimeField("End Date and Time", endDateRef, endDate, {}, !isBatchSendValid)}
                    </Box>
                    {!isBatchSendValid && (
                        <Typography variant="caption" sx={{ color: "error.main", mt: 1, mb: 2 }}>
                            Start date and time must be before end date and time, and the difference must be at least 3 hours.
                        </Typography>
                    )}
                    <FormControlLabel
                        control={
                            <Switch
                                checked={pauseOnWeekends}
                                onChange={(event) => setPauseOnWeekends(event.target.checked)}
                            />
                        }
                        label="Pause on Weekends"
                    />
                </Box>
            )}
        </Box>
    );
}