import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

const SimulationSkeletonComponent = () => {
    return (
        <Box>
            <Skeleton variant="rounded" width={100} height={40} sx={{ mb: 2 }} />
            <Skeleton variant="text" width="30%" height={80} sx={{ mb: 4 }} />

            <Stack spacing={3} direction="column">
                <Skeleton variant="rounded" width="50%" height={30} />
                <Skeleton variant="rounded" width="50%" height={30} />
                <Skeleton variant="rounded" width="50%" height={30} />
                <Skeleton variant="rounded" width="50%" height={30} />
                <Skeleton variant="rounded" width="50%" height={30} />
                <Skeleton variant="rounded" width="50%" height={30} />
                <Skeleton variant="rounded" width="50%" height={30} />
            </Stack>

            <Skeleton variant="rounded" width={"30%"} height={40} sx={{ my: 3 }} />


            <Stack spacing={2} direction="row">
                <Skeleton variant="rectangular" width="100%" height={300} />
            </Stack>
        </Box>
    );
};

export default SimulationSkeletonComponent;
