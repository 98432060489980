// material-ui
import logoWhite from 'assets/images/icons/logo.svg';
import logoBlack from 'assets/images/icons/logo_b.svg';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */
export const Logo100 = () => {
    return <><img src={logoWhite} alt="Logo" width="180" style={{marginTop: 8}} /></>;
};

export const Logo200 = () => {
    return <><img src={logoBlack} alt="Logo" width="180" style={{marginTop: 8}} /></>;
};
