import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useGetSimulationsQuery, useUploadSimulationCsvMutation, useUploadToS3Mutation } from "../../store/services/simulation-service";
import { useSelector } from "react-redux";
import { Alert, Autocomplete, Button, IconButton, Modal, Snackbar, TextField, Box, useMediaQuery } from "@mui/material";
import SimulationPreview from "../on-demand/SimulationPreview";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import Rating from "@mui/material/Rating";
import Table from "../../components/Table/Table";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from "react-router-dom";
import UsersTable from "../users/UsersTable";
import AddSimulationModal from "../on-demand/CreateOnDemand/AddSimulationModal";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import { useTheme } from "@mui/material/styles";
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import ShuffleIcon from "@mui/icons-material/Shuffle";

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    boxShadow: 24,
    borderRadius: 4,
    height: "80%",
    bgcolor: "background.paper",
    overflow: "scroll"
};

const userModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    boxShadow: 24,
    borderRadius: 4,
    bgcolor: "background.paper"
};

SimulationTable.propTypes = {
    type: PropTypes.oneOf(["edit", "select"]).isRequired,
    simulationIds: PropTypes.array,
    setSimulationId: PropTypes.func,
    onRandomSelect: PropTypes.func
};

export default function SimulationTable(props) {
    const { type, simulationIds, setSimulationId, onRandomSelect} = props;
    const navigate = useNavigate();
    const theme = useTheme();
    const lightMode = theme.palette.mode === 'light';
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { tenancyId } = useSelector((state) => state.tenancy);
    const additionalQueryParams = type === "edit" ? "" : `&published=true&tenancyId=${tenancyId}`;

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 100,
        page: 0
    });

    const { data: simulationData, isLoading: simulationsLoading, error: simulationsError } = useGetSimulationsQuery({
        perPage: paginationModel.pageSize,
        page: paginationModel.page,
        includes: "",
        query: "",
        additionalQueryParams: additionalQueryParams
    });

    const [uploadToS3] = useUploadToS3Mutation();
    const [uploadSimulationCsv] = useUploadSimulationCsvMutation();

    const [selectedSimulation, setSelectedSimulation] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selected, setSelected] = useState([]);
    const [simulationTypes, setSimulationTypes] = useState([]);
    const [filteredSimulations, setFilteredSimulations] = useState([]);
    const [simulationType, setSimulationType] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const fileInputRef = useRef(null);
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isTenancyAdmin, setIsTenancyAdmin] = useState(false);
    const [isUser, setIsUser] = useState(false);

    const [addSimulationModal, setAddSimulationModal] = useState(false);

    useEffect(() => {
        if (simulationData) {
            const types = simulationData.map((simulation) => simulation.simulationType);
            const uniqueTypes = [...new Set(types)];
            setSimulationTypes(uniqueTypes);
        }
    }, [simulationData]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setIsAdmin(user && user.role === "Administrators");
        setIsTenancyAdmin(user && user.role === "TenancyAdministrators")
        setIsUser(user && user.role === "");
    }, []);

    const handleSimulationTypeChange = (event, value) => {
        if (value) {
            setSimulationType(value);
            const filtered = simulationData.filter((simulation) => simulation.simulationType === value);
            setFilteredSimulations(filtered);
        } else {
            setSimulationType(null);
            setFilteredSimulations([]);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            handleSendSimUsingCSV(file);
        }
    };

    /*
    const handleSendUsingCSV = async (file) => {
        if (file.type === "application/vnd.ms-excel" || file.type === "text/csv") {
            await uploadToS3({ tenancyId: tenancyId, file: file }).unwrap().then((response) => {
                    console.log("Upload to S3 response:", response);
                    if (response.success) {
                        setSnackbarMessage("Users uploaded successfully.");
                        setSnackbarSeverity("success");
                    } else {
                        setSnackbarMessage("Error uploading users.");
                        setSnackbarSeverity("error");
                    }
                    setSnackbarOpen(true);
                }
            ).catch((error) => {
                console.log("Error uploading to S3:", error);
                setSnackbarMessage("Error uploading users.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            });
        } else {
            setSnackbarMessage("Invalid file type. Please upload a CSV file.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };
    */

    const handleSendSimUsingCSV = async (file) => {
        if (file.type === "application/vnd.ms-excel" || file.type === "text/csv") {
            try {
                const response = await uploadSimulationCsv({ file }).unwrap();
                setSnackbarMessage(response.success ?
                    "Upload successful" :
                    "Error uploading CSV");
                setSnackbarSeverity(response.success ? "success" : "error");
                setSnackbarOpen(true);
            } catch (error) {
                console.error("Error uploading CSV:", error);
                setSnackbarMessage(error.data || "Error uploading CSV");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        } else {
            setSnackbarMessage("Invalid file type. Please upload a CSV file.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const handleAddSimulation = () => {
        if (type === "edit") {
            navigate("/simulations/add");
        } else {
            setAddSimulationModal(true);
        }
    };

    const handleViewSimulation = (simulation) => {
        navigate(`/simulations/${simulation.id}`);
    };

    const handleEditSimulation = (simulation) => {
        navigate(`/simulations/edit/${simulation.id}`);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleSelectSimulation = (simulationId) => {
        setSelected(simulationId);
    };

    const handlePreview = (row) => {
        setSelectedSimulation(row);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleCloseUserModal = () => {
        setSelected([]);
        setUserModalOpen(false);
    };

    const handleOpenUserModal = (simulationId) => {
        setSelected(simulationId);
        setUserModalOpen(true);
    };

    const handleRandomSelection = () => {
        if (simulationData) {
            const randomSimulations = simulationData.filter(simulation => simulation.randomSend);
            if (randomSimulations.length > 0) {
                setSimulationId(randomSimulations);
                setSnackbarMessage(`Selected ${randomSimulations.length} simulation(s) with randomSend enabled.`);
                setSnackbarSeverity("success");
                setSnackbarOpen(true);

                // Call the onRandomSelect prop to navigate to the next step
                if (onRandomSelect) {
                    onRandomSelect();
                }
            } else {
                setSnackbarMessage("No simulations with randomSend enabled.");
                setSnackbarSeverity("info");
                setSnackbarOpen(true);
            }
        }
    }

    const ResponsiveSendUserButton = ({ dataRow }) => (
        <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => handleOpenUserModal(dataRow.id)}
            sx={{
                textTransform: 'none',
                whiteSpace: 'nowrap',
                minWidth: 0,

            }}
        >
            {isMobile ? "Send" : "Send to User"}
        </Button>
    );

    const toolbarActions = [
        {
            render: () => (
                <Box width={"100%"} justifyContent={"flex-end"} display={"flex"}>
                    <Autocomplete
                        id="simulation-type-autocomplete"
                        disableClearable={!simulationType}
                        sx={{ mr: 4, width: 120 }}
                        size="small"
                        multiple={false}
                        value={simulationType || "All"}
                        options={simulationTypes}
                        onChange={handleSimulationTypeChange}
                        renderInput={(params) => <TextField {...params} style={{ backgroundColor: 'background.default' }} label="Type" />}
                    />

                    {type === "select" && isAdmin && (
                        <Button
                            size="medium"
                            variant="contained"
                            color="warning"
                            sx={{ textTransform: "capitalize", mr: 2 }}
                            onClick={handleRandomSelection}
                            endIcon={<ShuffleIcon />}
                        >
                            Random
                        </Button>
                    )}
                    { !isUser && <Button
                        size="medium"
                        variant="contained"
                        sx={{ textTransform: "capitalize" }}
                        onClick={handleAddSimulation}
                        endIcon={<AddIcon />}
                    >
                        Add Sim
                    </Button>} 
                    {type !== "select" && isAdmin && (
                        <>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                onChange={handleFileChange}
                            />
                            <Button
                                size="medium"
                                variant="contained"
                                color="secondary"
                                sx={{ bgcolor: (theme) => theme.palette.secondary.lighter, ml: 2,  }}
                                onClick={() => fileInputRef.current.click()}
                            >
                                send using csv
                            </Button>
                        </>
                    )} 
                </Box>
            )
        }
    ];

    let headCells = [
        {
            id: "select",
            label: "Select",
            render: (dataRow) => {
                const isSelected = simulationIds?.map(sim => sim.id).includes(dataRow.id);
                return (
                    <IconButton
                        size="small"
                        onClick={() => {
                            if (isSelected) {
                                setSimulationId(simulationIds?.filter(sim => sim.id !== dataRow.id));
                            } else {
                                setSimulationId([...simulationIds, dataRow]);
                            }
                        }}
                    >
                        {isSelected ? <CheckBoxRoundedIcon color="warning" /> : <CheckBoxOutlineBlankRoundedIcon color={lightMode ? "black" : "white"} />}
                    </IconButton>
                );
            },
            initialVisible: type === "select"
        },
        {
            id: "preview",
            label: "Preview",
            render: (dataRow) => (
                <>
                    <Button onClick={() => handlePreview(dataRow)} size="small" variant="contained" color="warning">
                        Preview
                    </Button>
                </>
            ),
            initialVisible: true
        },
        {
            id: "edit",
            label: "Edit",
            render: (dataRow) => {
                const isVisible = isAdmin || (isTenancyAdmin && String(dataRow.tenancyId) === String(tenancyId));

                return (
                    <>
                        <Button
                            onClick={() => handleEditSimulation(dataRow)}
                            size="small"
                            variant="contained"
                            color="secondary"
                            sx={{display: isVisible ? "block" : "none"}}
                        >
                            Edit
                        </Button>
                    </>
                );
            },
            initialVisible: true
        },
        {
            id: "send-user",
            label: type === "edit" ? "" : "Send User",
            render: (dataRow) => <ResponsiveSendUserButton dataRow={dataRow} />,
            initialVisible: type === "edit"
        },
        {
            id: "simulationName",
            label: "Simulation Name",
            initialVisible: true
        },
        {
            id: "emailFromAddress",
            label: "Email From Address",
            initialVisible: type === "select"
        },
        {
            id: "simulationType",
            label: "Type",
            initialVisible: true
        },
        {
            id: "difficultyRating",
            label: "Difficulty",
            render: (dataRow) => (
                <Rating
                    name="difficulty-rating"
                    value={dataRow.difficultyRating}
                    max={3}
                    readOnly
                    icon={<StarRoundedIcon fontSize="inherit" />}
                    emptyIcon={<StarRoundedIcon fontSize="inherit" />}
                />
            ),
            initialVisible: true
        },
        {
            id: "linkedUniversityCourseId",
            label: "Linked Courses",
            initialVisible: type === "edit"
        },
    ];

    if (modalOpen) {
        return (
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="simulation-preview-title"
                aria-describedby="simulation-preview-description"
            >
                <Box sx={modalStyle}>
                    <SimulationPreview simulationId={selectedSimulation.id} />
                </Box>
            </Modal>
        );
    } else {
        return (
            <>
                <Table
                    name={"Simulation"}
                    data={filteredSimulations.length > 0 ? filteredSimulations : simulationData}
                    error={simulationsError}
                    loading={simulationsLoading}
                    headCells={headCells}
                    search={true}
                    editColumn={false}
                    checkbox={false}
                    toolbarActions={toolbarActions}
                    showToolbarActions={true}
                    onDelete={() => console.log("Delete Simulation Not Allowed")}
                    showDelete={false}
                    onCellClick={handleViewSimulation}
                    selected={selected}
                    setSelected={handleSelectSimulation}
                    maxTableHeight={type === "select" ? "calc(100vh - 350px)" : "calc(100vh - 250px)"}
                    initialVisibleColumns={headCells.filter(cell => cell.initialVisible).map(cell => cell.label)}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                />

                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>

                <Modal open={userModalOpen} onClose={handleCloseUserModal} aria-labelledby="user-selection-modal"
                       aria-describedby="user-selection-description">
                    <Box sx={userModalStyle}>
                        <UsersTable
                            type={"send"}
                            userId={selectedUserId}
                            setUserId={setSelectedUserId}
                            formatId={selected}
                            format={"simulation"}
                            CSVButton
                        />
                    </Box>
                </Modal>
                <AddSimulationModal open={addSimulationModal} handleClose={() => setAddSimulationModal(false)} />
            </>
        );
    }
}
