// ----- themeColorManager.js -----

export const saveThemeColors = async (themeColors) => {
    localStorage.setItem('themeColors', JSON.stringify(themeColors));
    return Promise.resolve();
};

export const saveThemeMode = async (mode) => {
    localStorage.setItem('mode', JSON.stringify(mode));
    return Promise.resolve();
};

export const getThemeColors = () => {
    const storedColors = localStorage.getItem('themeColors');
    return storedColors ? JSON.parse(storedColors) : null;
};

export const getThemeMode = () => {
    const mode = localStorage.getItem('mode');
    return mode ? JSON.parse(mode) : null;
};
