import React, { useState } from "react";
import Table from "../../components/Table/Table";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useGetJourneysQuery } from "../../store/services/journey-service";
import JourneyEditForm from "./JourneyEditForm";

export default function JourneyTable() {
    const navigate = useNavigate();

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 500,
        page: 0,
    });

    const { data: journeysData, isLoading: journeysLoading, error: journeysError } = useGetJourneysQuery({
        perPage: paginationModel.pageSize,
        page: paginationModel.page,
        includes: "",
        query: "",
        additionalQueryParams: ""
    });

    const [selected, setSelected] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [editingJourney, setEditingJourney] = useState(null);

    const handleAddJourney = () => {
        navigate("/journeys/add");
    };

    const handleViewJourney = (journey) => {
        navigate(`/journeys/${journey.id}`);
    };

    const handleEditJourney = (journey) => {
        setEditingJourney(journey);
        setOpenEditModal(true);
    };

    const handleCloseEditModal = () => {
        setOpenEditModal(false);
        setEditingJourney(null);
    };

    const handleSaveJourney = (updatedJourney) => {
        handleCloseEditModal();
    };

    const headCells = [
        {
            id: "edit",
            label: "Edit",
            initialVisible: true, 
            render: (journey) => (
                <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={() => handleEditJourney(journey)}
                >
                    Edit
                </Button>
            ),
        },
        { id: "id", label: "ID", initialVisible: true },
        { id: "name", label: "Journey Name", initialVisible: true }
    ];

    const toolbarActions = [{
        render: () => (
            <Box>
                <Button
                    size="medium"
                    variant="contained"
                    sx={{ textTransform: "capitalize" }}
                    onClick={handleAddJourney}
                    endIcon={<AddIcon />}
                >
                    Add Journey
                </Button>
            </Box>)
    }];

    return (
        <>
            <Table
                name={"Journeys"}
                data={journeysData}
                error={journeysError}
                loading={journeysLoading}
                headCells={headCells}
                search={true}
                editColumn={false}
                checkbox={false}
                toolbarActions={toolbarActions}
                showToolbarActions={true}
                onDelete={() => console.log("Delete Journeys Not Allowed")}
                onCellClick={handleViewJourney}
                selected={selected}
                setSelected={setSelected}
                initialVisibleColumns={headCells.filter(cell => cell.initialVisible).map(cell => cell.label)}
                maxTableHeight={"calc(100vh - 250px)"}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
            />
            <Dialog open={openEditModal} onClose={handleCloseEditModal} maxWidth="md" fullWidth  PaperProps={{ sx: { borderRadius: "8px", backgroundColor: "background.default" } }}>
                <DialogContent>
                    {editingJourney && (
                        <JourneyEditForm
                            initialData={editingJourney}
                            onSave={handleSaveJourney}
                            setSnackbarOpen={() => {}}
                            setSnackbarMessage={() => {}}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="black" onClick={handleCloseEditModal}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}