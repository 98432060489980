import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material-ui
import { Box, Divider, List, Typography } from "@mui/material";

// project import
import NavItem from './NavItem';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const menu = useSelector((state) => state.menu);
    const { drawerOpen } = menu;

    const navCollapse = item.children?.map((menuItem) => {
        switch (menuItem.type) {
            case 'collapse':
                return (
                    <Typography key={menuItem.id} variant="caption" color="error" sx={{ p: 2.5 }}>
                        collapse - only available in paid version
                    </Typography>
                );
            case 'item':
                return <NavItem key={menuItem.id} item={menuItem} level={1} />;
            default:
                return (
                    <Typography key={menuItem.id} variant="h6" color="error" align="center">
                        Fix - Group Collapse or Items
                    </Typography>
                );
        }
    });

    return (
        <>
            <List sx={{ mb: 0, py: 0, zIndex: 0 }}>
                {navCollapse}
            </List>

            {user?.role === 'Administrators' && item.divider && <Divider textAlign='center'  variant='middle' sx={{mt: 1, mb: 1}} /> }
        </>

    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
