import tenancyAdminItems from './tenancyAdminItems';
import adminItems from './adminItems';
import userItems from "./userItems";

const menuItems = () => {
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const { role } = user;
    const items = [userItems];

    const filterMenuItems = (menuItems, userRole) => {
        return {
            ...menuItems,
            children: menuItems.children.filter(item => {

                if (item.id === 'reports1') {
                    if (userRole === 'TenancyAdministrators') return user.enableReporting;
                    else return user.reportingLevel !== null && user.enableReporting;
                }
                if (item.id === 'ondemand1') {
                    if (userRole === 'TenancyAdministrators') return user.enableOndemand;
                    else return user.onDemandLevel !== null && user.enableOndemand;;
                }
                if (userRole === "TenancyAdministrators") return true;
            })
        };
    };

    if (role === 'Administrators') {
        items.push(tenancyAdminItems, adminItems);
    } else if (role === 'TenancyAdministrators') {
        items.push(filterMenuItems(tenancyAdminItems, role));
    } else if (role === '') {
        items.push(filterMenuItems(tenancyAdminItems, role));
    }

    return { items };
};

export default menuItems;