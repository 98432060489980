import React, { useState, useCallback } from "react";
import Table from "../../components/Table/Table";
import { Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CopyIcon from "@mui/icons-material/ContentCopy";
import TickIcon from "@mui/icons-material/Check";
import DownloadIcon from '@mui/icons-material/Download'; 

export default function JourneyTable() {
    const navigate = useNavigate();
    const domainsData = [
        { domain: "microsoft-notifications.co.uk", mailFrom: "mail.microsoft-notifications.co.uk" },
        { domain: "hr-staff-updates.com", mailFrom: "mail.hr-staff-updates.com" },
        { domain: "bluuebeams.com", mailFrom: "info.bluuebeams.com" },
        { domain: "google-account-team.com", mailFrom: "mail.google-account-team.com" },
        { domain: "google-notifications.co.uk", mailFrom: "mail.google-notifications.co.uk" },
        { domain: "noreply-deliveroocredit.co.uk", mailFrom: "mail.noreply-deliveroocredit.co.uk" },
        { domain: "onedrivesharing.com", mailFrom: "info.onedrivesharing.com" },
        { domain: "noreply-amazon.co.uk", mailFrom: "mail.noreply-amazon.co.uk" },
        { domain: "dpdupdates.co.uk", mailFrom: "email.dpdupdates.co.uk" },
        { domain: "electrosoftt.com", mailFrom: "info.electrosoftt.com" },
        { domain: "file-transf3rs.com", mailFrom: "mail.file-transf3rs.com" },
        { domain: "linkedin-network.com", mailFrom: "mail.linkedin-network.com" },
        { domain: "insightfulsurveys.com", mailFrom: "mail.insightfulsurveys.com" },
        { domain: "dropbox-notifications.co.uk", mailFrom: "mail.dropbox-notifications.co.uk" },
        { domain: "windowsmessages.com", mailFrom: "mail.windowsmessages.com" },
        { domain: "e-cards-mail.com", mailFrom: "promo.e-cards-mail.com" },
        { domain: "noreply-linkedinverify.co.uk", mailFrom: "info.noreply-linkedinverify.co.uk" },
        { domain: "microsoftaccountalert.com", mailFrom: "security.microsoftaccountalert.com" },
        { domain: "royaal-maill.com", mailFrom: "info.royaal-maill.com" },
        { domain: "bankfraudteam.com", mailFrom: "mail.bankfraudteam.com" },
        { domain: "isecurity-alerts.com", mailFrom: "apple.isecurity-alerts.com" },
        { domain: "amazoneorder.com", mailFrom: "mail.amazoneorder.com" },
        { domain: "fa-uk.com", mailFrom: "mail.fa-uk.com" },
        { domain: "coffee-vouchers.com", mailFrom: "portal.coffee-vouchers.com" },
        { domain: "who-travel-updates.com", mailFrom: "info.who-travel-updates.com" },
        { domain: "365invoices.com", mailFrom: "mail.365invoices.com" },
        { domain: "just-eat-voucher.co.uk", mailFrom: "info.just-eat-voucher.co.uk" },
        { domain: "gmaillogin.co.uk", mailFrom: "info.gmaillogin.co.uk" },
        { domain: "mail365-team.com", mailFrom: "info.mail365-team.com" },
        { domain: "google-notificatons.com", mailFrom: "info.google-notificatons.com" },
        { domain: "netflix-password.co.uk", mailFrom: "mail.netflix-password.co.uk" },
        { domain: "netlfix-update-details.com", mailFrom: "info.netlfix-update-details.com" },
        { domain: "netlixnotifications.co.uk", mailFrom: "info.netlixnotifications.co.uk" },
        { domain: "traffordgov.com", mailFrom: "mail.traffordgov.com" },
        { domain: "accessyourcloud.co.uk", mailFrom: "secure.accessyourcloud.co.uk" },
        { domain: "microsoft-security-alerts.com", mailFrom: "info.microsoft-security-alerts.com" },
        { domain: "fileboxshare.com", mailFrom: "drive.fileboxshare.com" },
        { domain: "noreply-ubercredit.co.uk", mailFrom: "mail.noreply-ubercredit.co.uk" },
        { domain: "dhlshipping.co.uk", mailFrom: "info.dhlshipping.co.uk" },
        { domain: "theaccountsgroup.com", mailFrom: "mail.theaccountsgroup.com" },
        { domain: "staff-payroll-updates.com", mailFrom: "mail.staff-payroll-updates.com" },
        { domain: "mydeliverytracker.com", mailFrom: "info.mydeliverytracker.com" },
        { domain: "freshworked.co.uk", mailFrom: "info.freshworked.co.uk" },
        { domain: "webcontracttar.co.uk", mailFrom: "mail.webcontracttar.co.uk" },
        { domain: "noreply-hmrcupdate.co.uk", mailFrom: "mail.noreply-hmrcupdate.co.uk" },
        { domain: "nhs-antibodytest.co.uk", mailFrom: "mail.nhs-antibodytest.co.uk" },
        { domain: "noreply-sage.com", mailFrom: "mail.noreply-sage.com" }
    ];
    const [selected, setSelected] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 100,
        page: 0
    });
    const [copiedStates, setCopiedStates] = useState({});

    const copyToClipboard = useCallback(async (text, id) => {
        try {
            await navigator.clipboard.writeText(text);

            // Set the 'Copied' state for this button
            setCopiedStates(prev => ({ ...prev, [id]: true }));

            // Reset the state after 2 seconds
            setTimeout(() => {
                setCopiedStates(prev => ({ ...prev, [id]: false }));
            }, 1200);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    }, []);

    const CopyButton = ({ text, id, label }) => (
        <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => copyToClipboard(text, id)}
            sx={{
                width: 'auto',  
                minWidth: 'fit-content',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {copiedStates[id] ? <TickIcon fontSize="small" /> : <CopyIcon fontSize="small" />}
        </Button>
    );

    const handleDownloadDomainsCSV = () => {
        const link = document.createElement('a');
        link.href = "/csv-files/domain-list.csv";
        link.download = 'domain-list.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDownloadMailFromCSV = () => {
        const link = document.createElement('a');
        link.href = "/csv-files/mailfrom-list.csv";
        link.download = 'mailfrom-list.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const toolbarActions = [
        {
            render: () => (
                <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", mb: 3 }}>
                    <Button
                        size="medium"
                        variant="contained"
                        onClick={handleDownloadDomainsCSV}
                        endIcon={<DownloadIcon />}
                    >
                        Download Domains CSV
                    </Button>
                    <Button
                        size="medium"
                        variant="contained"
                        sx={{ marginLeft: 2 }}
                        onClick={handleDownloadMailFromCSV}
                        endIcon={<DownloadIcon />}
                    >
                        Download Mail-from CSV
                    </Button>
                </Box>
            )
        }
    ];

    const headCells = [
        { id: "domain", label: "Domain", initialVisible: true },
        {
            id: "copyDomain",
            label: "Copy",
            initialVisible: true,
            render: (row) => (
                <CopyButton text={row.domain} id={`copyDomain-${row.domain}`} label="Copy Domain" />
            ),
        },
        { id: "mailFrom", label: "Mail From", initialVisible: true },
        {
            id: "copyMailFrom",
            label: "Copy",
            initialVisible: true,
            render: (row) => (
                <CopyButton text={row.mailFrom} id={`copyMailFrom-${row.domain}`} label="Copy Mail From" />
            ),
        }
    ];

    return (
        <>
            <Table
                name={"Domains"}
                data={domainsData}
                error={false}
                loading={false}
                headCells={headCells}
                search={true}
                editColumn={false}
                checkbox={false}
                toolbarActions={toolbarActions}
                showToolbarActions={true}
                onDelete={() => console.log("Delete Domains Not Allowed")}
                selected={selected}
                setSelected={setSelected}
                initialVisibleColumns={headCells.filter(cell => cell.initialVisible).map(cell => cell.label)}
                maxTableHeight={"calc(100vh - 250px)"}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
            />
        </>
    );
}