import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logout, token } from "../reducers/auth";
import { TENANCY_API_URL } from 'config';

const baseQuery = fetchBaseQuery({
    baseUrl: TENANCY_API_URL,
    prepareHeaders: (headers) => {
        const user = localStorage.getItem('user');
        if (user) {
            try {
                const userToken = JSON.parse(user).token;
                headers.set('authorization', `Bearer ${userToken}`);
            } catch (error) {
                console.error('Error parsing user token:', error);
            }
        }
        return headers;
    },
    credentials: "omit"
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        const refreshResult = await baseQuery('auth/login', api, extraOptions);
        if (refreshResult.data) {
            api.dispatch(token(refreshResult.data));
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }
    return result;
};

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getUserById: builder.query({
            query: ({ userId }) => `user/${userId}`,
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
            providesTags: (result, error, id) => [{ type: "Users", id }]
        }),
        getUsers: builder.query({
            query: ({ perPage, page, includes, query, searchTerm, tenancyId, adminFilter, extendedResults }) =>
                `user/?perPage=${perPage}&page=${page}&includes=${includes}&query=${query}&searchTerm=${searchTerm}&tenancyId=${tenancyId}&adminFilter=${adminFilter}&extendedResults=${extendedResults}`,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
            providesTags: (result) =>
                result?.data
                    ? [...result.data.map(({ id }) => ({ type: "Users", id })), { type: "Users", id: "LIST" }]
                    : [{ type: "Users", id: "LIST" }]
        }),
        addUser: builder.mutation({
            query: ({ user }) => ({
                url: `auth/createuser`,
                method: 'POST',
                body: user
            }),
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
            invalidatesTags: [{ type: "Users", id: "LIST" }]
        }),
        updateUser: builder.mutation({
            query: ({ userId, user }) => ({
                url: `user/${userId}`,
                method: 'POST',
                body: user
            }),
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
            invalidatesTags: (result, error, arg) =>
            {
                return  [{ type: "Users", id: "LIST" }, {type: "Users", id: arg.userId}]
            }

        }),
        updateUserRole: builder.mutation({
            query: ({ userId }) => ({
                url: `user/${userId}/makeadmin`,
                method: 'PUT',
                body: JSON.stringify({})
            }),
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
            invalidatesTags: (result, error, arg) => [
                { type: "Users", id: "LIST" },
                { type: "Users", id: arg.userId }
            ]
        }),
        deleteUser: builder.mutation({
            query: ({ userId }) => ({
                url: `user/${userId}`,
                method: 'DELETE'
            }),
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
            invalidatesTags: [{ type: "Users", id: "LIST" }]
        }),
        getPreSignedUrl: builder.query({
            query: ({ tenancyId, fileType }) => {
                return `user/getpresignedurl?type=${fileType}&tenancyId=${tenancyId}`;
            },
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
            providesTags: (result, error, id) => [{ type: "PreSignedUrl", id }]
        }),
        uploadToS3: builder.mutation({
            queryFn: async ({ tenancyId, file }, api, extraOptions, baseQuery) => {
                const preSignedUrlResponse = await baseQuery(`user/getpresignedurl?type=${file.type}&tenancyId=${tenancyId}`, api, extraOptions);
                if (preSignedUrlResponse.error) {
                    return { error: preSignedUrlResponse.error };
                }

                const { presignedUrl } = preSignedUrlResponse.data;

                try {
                    const uploadResponse = await fetch(presignedUrl, {
                        method: 'PUT',
                        body: file,
                        headers: {
                            'x-amz-acl': 'bucket-owner-full-control',
                        },
                    });

                    if (!uploadResponse.ok) {
                        return {
                            error: {
                                status: uploadResponse.status,
                                statusText: uploadResponse.statusText
                            }
                        };
                    }

                    return { data: { success: true } };
                } catch (error) {
                    return { error: { status: 'NETWORK_ERROR', message: error.message } };
                }
            },
            invalidatesTags: [{ type: "Users", id: "LIST" }]
        }),
        getDeletePreSignedUrl: builder.query({ 
            query: ({ tenancyId, fileType }) => `user/getdeletepresignedurl?type=${fileType}&tenancyId=${tenancyId}`,
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
            providesTags: (result, error, id) => [{ type: "DeletePreSignedUrl", id }]
        }),
        uploadDeletionCsvToS3: builder.mutation({ 
            queryFn: async ({ tenancyId, file }, api, extraOptions, baseQuery) => {
                const preSignedUrlResponse = await baseQuery(`user/getdeletepresignedurl?type=${file.type}&tenancyId=${tenancyId}`, api, extraOptions);
                if (preSignedUrlResponse.error) {
                    return { error: preSignedUrlResponse.error };
                }

                const { presignedUrl } = preSignedUrlResponse.data;

                try {
                    const uploadResponse = await fetch(presignedUrl, {
                        method: 'PUT',
                        body: file,
                        headers: {
                            'x-amz-acl': 'bucket-owner-full-control',
                        },
                    });

                    if (!uploadResponse.ok) {
                        return {
                            error: {
                                status: uploadResponse.status,
                                statusText: uploadResponse.statusText
                            }
                        };
                    }

                    return { data: { success: true } };
                } catch (error) {
                    return { error: { status: 'NETWORK_ERROR', message: error.message } };
                }
            },
            invalidatesTags: [{ type: "Users", id: "LIST" }]
        }),
        exportUsers: builder.mutation({
            query: ({ tenancyId, onlyActive = false }) => ({
                url: 'user/getusercsv',
                method: 'GET',
                params: {
                    perPage: 1000000000,  
                    page: 0,              
                    includes: '',         
                    query: '',            
                    tenancyId,
                    onlyActive
                }
            }),
        }),

        getUsersFromGroup: builder.query({
            query: ({ tenancyId,groupId }) => `tenancy/${tenancyId}/group/${groupId}/user`,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
            // providesTags: (result) =>
            //     result
            //         ? [...result.map(({ id }) => ({ type: "Users", id })), { type: "Users", id: "LIST" }]
            //         : [{ type: "Users", id: "LIST" }]
        }),
    }),
});

//

export const {
    useGetUserByIdQuery,
    useGetUsersQuery,
    useAddUserMutation,
    useUpdateUserMutation,
    useUpdateUserRoleMutation,
    useDeleteUserMutation,
    useGetPreSignedUrlQuery,
    useUploadToS3Mutation,
    useGetDeletePreSignedUrlQuery,
    useUploadDeletionCsvToS3Mutation,
    useGetUsersFromGroupQuery,
    useExportUsersMutation
} = userApi;


