import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import SimulationTable from "../../simulations/SimulationTable";
import CourseTable from "../../courses/CourseTable";

ContentSelection.propTypes = {
    format: PropTypes.string.isRequired,
    simsAndCoursesToSend: PropTypes.array.isRequired,
    setSimsAndCoursesToSend: PropTypes.func.isRequired,
    onNextStep: PropTypes.func.isRequired,
};

export default function ContentSelection(props) {
    const { format, simsAndCoursesToSend, setSimsAndCoursesToSend, onNextStep } = props;

    // Ensure simsAndCoursesToSend is always an array
    const selectedIds = useMemo(() => Array.isArray(simsAndCoursesToSend) ? simsAndCoursesToSend : [], [simsAndCoursesToSend]);

    const handleRandomSelect = useCallback(() => {
        onNextStep();
    }, [onNextStep]);

    const handleSetSelected = useCallback((newSelected) => {
        // Ensure we're always setting an array
        setSimsAndCoursesToSend(Array.isArray(newSelected) ? newSelected : []);
    }, [setSimsAndCoursesToSend]);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%" }}>
            {format === "simulation" && (
                <SimulationTable
                    type="select"
                    simulationIds={selectedIds}
                    setSimulationId={handleSetSelected}
                    onRandomSelect={handleRandomSelect}
                />
            )}

            {format === "course" && (
                <CourseTable
                    type="select"
                    courseIds={selectedIds}
                    setCourseId={handleSetSelected}
                    onRandomSelect={handleRandomSelect}
                />
            )}
        </Box>
    );
}