import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {QUICKSIGHT_API_URL} from "config";

const baseQuery = fetchBaseQuery({
    baseUrl: QUICKSIGHT_API_URL,
    prepareHeaders: (headers) => {
        const user = localStorage.getItem('user');

        if (user) {
            let userToken = JSON.parse(user).token;
            headers.set('authorization', `Bearer ${userToken}`)
        }
        return headers
    },
    credentials: "omit"
})

export const quicksightApi = createApi({
    reducerPath: "quicksightApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getQuicksightEmbedUrl: builder.query({
            query: () => `reporting/quicksighturl`,
            // transformResponse: (response) => response,
            // transformErrorResponse: (response) => response.status.query(),
            // providesTags: (result, error, id) => [{ type: "EmbedUrl", id }],
            // invalidatesTags: (result, error, id) => [{ type: "EmbedUrl", id }]
        }),
    }),
});

export const {
    useLazyGetQuicksightEmbedUrlQuery
} = quicksightApi;
