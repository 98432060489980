export default function TextField(theme) {
    return {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        borderRadius: theme.borderRadius.sm,
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 500,
                        backgroundColor: theme.palette.background.default,
                    },
                    '& .MuiInputLabel-root': {
                        backgroundColor: 'transparent',
                        color: theme.palette.text.primary,
                    },
                    '& .MuiInputLabel-outlined': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
    };
}
