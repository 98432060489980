import { useEffect, useState } from "react";
import { Alert, Box, Button, Grid, Snackbar, Stack, Tab, Tabs, Typography, CircularProgress } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainCard from "../../components/cards/MainCard";
import { useGetTenancyBasicsQuery, useGetTenancyByIdQuery } from "../../store/services/tenancy.service";
import { useSelector } from "react-redux";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import UserDetails from "./UserDetails";
import ActiveJourney from "./ActiveJourney";
import JourneyHistory from "./JourneyHistory";
import UserSettings from "./UserSettings";
import { useDeleteUserMutation, useGetUserByIdQuery } from "../../store/services/user-service";
import TenancySettingSkeletonComponent from "../../components/Skeleton/TenancySettingSkeletonComponent";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { getStatusMessage } from "../../functions/getStatusMessage";
import ConfirmationDialog from "../../utils/confirmationDialog";

const UserDefault = () => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { tenancyId } = useSelector((state) => state.tenancy);

    const [deleteUser] = useDeleteUserMutation();
    const [isDeleting, setIsDeleting] = useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

    const { data: userData, isLoading: userDataLoading, isError: userDataError } = useGetUserByIdQuery({ userId: id });
    const { data: tenancyData, isLoading: tenancyDataLoading, isError: tenancyDataError } = useGetTenancyByIdQuery({ tenancyId });
    const { data: tenancyBasicData, isLoading: tenancyBasicDataLoading, isError: tenancyBasicDataError } = useGetTenancyBasicsQuery({ tenancyId });

    const [tab, setTab] = useState("settings");
    const [isAdmin, setIsAdmin] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleTabChange = (event, value) => {
        if (value === null) return;

        setTab(value);
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setIsAdmin(user && user.role === "Administrators");
    }, []);

    const handleDelete = async () => {
        setIsConfirmDialogOpen(false);
        setIsDeleting(true);
        await deleteUser({ userId: userData.id })
            .unwrap()
            .then(() => {
                setSnackbarMessage("User deleted");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                navigate("/users");
            })
            .catch((error) => {
                console.error("Error deleting user", error);
                setSnackbarSeverity("error");
                setSnackbarMessage("Error deleting user");
                setSnackbarOpen(true);
            })
            .finally(() => {
                setIsDeleting(false);
            });
    };

    if (tenancyDataLoading || tenancyBasicDataLoading || userDataLoading) {
        return <TenancySettingSkeletonComponent />;
    }

    if (tenancyDataError || tenancyBasicDataError || userDataError) {
        console.error("Error loading data", tenancyDataError, tenancyBasicDataError);
        return (
            <MainCard boxShadow={true} border={false}
                      sx={{ minHeight: "calc(100vh - 200px)", display: "flex", justifyContent: "center", alignItems: "center", bgcolor: 'background.default' }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={8}>
                        <Stack direction="column" spacing={4} alignItems="center">
                            <ErrorOutlineIcon color="error" style={{ fontSize: 80 }} />
                            <Typography variant="h3" align="center" color="textPrimary">
                                {getStatusMessage(tenancyDataError || tenancyBasicDataError || userDataError)}
                            </Typography>
                            <Typography variant="body1" align="center" color="textSecondary">
                                Please refresh the page or contact the administrator.
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
        );
    }

    return (
        <>
            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                        <Button onClick={() => navigate(location.from ? location.from : -1)} variant="contained"
                                startIcon={<KeyboardArrowLeftTwoToneIcon />}>
                            Back
                        </Button>
                    </Grid>
                    <MainCard sx={{ mt: 2, minHeight: 'calc(100vh - 200px)', backgroundColor: 'background.default' }} content={true} boxShadow={true} border={false}>
                        <Grid container justifyContent="space-between" sx={{ mb: 4 }}>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center" }}>
                                <Typography variant="h2">
                                    {userData.firstName} {userData.lastName}
                                </Typography>
                                <Typography variant="caption">
                                    {userData.role || "User"}
                                </Typography>
                            </Box>
                            <Button variant="contained" color="error" onClick={ () => setIsConfirmDialogOpen(true)}>
                                {isDeleting ? <CircularProgress sx={{ color: "black.main" }} /> : "Delete User" }
                            </Button>
                        </Grid>

                        <Tabs value={tab} onChange={handleTabChange} aria-label="user-tabs" sx={{ mb: 2 }}>
                            <Tab label="User Settings" value={"settings"} disableRipple />
                            <Tab label="User Details" value={"details"} disableRipple />
                            <Tab label="Active Journey" value={"active-journey"} disableRipple />
                            <Tab label="Journey History" value={"journey-history"} disableRipple />
                        </Tabs>

                        {tab === "settings" && (
                            <UserSettings userData={userData} tenancyData={tenancyData} />
                        )}

                        {tab === "details" && (
                            <UserDetails user={userData} tenancyData={tenancyData} tenancyBasicData={tenancyBasicData} />
                        )}

                        {tab === "active-journey" && (
                            <ActiveJourney user={userData} />
                        )}

                        {tab === "journey-history" && (
                            <JourneyHistory user={userData} />
                        )}

                    </MainCard>
                </Grid>
            </Grid>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled" sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <ConfirmationDialog
                open={isConfirmDialogOpen}
                title="Confirm Deletion"
                content="Are you sure you want to delete this user? This action cannot be undone."
                onConfirm={handleDelete}
                onCancel={() => setIsConfirmDialogOpen(false)}
            />
        </>
    );
};

export default UserDefault;
