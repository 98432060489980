import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Watermark = ({ text, left }) => {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;

    return (
        <Box
            sx={{
                position: "absolute",
                top: 15,
                left: left, 
                fontSize: 20,
                color: primaryColor,
                opacity: 0.4, 
                pointerEvents: "none", 
                zIndex: 2001, 
            }}
        >
            {text}
        </Box>
    );
};

export default Watermark;
