import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { JOURNEY_API_URL } from "config";

const baseQuery = fetchBaseQuery({
    baseUrl: JOURNEY_API_URL,
    prepareHeaders: (headers) => {
        const user = localStorage.getItem("user");

        if (user) {
            let userToken = JSON.parse(user).token;
            headers.set("authorization", `Bearer ${userToken}`);
        }
        return headers;
    },
    credentials: "omit"
});

export const journeyApi = createApi({
    reducerPath: "journeyApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        createJourney: builder.mutation({
            query: (journeyData) => ({ url: "journey", method: "POST", body: journeyData }),
            invalidatesTags: [{ type: "Journeys", id: "LIST" }]
        }),
        getJourneyById: builder.query({
            query: ({ journeyId }) => `journey/${journeyId}`,
            providesTags: (result, error, id) => [{ type: "JourneyByIdData", id: id }]
        }),
        getJourneys: builder.query({
            query: () => `journey?perPage=100&page=0&includes=&query=&searchTerm=`
        }),
        updateJourney: builder.mutation({
            query: ({ journeyData }) => ({ url: `journey`, method: "PUT", body: journeyData }),
            invalidatesTags: (result, error, id) => [{ type: "Journeys", id: "LIST" }, { type: "JourneyByIdData", id: id }]
        })
    })
});

export const {
    useCreateJourneyMutation,
    useGetJourneyByIdQuery,
    useGetJourneysQuery,
    useUpdateJourneyMutation
} = journeyApi;
