export const PRESET_THEMES = [
    { name: 'Default', primary: '#74E1BF', secondary: '#4281EE', tertiary: '#FDC95D' },
    { name: 'Sunset', primary: '#ff7e5f', secondary: '#feb47b', tertiary: '#ffcc5c' },
    { name: 'Forest', primary: '#228b22', secondary: '#8fbc8f', tertiary: '#daa520' },
    { name: 'Lavender', primary: '#8e44ad', secondary: '#9b59b6', tertiary: '#d2b4de' },
    { name: 'Monochrome', primary: '#2c3e50', secondary: '#7f8c8d', tertiary: '#bdc3c7' },
    { name: 'Cherry Blossom', primary: '#ff69b4', secondary: '#ffc0cb', tertiary: '#ffb6c1' },
    { name: 'Midnight Wasp', primary: '#0F102C', secondary: '#0F102C', tertiary: '#ffcf00' },
    { name: 'Winter', primary: '#3498db', secondary: '#95a5a6', tertiary: '#ecf0f1' },
    { name: 'Spring', primary: '#2ecc71', secondary: '#27ae60', tertiary: '#f1c40f' },
    { name: 'Summer', primary: '#e74c3c', secondary: '#e67e22', tertiary: '#f39c12' },
    { name: 'Galaxy', primary: '#2c3e50', secondary: '#8e44ad', tertiary: '#3498db' },
    { name: 'Desert', primary: '#e67e22', secondary: '#d35400', tertiary: '#f39c12' },
    { name: 'Vintage', primary: '#d7ccc8', secondary: '#8d6e63', tertiary: '#bcaaa4' },
    { name: 'Neon', primary: '#e91e63', secondary: '#9c27b0', tertiary: '#00e676' },
];