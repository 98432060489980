// third-party
import { merge } from 'lodash';

// project import
import Badge from './Badge';
import Button from './Button';
import CardContent from './CardContent';
import Checkbox from './Checkbox';
import Chip from './Chip';
import IconButton from './IconButton';
import InputLabel from './InputLabel';
import LinearProgress from './LinearProgress';
import Link from './Link';
import ListItemIcon from './ListItemIcon';
import OutlinedInput from './OutlinedInput';
import Tab from './Tab';
import TableCell from './TableCell';
import Tabs from './Tabs';
import Typography from './Typography';
import MenuItem from "./MenuItem";
import AutoComplete from "./AutoComplete";
import TextField from "./TextField";
import Table from './Table';
import ToggleButtonGroup from './ToggleButtonGroup';
import Select from "./Select";
import Menu from "./Menu";
import Alert from "./Alert";
import ToggleButton from "./ToggleButton";  
import Modal from "./Modal";
import DatePicker from "./DatePicker";

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides(theme) {
    return merge(
        Button(theme),
        Badge(theme),
        CardContent(),
        Checkbox(theme),
        Chip(theme),
        IconButton(theme),
        InputLabel(theme),
        LinearProgress(),
        Link(),
        ListItemIcon(),
        OutlinedInput(theme),
        Tab(theme),
        TableCell(theme),
        Tabs(),
        Typography(),
        Menu(theme),
        MenuItem(theme),
        AutoComplete(theme),
        TextField(theme),
        // Table(theme),
        Modal(theme),
        ToggleButtonGroup(theme),
        Select(theme),
        Alert(theme),
        ToggleButton(theme),
        DatePicker(theme)
    );
}
