import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    Alert,
    useMediaQuery,
    Skeleton
} from '@mui/material';
import { Pagination } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import {formatDateOnly} from "../../utils/formatDateTime";

import CardComponent from '../../components/cards/CourseCard';
import { APP_URL } from '../../config';
import { useGetValidCoursesQuery } from '../../store/services/university-service';
import { useGetTenancyAllowRetakeQuery } from "../../store/services/tenancy.service";
import { useSelector } from 'react-redux';
import { useDynamicBreakpoints } from '../../utils/breakpointUtils';

const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.35 } },
    exit: { opacity: 0, transition: { duration: 0.35 } }
};

const cardVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            type: 'spring',
            stiffness: 100,
            damping: 15,
            duration: 0.35
        }
    }
};

const DEFAULT_GIF = '/default.gif'
const DEFAULT_IMG = '/default.png'

const CourseCarousel = ({ showCourseContent, courses, loading, error, type }) => {
    const theme = useTheme();
    const { tenancyId } = useSelector((state) => state.tenancy);
    const { drawerOpen } = useSelector((state) => state.menu);
    const { matchDownSM, matchDownMD, matchDownLG } = useDynamicBreakpoints(drawerOpen);
    const [page, setPage] = useState(1);
    const [imageLoadStatus, setImageLoadStatus] = useState({});

    const { data: tenancyRetakeData, isLoading: tenancyRetakeLoading, error: tenancyRetakeError } = useGetTenancyAllowRetakeQuery({ tenancyId: tenancyId });
    const { data: coursesData, isLoading: coursesLoading, error: coursesError } = useGetValidCoursesQuery({
        perPage: 10000,
        page: 1,
        includes: '',
        query: '',
        additionalQueryParams: `&published=true&tenancyId=${tenancyId}`
    });

    const handleImageError = (courseId, imageType) => {
        setImageLoadStatus(prev => ({
            ...prev,
            [courseId]: { ...prev[courseId], [imageType]: false }
        }));
    };

    const coursesPerPage = 100;

    const getGridItemSize = () => {
        if (matchDownSM) return 12; // 1 wide layout
        if (matchDownMD) return 6;  // 2 wide layout
        if (matchDownLG) return 4;  // 3 wide layout
        return 3; // Default to 4 wide layout for larger screens
    };
    const gridItemSize = getGridItemSize();

    const visibleCourses = (courses) => {
        const start = (page - 1) * coursesPerPage;
        return courses.slice(start, start + coursesPerPage);
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    if (error) {
        return (
            <Alert id="course-load-error" severity="error">
                An error occurred while fetching the courses.
            </Alert>
        );
    }

    if (courses && courses.length === 0 && !loading) {
        return (
            <Typography variant="h6" align="center" sx={{ mt: 4 }}>
                No courses available at the moment.
            </Typography>
        );
    }

    return (
        <>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={page}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={containerVariants}
                        >
                            <Grid container spacing={2} justifyContent="center" alignItems="stretch">
                                {loading
                                    ? [...Array(coursesPerPage)].map((_, index) => (
                                        <Grid item xs={12} sm={gridItemSize} key={index}>
                                            <Skeleton variant="rounded" height={0} width="100%" sx={{ paddingTop: '75%', marginBottom: 1 }} />
                                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="70%" />
                                            <Skeleton variant="rounded" height={50} width="100%" sx={{ marginTop: 1 }} />
                                        </Grid>
                                    ))
                                    : visibleCourses(courses).map((course) => {
                                        const courseData = coursesData?.find((c) => c.id === course.universityCourseId);
                                        const course_gif = courseData ? `${APP_URL}/${courseData.courseContentPath.replace('index.html', 'card.gif')}` : DEFAULT_GIF;
                                        const course_img = courseData ? `${APP_URL}/${courseData.courseContentPath.replace('index.html', 'card.png')}` : DEFAULT_IMG;
                                        const dateToShow = type === 'completed' ? course.dateCompleted : course.dateCreated;
                                        const formattedDate = formatDateOnly(new Date(dateToShow), 'dd-MM-yyyy');

                                        return (
                                            <Grid item xs={12} sm={gridItemSize} key={course.id} sx={{ mb: 4 }}>
                                                <motion.div
                                                    variants={cardVariants}
                                                    initial="hidden"
                                                    animate="visible"
                                                    whileHover={{ scale: 1.03, transition: { duration: 0.4 } }}
                                                    style={{ height: '100%' }}
                                                >
                                                    <CardComponent
                                                        image={course_img}
                                                        title={course.universityCourseName}
                                                        gif={course_gif}
                                                        onClick={() => showCourseContent(course)}
                                                        type={type}
                                                        dateLabel={type === 'completed' ? 'Completed:' : 'Added:'}
                                                        dateValue={formattedDate}
                                                        retake={tenancyRetakeData?.allowRetake && type === "outstanding" && course.correctAnswers < 80 && course.correctAnswers !== null}
                                                    />
                                                </motion.div>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        </motion.div>
                    </AnimatePresence>
                </Grid>
            </Grid>

            {courses && courses.length > coursesPerPage && (
                <Grid container justifyContent="center" sx={{ mt: 4 }}>
                    <Pagination
                        count={Math.ceil(courses.length / coursesPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        color="primary"
                        size={matchDownSM ? "small" : "large"}
                    />
                </Grid>
            )}
        </>
    );
};

CourseCarousel.propTypes = {
    showCourseContent: PropTypes.func.isRequired,
    courses: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.object,
    type: PropTypes.string
};

export default CourseCarousel;
