export default function Select(theme) {
    return {
        MuiSelect: {
            styleOverrides: {
                root: {
                    '&.MuiOutlinedInput-root': {
                        borderRadius: theme.borderRadius.sm,
                        backgroundColor: theme.palette.background.default,
                        color: theme.palette.text.primary,
                    },

                    '&.MuiInputLabel-root': {
                        backgroundColor: 'transparent',
                        color: theme.palette.text.primary,
                    },
                },
            },
        },
    };
}