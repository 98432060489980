import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
    Box,
    CircularProgress,
} from "@mui/material";
import Table from "../../components/Table/Table";
import { useGetUsersFromGroupQuery } from "../../store/services/user-service";

export default function GroupUsersTable({ selectedGroupId, closeModal }) {
    const { tenancyId } = useSelector((state) => state.tenancy);
    const [selected, setSelected] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0
    });

    const { data: groupData, error: groupError, isLoading: groupLoading } = useGetUsersFromGroupQuery({
        groupId: selectedGroupId,
        tenancyId: tenancyId
    }, {
        skip: !selectedGroupId
    });

    const headCells = [
        {
            id: 'name',
            label: 'Name',
            initialVisible: true,
            render: (row) => `${row.firstName} ${row.lastName}`
        },
        {
            id: 'dateCreated',
            label: 'Date Created',
            initialVisible: true,
            render: (row) => new Date(row.dateCreated).toLocaleDateString()
        },
        {
            id: 'email',
            label: 'Email',
            initialVisible: true,
            render: (row) => row.email
        },
        { id: "firstName", label: "First Name", initialVisible: false }, //First and last name are left here to allow for search functionality
        { id: "lastName", label: "Last Name", initialVisible: false },
    ];

    return (
        <Table
            name={"group users"}
            data={groupData || []}
            error={groupError}
            loading={groupLoading}
            headCells={headCells}
            search={true}
            editColumn={false}
            checkbox={false}
            showToolbarActions={true}
            toolbarActions={[]}
            onDelete={() => console.log("Delete group users not allowed")}
            selected={selected}
            setSelected={setSelected}
            maxTableHeight={"calc(100vh - 400px)"}
            initialVisibleColumns={headCells.filter(cell => cell.initialVisible).map(cell => cell.label)}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            plural={"group users"}
        />
    );
}

GroupUsersTable.propTypes = {
    selectedGroupId: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired
};