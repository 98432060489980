import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RESPONSE_API_URL } from "config";

const baseQuery = fetchBaseQuery({
    baseUrl: RESPONSE_API_URL,
    prepareHeaders: (headers) => {
        const user = localStorage.getItem('user');

        if (user) {
            let userToken = JSON.parse(user).token;
            headers.set('authorization', `Bearer ${userToken}`)
        }
        return headers
    },
    credentials: "omit"
})

export const responseApi = createApi({
    reducerPath: "responseApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        sendTenancyReminderEmail: builder.mutation({
            query: ({tenancyId}) => ({
                url: `tenancyreminder/${tenancyId}/sendemail`,
                method: 'POST'
            }),
            invalidatesTags: [{ type: "TenancyReminderEmail", id: "LIST" }]
        }),
        getUserCompletedCourses: builder.query({
            query: ({userEmail}) => `universityresponse/completed?userId=${userEmail}`,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status.query(),
            providesTags: (result, error, id) => [{ type: "UserCompletedCourses", id }]
        }),
        getUserOutstandingCourses: builder.query({
            query: ({userEmail}) => `universityresponse?userId=${userEmail}`,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status.query(),
            providesTags: (result, error, id) => [{ type: "UserOutstandingCourses", id }]
        }),
    }),
});

export const {
    useSendTenancyReminderEmailMutation,
    useLazyGetUserCompletedCoursesQuery,
    useLazyGetUserOutstandingCoursesQuery
} = responseApi;
