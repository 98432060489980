import { useState } from "react";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import AdminLogsTable from "./AdminLogsTable";
import SyncImportCard from "../../components/cards/SyncImportCard";

export default function AdminLogs() {
    const [tab, setTab] = useState("UserCreation");

    const handleTabChange = (event, value) => {
        if (value === null) return;

        setTab(value);
    };

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} md={12} lg={12}>
                <Tabs value={tab} onChange={handleTabChange} aria-label="admin-logs-tab" sx={{ mb: 2 }}>
                    {/*<Tab label="User Creation" value={"UserCreation"} disableRipple />*/}
                    <Tab label="User Sync" value={"UserCreation"} disableRipple />
                    <Tab label="Reminder" value={"SendReminder"} disableRipple />
                    <Tab label="User Activity" value={"UserManagement"} disableRipple />
                    
                </Tabs>

                <AdminLogsTable tab={tab} />
            </Grid>
        </Grid>
    );
};
