import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import JourneyTable from "./JourneyTable";

ActiveJourney.propTypes = {
    user: PropTypes.object.isRequired
};

export default function ActiveJourney(props) {
    const { user } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <JourneyTable type={"Active"} user={user} />
            </Grid>
        </Grid>
    );
};