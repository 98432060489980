import { Alert, Button, Grid, Snackbar, Typography } from "@mui/material";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import MainCard from "../../components/cards/MainCard";
import { useNavigate, useParams } from "react-router-dom";
import { useGetJourneyByIdQuery, useCreateJourneyMutation, useUpdateJourneyMutation } from "../../store/services/journey-service";
import { useState } from "react";
import JourneySkeletonComponent from "../../components/Skeleton/JourneySkeletonComponent";
import JourneyForm from "./JourneyForm";

export default function JourneyDetails() {
    const navigate = useNavigate();
    const { id } = useParams();
    const isEditMode = Boolean(id);
    const [createJourney] = useCreateJourneyMutation();
    const [updateJourney] = useUpdateJourneyMutation();
    const { data: journeyData, isLoading: journeyDataLoading } = useGetJourneyByIdQuery({ journeyId: id }, { skip: !isEditMode });

    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const initialData = isEditMode ? journeyData : {
        name: "",
        journeyOptions: []
    };

    const handleSaveJourney = async (data) => {
        if (isEditMode) {
            data.universityJourneyId = id;
            data.dateUpdated = new Date().toISOString();

            try {
                const journeyUpdateCommand = { journeyToUpdate: data };

                await updateJourney({ journeyData: journeyUpdateCommand })
                .unwrap()
                .then(() => {
                    setSnackbarMessage("Journey updated successfully");
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                })
                .catch(() => {
                    setSnackbarMessage("Error occurred while updating journey");
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                });
            } catch (e) {
                setSnackbarMessage("Error occurred while updating journey");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        } else {
            try {
                data.dateCreated = new Date().toISOString();
                const journeyCreateCommand = { journeyToCreate: data };

                await createJourney(journeyCreateCommand)
                .unwrap()
                .then(() => {
                    setSnackbarMessage("Journey created successfully");
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                })
                .catch(() => {
                    setSnackbarMessage("Error occurred while creating journey");
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                });

            } catch (e) {
                setSnackbarMessage("Error occurred while creating journey");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        }
    };

    if (journeyDataLoading && isEditMode) return <JourneySkeletonComponent />;

    return (
        <>
            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                        <Button onClick={() => navigate(-1)} variant="contained" startIcon={<KeyboardArrowLeftTwoToneIcon />}>
                            Back
                        </Button>
                    </Grid>
                    <MainCard sx={{ mt: 2, bgcolor: 'background.default' }} content={true} boxShadow={true} border={false}>
                        <Grid container justifyContent="space-between" sx={{ mb: 4 }}>
                            <Typography variant="h2">
                                {isEditMode ? "View Journey" : "Create Journey"}
                            </Typography>
                        </Grid>

                        <JourneyForm initialData={initialData} onSave={handleSaveJourney} mode={isEditMode ? "view" : "add"} />
                    </MainCard>
                </Grid>
            </Grid>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={snackbarOpen} autoHideDuration={3000}
                      onClose={() => setSnackbarOpen(false)}>
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};
