import { useMediaQuery, useTheme } from "@mui/material";
import { useMemo, useEffect } from "react";

const DRAWER_WIDTH = 260; 

export const useDynamicBreakpoints = (isDrawerOpen) => {
    const theme = useTheme();

    const baseBreakpoints = useMemo(() => ({
        sm: 750,
        md: 1050,
        lg: 1350
    }), []);

    const adjustedBreakpoints = useMemo(() => ({
        sm: baseBreakpoints.sm + (isDrawerOpen ? DRAWER_WIDTH : 0),
        md: baseBreakpoints.md + (isDrawerOpen ? DRAWER_WIDTH : 0),
        lg: baseBreakpoints.lg + (isDrawerOpen ? DRAWER_WIDTH : 0)
    }), [isDrawerOpen, baseBreakpoints]);

    const matchDownSM = useMediaQuery(theme.breakpoints.down(adjustedBreakpoints.sm));
    const matchDownMD = useMediaQuery(theme.breakpoints.down(adjustedBreakpoints.md));
    const matchDownLG = useMediaQuery(theme.breakpoints.down(adjustedBreakpoints.lg));

    return { matchDownSM, matchDownMD, matchDownLG, adjustedBreakpoints };
};