import React, { useState, useCallback, useMemo } from 'react';
import { Button, CircularProgress } from '@mui/material';

const UserEnableDisable = React.memo(({ user, type, handleEnableChange, userLoading }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = useCallback(() => setIsHovered(true), []);
    const handleMouseLeave = useCallback(() => setIsHovered(false), []);

    const buttonColor = useMemo(() => {
        if (user.enabled && isHovered) return "black";
        if (user.enabled && !isHovered) return "warning";
        if (!user.enabled && isHovered) return "warning";
        return "black";
    }, [user.enabled, isHovered]);

    const buttonText = useMemo(() => {
        if (userLoading[user.id]) return <CircularProgress color="inherit" size={24} />;
        if (isHovered) return user.enabled ? "DISABLE" : "ENABLE";
        return user.enabled ? "ENABLED" : "DISABLED";
    }, [user.id, user.enabled, isHovered, userLoading]);

    return (
        <Button
            variant="contained"
            size="small"
            onClick={() => type === "edit" && handleEnableChange(user)}
            color={buttonColor}
            disabled={type !== "edit"}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{ minWidth: 100 }}
        >
            {buttonText}
        </Button>
    );
});

export default UserEnableDisable;