import React from 'react';
import { Box, Switch, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

const LoadingSwitch = ({ checked, onChange, disabled, loading, inputProps }) => (
    <Box sx={{ width: 58, height: 38, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {loading ? (
            <CircularProgress size={24} color="warning" />
        ) : (
            <Switch
                checked={checked}
                onChange={onChange}
                disabled={disabled || loading}
                inputProps={inputProps}
                color="warning"
            />
        )}
    </Box>
);

LoadingSwitch.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    inputProps: PropTypes.object,
};

export default LoadingSwitch;