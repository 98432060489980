// material-ui
import { Grid } from "@mui/material";

// project import
import JourneyTable from "./JourneyTable";

// ==============================|| USERS - DEFAULT ||============================== //
const JourneysDefault = () => {

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} md={12} lg={12}>
                <JourneyTable />
            </Grid>
        </Grid>
    );
};

export default JourneysDefault;
