import {
    Box, Button, Chip,
    Grid, Menu,
    MenuItem,
    Modal,
    Stack,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material";
import GroupTable from "../on-demand/Tables/GroupTable";
import ImportGroupTable from "./ImportGroupTable";
import { useSelector } from "react-redux";
import {
    useGetAuditLogsForTenancyQuery,
    useGetImportStatusQuery,
    useRefreshSyncGroupsMutation
} from "../../store/services/tenancy.service";
import React, { useCallback, useEffect, useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ColorLibConnector from "../../components/ColorLibConnector";
import CustomStepIcon from "../../components/Icon/CustomStepIcon";
import AdminLogsTable from "./AdminLogsTable";
import MainCard from "../../components/cards/MainCard";
import SyncStatusCard from "../../components/cards/SyncStatusCard";
import { useTheme } from "@mui/material/styles";
import { APP_URL, GOOGLE_CLIENT_CODE_IMPORT, OFFICE_CLIENT_CODE_SYNC } from "../../config";
import AddIcon from "@mui/icons-material/Add";
import GoogleIcon from "@mui/icons-material/Google";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import { formatDateTime } from "../../utils/formatDateTime";
import { useNavigate } from 'react-router-dom';
import { getCardColor, getTextColor } from '../../utils/colorUtils';

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    boxShadow: 24,
    borderRadius: 4,
    maxHeight: "80vh",
    bgcolor: "background.paper",
    overflow: "scroll",
    border: "0px solid",
};

const STEPS = ["Start", "In Progress", "Success"];

const UserSync = ({ openModal, setOpenModal }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const { tenancyId } = useSelector((state) => state.tenancy);
    const { data: auditLogsData, isLoading: auditLogsLoading, error: auditLogsError, refetch: refetchAuditLogs } = useGetAuditLogsForTenancyQuery({ tenancyId });
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";
    const navigate = useNavigate();
    const [latestImportStatus, setLatestImportStatus] = useState(null);

    const {
        data: importStatusData = [],
        isLoading: importStatusLoading,
        error: importStatusError,
        refetch
    } = useGetImportStatusQuery({ tenancyId });

    const [ refreshSyncGroups ] = useRefreshSyncGroupsMutation();

    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});

    const [importMenuAnchorEl, setImportMenuAnchorEl] = useState(null);
    const [importMenuOpen, setImportMenuOpen] = useState(false);

    const importMenuId = importMenuOpen ? "import-menu" : undefined;

    const handleImportMenuClick = (event) => {
        setImportMenuAnchorEl(event.currentTarget);
        setImportMenuOpen(true);
    };

    const handleImportMenuClose = () => {
        setImportMenuAnchorEl(null);
        setImportMenuOpen(false);
    };

    const handleRefresh = useCallback(() => {
        refetchAuditLogs();
    }, [refetchAuditLogs]);


    const updateStepper = (status) => {
        switch (status) {
            case "Start":
                setActiveStep(0);
                setCompleted({});
                break;
            case "In Progress":
                setActiveStep(1);
                setCompleted({});
                break;
            case "Success":
                setActiveStep(3);
                break;
            case "Error":
                setActiveStep(2);
                break;
            default:
                break;
        }
    };
    const getLatestImportStatus = (data) => {
        if (!data || data.length === 0) {
            return null;
        }
        const sortedData = [...data].sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
        return sortedData[0];
    };

    useEffect(() => {
        if (importStatusData) {
            setLatestImportStatus(getLatestImportStatus(importStatusData));
        }
    }, [importStatusData]);

    useEffect(() => {
        if (latestImportStatus && !importStatusLoading && !importStatusError) {
            const status = latestImportStatus?.status || "Start";
            updateStepper(status);
        }

        const pollImportStatus = () => {
            const status = latestImportStatus?.status || "Start";

            if (status !== "Success" && status !== "Error") {
                refetch();
            }
        };

        const intervalId = setInterval(pollImportStatus, 5000);

        return () => clearInterval(intervalId);
    }, [latestImportStatus, refetch]);


    const handleConnect = (origin) => {
        if (origin === "office") {
            window.open(
                `https://login.microsoftonline.com/common/adminconsent?client_id=${OFFICE_CLIENT_CODE_SYNC}&redirect_uri=${APP_URL}/officesync&state=${tenancyId}`,
                "_blank"
            );
        } else if (origin === "google") {
            window.open(`https://accounts.google.com/o/oauth2/auth?access_type=offline&prompt=consent&scope=https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/admin.directory.orgunit.readonly&response_type=code&client_id=${GOOGLE_CLIENT_CODE_IMPORT}&redirect_uri=${APP_URL}/googlehandler`,
                '_blank');
        }
    }

    const refreshGroups = async (origin) => {
        setCompleted({});
        setActiveStep(0);

        const data = {
            tenancyId: tenancyId,
            RequestorEmail: user.email,
            Origin: { type: origin === "google" ? "google" : "office" },
            OfficeAuthCode: "",
        };

        refreshSyncGroups({ tenancyId: tenancyId, data: data })
            .unwrap()
            .then(() => {
                refetch();
            })
            .catch((error) => {
                console.error("Error refreshing groups: ", error);
            });
    };

    const getSyncStatus = () => {
        if (importStatusError) return 'Error';
        if (importStatusLoading) return 'Loading';
        if (latestImportStatus?.sk === "google") return 'Google';
        if (latestImportStatus?.sk === "office") return 'Microsoft';
        return 'Other';
    }

    const isNoSync = () => {
        return !importStatusLoading && !importStatusError && importStatusData && importStatusData.length === 0;
    }

    return (
        <>
            <Grid container rowSpacing={4.5} columnSpacing={2.75} alignItems="center">
                { !isNoSync() &&
                    <Grid item xs={2}>
                        <SyncStatusCard status={getSyncStatus()} />
                    </Grid>
                }
            <Grid item xs={isNoSync() ? 12 : 7.95}>
                <MainCard
                    content
                    boxShadow
                    border={false}
                    sx={{
                        bgcolor: getCardColor(lightMode),
                        display: "flex",
                        flexDirection: "column",
                        height: isNoSync() ? 75 : 50,
                        justifyContent: "center",
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        {importStatusLoading && (
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
                                <Typography variant="h6">Loading Import Status...</Typography>
                            </Box>
                        )}

                        {importStatusError && (
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
                                <Typography variant="h6">Error fetching import status.</Typography>
                            </Box>
                        )}

                        {!importStatusLoading && !importStatusError && (
                                <> 
                                    {importStatusData && importStatusData.length === 0 ? (
                                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                            <Typography variant="h5" sx={{ flexGrow: 1 }}>
                                                Connect to Microsoft 365 or Google to sync your groups
                                            </Typography>

                                            <Button
                                                size="medium"
                                                variant="contained"
                                                sx={{ textTransform: "capitalize", mr: 1 }}
                                                aria-controls={importMenuId}
                                                aria-haspopup="true"
                                                onClick={handleImportMenuClick}
                                                endIcon={<AddIcon />}
                                            >
                                                Connect
                                            </Button>

                                            <Menu
                                                id={importMenuId}
                                                anchorEl={importMenuAnchorEl}
                                                open={importMenuOpen}
                                                onClose={handleImportMenuClose}
                                                MenuListProps={{
                                                    "aria-labelledby": "add-user-button",
                                                    sx: { bgcolor: "background.default" },
                                                }}
                                            >
                                                <MenuItem onClick={() => handleConnect("google")}>
                                                    <GoogleIcon sx={{ mr: 1 }} />
                                                    Connect to Google
                                                </MenuItem>
                                                <MenuItem onClick={() => handleConnect("office")}>
                                                    <MicrosoftIcon sx={{ mr: 1 }} />
                                                    Connect to Microsoft 365
                                                </MenuItem>
                                            </Menu>
                                        </Box>) :
                                       
                                (<Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                        <Typography variant="subtitle1">Last Sync</Typography>
                                                <Typography variant="caption" color={getTextColor(getCardColor(lightMode))} sx={{ ml: 1}}>
                                            {formatDateTime(latestImportStatus?.dateTime) || "Unknown"}
                                        </Typography>
                                    </Box>
                                </Box>)}

                        </>
                            
                        )}
                    </Box>
                </MainCard>
                </Grid>
                {!isNoSync() &&
                    <Grid item xs={2.05}>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    sx={{ textTransform: "capitalize", mr: 1 }}
                                    aria-controls={importMenuId}
                                    aria-haspopup="true"
                                    onClick={() => { window.location.href = '/users?tab=3'; }}
                                    color='secondary'

                                >
                                    Logs
                                </Button>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    sx={{ textTransform: "capitalize", mr: 1, minWidth: 110 }} 
                                    aria-controls={importMenuId}
                                    aria-haspopup="true"
                                    onClick={handleImportMenuClick}
                                >
                                    Reconfigure
                                </Button>

                                <Menu
                                    anchorEl={importMenuAnchorEl}
                                    open={Boolean(importMenuAnchorEl)}
                                    onClose={handleImportMenuClose}
                                >
                                    <MenuItem onClick={() => handleConnect("google")}>
                                        <GoogleIcon sx={{ mr: 1 }} />
                                        Connect to Google
                                    </MenuItem>
                                    <MenuItem onClick={() => handleConnect("office")}>
                                        <MicrosoftIcon sx={{ mr: 1 }} />
                                        Connect to Microsoft 365
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Box>
                    </Grid>
                }
        </Grid>
            <Grid container rowSpacing={4.5} columnSpacing={2.75} alignItems="stretch" sx={{mt: 1}}>
                <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex' }}>
                    <Box sx={{ width: '100%' }}>
                        {isNoSync() ? 
                            <MainCard
                                content
                                boxShadow
                                border={false}
                                sx={{
                                    bgcolor: "#F8F8F8",
                                    display: "flex",
                                    flexDirection: "column",
                                    width: '100%', 
                                    height: '500px', 
                                }}
                            >
                                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="body1">Connect to Google or Microsoft to see user groups</Typography>
                                </Box>
                            </MainCard> :
                            <GroupTable type={"edit"} />
                         }
                    </Box>
                </Grid>
                { /*
            <Grid item xs={0} md={0} lg={0} sx={{ display: 'flex' }}>
                    <MainCard
                        content
                        boxShadow
                        border={false}
                        sx={{
                            bgcolor: theme => theme.palette.mode === 'light' ? '#F8F8F8' : getCardColor(lightMode),
                        display: "flex",
                        flexDirection: "column",
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="body1">Activity (coming soon...)</Typography>
                    </Box>
                </MainCard>
            </Grid> */}

            {openModal && (
                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={12} md={12}>
                                {activeStep !== 3 && (
                                    <Stack direction="column" spacing={4} alignItems="center">
                                        <CheckCircleOutlineIcon color="primary" style={{ fontSize: 50 }} />
                                        <Typography variant="h4" align="center">
                                            The import of your {latestImportStatus?.sk === "office" ? "Microsoft 365" : "Google"}
                                            &nbsp;groups {latestImportStatus && latestImportStatus.status === "Success" ? "has completed" : "has started"}.
                                        </Typography>
                                        <Box width={"100%"}>
                                            <Stepper activeStep={activeStep} alternativeLabel
                                                     connector={<ColorLibConnector />}>
                                                {STEPS.map((label, index) => (
                                                    <Step key={label} completed={completed[index]}>
                                                        <StepLabel
                                                            StepIconComponent={(props) => <CustomStepIcon {...props} />}>
                                                            {label}
                                                        </StepLabel>
                                                    </Step>
                                                ))}
                                            </Stepper>
                                        </Box>

                                        {importStatusError && (
                                            <Typography color="error" align="center">
                                                There was an error with the import process. Please try again.
                                            </Typography>
                                        )}

                                        {(activeStep === 1 || activeStep === 0) && (
                                            <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                                                The import process may take some time. You can check back later to select
                                                the groups
                                                once the import is complete in the User Sync section.
                                            </Typography>
                                        )}
                                    </Stack>
                                )}
                                {activeStep === 3 && (
                                    <Box mt={4} width={"100%"}>
                                        <ImportGroupTable closeModal={() => setOpenModal(false)} />
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            )}
            </Grid>
        </>
    );
};

export default UserSync;
/* ----------- PREVIOUS SYNC STATUS CARD CODE -----------
                <Grid item xs={12}>
                    <MainCard
                        content
                        boxShadow
                        border={false}
                        sx={{
                            bgcolor: lightMode ? "white" : "grey.700",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            {importStatusLoading && (
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
                                    <Typography variant="h6">Loading Import Status...</Typography>
                                </Box>
                            )}

                            {importStatusError && (
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
                                    <Typography variant="h6">Error fetching import status.</Typography>
                                </Box>
                            )}

                            {!importStatusLoading && !importStatusError && (
                                <>
                                    {importStatusData && importStatusData.length === 0 ? (
                                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                            <Typography variant="h5" sx={{ flexGrow: 1 }}>
                                                Connect to Microsoft 365 or Google to sync your groups
                                            </Typography>

                                            <Button
                                                size="medium"
                                                variant="contained"
                                                sx={{ textTransform: "capitalize", mr: 1 }}
                                                aria-controls={importMenuId}
                                                aria-haspopup="true"
                                                onClick={handleImportMenuClick}
                                                endIcon={<AddIcon />}
                                            >
                                                Connect
                                            </Button>

                                            <Menu
                                                id={importMenuId}
                                                anchorEl={importMenuAnchorEl}
                                                open={importMenuOpen}
                                                onClose={handleImportMenuClose}
                                                MenuListProps={{
                                                    "aria-labelledby": "add-user-button",
                                                    sx: { bgcolor: "background.default" },
                                                }}
                                            >
                                                <MenuItem onClick={() => handleConnect("google")}>
                                                    <GoogleIcon sx={{ mr: 1 }} />
                                                    Connect to Google
                                                </MenuItem>
                                                <MenuItem onClick={() => handleConnect("office")}>
                                                    <MicrosoftIcon sx={{ mr: 1 }} />
                                                    Connect to Microsoft 365
                                                </MenuItem>
                                            </Menu>
                                        </Box>
                                    ) : (
                                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                                <Typography variant="h5" gutterBottom>
                                                    Connected to {latestImportStatus.sk === "office" ? "Microsoft 365" : "Google"}
                                                </Typography>

                                                <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => refreshGroups(latestImportStatus.sk)}
                                                        sx={{ minWidth: "120px" }}
                                                    >
                                                        Refresh
                                                    </Button>

                                                    <Button
                                                        variant="contained"
                                                        color="warning"
                                                        size="small"
                                                        aria-controls={importMenuId}
                                                        aria-haspopup="true"
                                                        onClick={handleImportMenuClick}
                                                        sx={{ minWidth: "120px" }}>
                                                        Switch
                                                    </Button>

                                                    <Menu
                                                        id={importMenuId}
                                                        anchorEl={importMenuAnchorEl}
                                                        open={importMenuOpen}
                                                        onClose={handleImportMenuClose}
                                                        MenuListProps={{
                                                            "aria-labelledby": "add-user-button",
                                                            sx: { bgcolor: "background.default" },
                                                        }}
                                                    >
                                                        <MenuItem onClick={() => handleConnect("google")}>
                                                            <GoogleIcon sx={{ mr: 1 }} />
                                                            Connect to Google
                                                        </MenuItem>
                                                        <MenuItem onClick={() => handleConnect("office")}>
                                                            <MicrosoftIcon sx={{ mr: 1 }} />
                                                            Connect to Microsoft 365
                                                        </MenuItem>
                                                    </Menu>
                                                </Box>
                                            </Box>

                                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", }}>
                                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
                                                    <Typography variant="subtitle1">Status</Typography>
                                                    <Chip
                                                        label={latestImportStatus.status}
                                                        size="medium"
                                                        color={latestImportStatus.status === "Success" ? "warning" : "default"}
                                                        sx={{ mt: 1 }}
                                                    />
                                                </Box>

                                                {activeStep === 3 && (
                                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
                                                        <Typography variant="subtitle1">Last Sync</Typography>
                                                        <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
                                                            {formatDateTime(latestImportStatus?.dateTime) || "Unknown"}
                                                        </Typography>
                                                    </Box>
                                                )}

                                                {latestImportStatus.status === "Success" && (
                                                    <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            size="small"
                                                            onClick={() => setOpenModal(true)}
                                                            sx={{ minWidth: "120px" }}
                                                        >
                                                            Import Groups
                                                        </Button>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                    )}
                                </>
                            )}
                        </Box>
                    </MainCard>
                </Grid>*/
