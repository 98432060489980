// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableCell(theme) {
    return {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    padding: 12,
                    borderBottom: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[200]}`,
                },
                head: {
                    fontWeight: 600,
                    paddingTop: 20,
                    paddingBottom: 20
                },
            }
        }
    };
}
