import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Table from "../../components/Table/Table";
import { useGetUserScheduleQuery, useUpdateUserJourneyMutation } from "../../store/services/scheduler.service";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Alert, Box, Button, Snackbar } from "@mui/material";
import ConfirmationDialog from "../../utils/confirmationDialog";

JourneyTable.propTypes = {
    type: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired
};

export default function JourneyTable(props) {
    const { type, user } = props;
    const {
        data: userSchedule,
        error: userScheduleError,
        isLoading: userScheduleLoading
    } = useGetUserScheduleQuery({ userEmail: user.email });

    const [updateUserJourney] = useUpdateUserJourneyMutation();

    const [filteredData, setFilteredData] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [journeyId, setJourneyId] = useState(null);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    // Add confirmation dialog states
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle] = useState("Reschedule Journey");
    const [dialogContent] = useState(
        "Are you sure you want to reschedule this journey? This will update all upcoming course and simulation dates."
    );

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 100,
        page: 0
    });

    let headCells = [
        { id: "date", label: "Date", initialVisible: true },
        { id: "course", label: "Course", initialVisible: true },
        { id: "simulation", label: "Simulation", initialVisible: true }
    ];

    const handleRescheduleJourney = () => {
        const data = {
            tenancyId: user.tenancyId,
            journeyId: journeyId,
            journeyStartDate: startDate,
            userId: user.id,
            userEmail: user.sendEmailTo ?? user.email,
            userFirstName: user.firstName,
            userLastName: user.lastName,
            tenancyName: user.tenancyName,
            tenancyAddress1: user.tenancyAddress1,
            tenancyAddress2: user.tenancyAddress2,
            tenancyAddress3: user.tenancyAddress3,
            tenancyAddress4: user.tenancyAddress4,
            tenancyAddress5: user.tenancyAddress5,
            tenancyPostcode: user.tenancyPostcode
        };

        updateUserJourney({ userEmail: user.email, data })
        .unwrap()
        .then(() => {
            setSnackbarMessage("User journey rescheduled successfully");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        })
        .catch(() => {
            setSnackbarMessage("User journey reschedule failed");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        });
    };

    // Add function to handle initial reschedule button click
    const handleRescheduleClick = () => {
        setDialogOpen(true);
    };

    useEffect(() => {
        if (userSchedule) {
            let combinedData = [];
            if (type === "Active") {
                userSchedule.forEach((schedule) => {
                    if (schedule.isActive) {
                        setStartDate(new Date(schedule.startDate));
                        setJourneyId(schedule.journey);
                        const emails = schedule.emails.map((email) => {
                            const sendDate = new Date(email.sendEmailOn);
                            const formattedDate = sendDate.toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric"
                            });
                            const formattedTime = sendDate.toLocaleTimeString("en-GB", {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit"
                            });
                            return {
                                date: `${formattedDate} ${formattedTime}`,
                                course: email.type === 1 ? email.typeName : "-",
                                simulation: email.type === 0 ? email.typeName : "-",
                                sortDate: sendDate
                            };
                        });
                        combinedData = [...combinedData, ...emails];
                    }
                });
            } else if (type === "History") {
                userSchedule.forEach((schedule) => {
                    const emails = schedule.sentEmails.map((email) => {
                        const sendDate = new Date(email.emailSentOn);
                        const formattedDate = sendDate.toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric"
                        });
                        const formattedTime = sendDate.toLocaleTimeString("en-GB", {
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit"
                        });
                        return {
                            date: `${formattedDate} ${formattedTime}`,
                            course: email.type === 1 ? email.typeName : "-",
                            simulation: email.type === 0 ? email.typeName : "-",
                            sortDate: sendDate
                        };
                    });
                    combinedData = [...combinedData, ...emails];
                });
            }

            combinedData.sort((a, b) => a.sortDate - b.sortDate);
            setFilteredData(combinedData);
        }
    }, [userSchedule, type]);

    const toolbarActions = [
        {
            render: () => (
                <Box width={"100%"} display="flex" justifyContent="flex-end">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Journey Start Date"
                            value={startDate}
                            onChange={setStartDate}
                            format="dd MMMM yyyy"
                            sx={{ width: "50%", mr: 2 }}
                            minDateTime={new Date()}
                        />
                        <Button variant="contained" color="warning" onClick={handleRescheduleClick}>
                            Reschedule Journey
                        </Button>
                    </LocalizationProvider>
                </Box>
            )
        }
    ];

    return (
        <>
            <Table
                name={type === "Active" ? "Active Journey" : "Journey History"}
                loading={userScheduleLoading}
                error={userScheduleError}
                data={filteredData.map(({ sortDate, ...rest }) => rest)}
                headCells={headCells}
                search={false}
                editColumn={false}
                checkbox={false}
                toolbarActions={toolbarActions}
                showToolbarActions={type === "Active"}
                onDelete={() => console.log("Delete function")}
                selected={[]}
                setSelected={() => console.log("Set Selected")}
                maxTableHeight={"calc(100vh - 400px)"}
                initialVisibleColumns={headCells.filter(cell => cell.initialVisible).map(cell => cell.label)}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
            />

            <Snackbar 
                anchorOrigin={{ vertical: "top", horizontal: "center" }} 
                open={snackbarOpen} 
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert 
                    onClose={() => setSnackbarOpen(false)} 
                    severity={snackbarSeverity} 
                    variant="filled" 
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <ConfirmationDialog
                open={dialogOpen}
                title={dialogTitle}
                content={dialogContent}
                onConfirm={() => {
                    setDialogOpen(false);
                    handleRescheduleJourney();
                }}
                onCancel={() => {
                    setDialogOpen(false);
                }}
            />
        </>
    );
}