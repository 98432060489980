import AdditionalData from "./AdditionalData";
import UserManagement from "./UserManagement";
import { useLocation } from "react-router-dom";

// ==============================|| USERS - DEFAULT ||============================== //

const UsersDefault = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const hasQueryParams = Array.from(searchParams.keys()).length > 0;
    const QueryParams = Array.from(searchParams.keys());

    if (hasQueryParams && QueryParams[0] !== 'tab') {
        return <AdditionalData />;
    } else {
        return <UserManagement />;
    }
};

export default UsersDefault;