// ==============================|| OVERRIDES - INPUT LABEL ||============================== //

export default function InputLabel(theme) {
    return {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.primary,
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '13px',
                },
                outlined: {
                    backgroundColor: 'transparent',
                }
            }
        }
    };
}
