import React, { useState, useMemo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Button, IconButton } from "@mui/material";
import Table from "../../../components/Table/Table";
import {
    useGetTenancyBasicsQuery,
    useLazyGetLocationsByTenancyIdQuery,
    useLazyGetStatesByTenancyIdQuery,
    useLazyGetDepartmentsByTenancyIdQuery,
    useLazyGetCompanyNamesByTenancyIdQuery,
    useLazyGetCitiesByTenancyIdQuery,
    useLazyGetCountriesByTenancyIdQuery,
} from "../../../store/services/tenancy.service";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetUsersQuery } from "../../../store/services/user-service";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import { useTheme } from "@mui/material/styles";

AdditionalDataTable.propTypes = {
    name: PropTypes.oneOf([
        "Department",
        "City",
        "Location",
        "Country",
        "CompanyName",
        "State",
    ]).isRequired,
    type: PropTypes.string.isRequired,
    selectedIds: PropTypes.array,
    setSelectedIds: PropTypes.func,
};

const plural = {
    Location: "locations",
    Department: "departments",
    City: "cities",
    Country: "countries",
    State: "states",
    CompanyName: "company names",
};

export default function AdditionalDataTable({ type, selectedIds = [], setSelectedIds, name }) {
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";
    const { tenancyId } = useSelector((state) => state.tenancy);
    const [selected, setSelected] = useState([]);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 100,
        page: 0,
    });

    // Declare each lazy hook
    const [triggerLocations, { data: locationsData, isLoading: locationsLoading, error: locationsError }] = useLazyGetLocationsByTenancyIdQuery();
    const [triggerStates, { data: statesData, isLoading: statesLoading, error: statesError }] = useLazyGetStatesByTenancyIdQuery();
    const [triggerDepartments, { data: departmentsData, isLoading: departmentsLoading, error: departmentsError }] = useLazyGetDepartmentsByTenancyIdQuery();
    const [triggerCompanyNames, { data: companyNamesData, isLoading: companyNamesLoading, error: companyNamesError }] = useLazyGetCompanyNamesByTenancyIdQuery();
    const [triggerCities, { data: citiesData, isLoading: citiesLoading, error: citiesError }] = useLazyGetCitiesByTenancyIdQuery();
    const [triggerCountries, { data: countriesData, isLoading: countriesLoading, error: countriesError }] = useLazyGetCountriesByTenancyIdQuery();

    const { data: usersData, error: usersError, isLoading: usersLoading } =
        useGetUsersQuery({
            perPage: paginationModel.pageSize,
            page: paginationModel.page,
            includes: "",
            query: "",
            searchTerm: "",
            tenancyId: tenancyId,
            adminFilter: false,
            extendedResults: true,
        });

    // Load the appropriate data based on the plural name
    useEffect(() => {
        if (!tenancyId) return;
        switch (name) {
            case "Location":
                triggerLocations({ tenancyId });
                break;
            case "State":
                triggerStates({ tenancyId });
                break;
            case "Department":
                triggerDepartments({ tenancyId });
                break;
            case "CompanyName":
                triggerCompanyNames({ tenancyId });
                break;
            case "City":
                triggerCities({ tenancyId });
                break;
            case "Country":
                triggerCountries({ tenancyId });
                break;
            default:
                break;
        }
    }, [tenancyId, name, triggerLocations, triggerStates, triggerDepartments, triggerCompanyNames, triggerCities, triggerCountries]);

    // Determine which data to use based on the plural value
    const pluralData = useMemo(() => {
        switch (name) {
            case "Location":
                return locationsData;
            case "State":
                return statesData;
            case "Department":
                return departmentsData;
            case "CompanyName":
                return companyNamesData;
            case "City":
                return citiesData;
            case "Country":
                return countriesData;
            default:
                return [];
        }
    }, [name, locationsData, statesData, departmentsData, companyNamesData, citiesData, countriesData]);


    // Determine loading and error states
    const isLoading = locationsLoading || statesLoading || departmentsLoading || companyNamesLoading || citiesLoading || countriesLoading || usersLoading;
    const error = locationsError || statesError || departmentsError || companyNamesError || citiesError || countriesError || usersError;

    const dataWithUserCount = useMemo(() => {
        if (!pluralData) return [];
        const userCountMap = usersData?.data?.reduce((acc, user) => {
            const key = `user${name}Id`;
            if (user[key]) {
                acc[user[key]] = (acc[user[key]] || 0) + 1;
            }
            return acc;
        }, {});

        return pluralData.map((item) => ({
            name: item.name,
            id: item.id,
            userCount: userCountMap?.[item.id] || 0,
        }));
    }, [pluralData, usersData, name]);

    const handleView = useCallback(
        (data) => {
            navigate(`/users?user${name}Id=${data.id}`, {
                state: { additionalData: data, from: location },
            });
        },
        [navigate, location, name]
    );

    const handleSelect = useCallback(
        (dataRow) => {
            const isSelected = selectedIds.map((selectedId) => selectedId.id).includes(dataRow.id);
            setSelectedIds(
                isSelected
                    ? selectedIds.filter((selectedId) => selectedId.id !== dataRow.id)
                    : [...selectedIds, dataRow]
            );
        },
        [selectedIds, setSelectedIds]
    );

    const headCells = useMemo(
        () => [
            {
                id: "select",
                label: "Select",
                render: (dataRow) => {
                    const isSelected = selectedIds.map((selectedId) => selectedId.id).includes(dataRow.id);
                    return (
                        <IconButton size="small" onClick={() => handleSelect(dataRow)}>
                            {isSelected ? <CheckBoxRoundedIcon color="warning" /> : <CheckBoxOutlineBlankRoundedIcon color={lightMode ? "black" : "white"} />}
                        </IconButton>
                    );
                },
                initialVisible: type === "select",
            },
            { id: "name", label: "Name", initialVisible: true },
            { id: "userCount", label: "User Count", initialVisible: false },
            {
                id: "actions",
                label: type === "select" ? "View" : "",
                render: (dataRow) => (
                    <Button
                        onClick={() => handleView(dataRow)}
                        size="small"
                        variant="contained"
                        color="warning"
                    >
                        View
                    </Button>
                ),
                initialVisible: type === "edit",
            },
        ],
        [name, type, selectedIds, handleSelect, handleView]
    );

    return (
        <Table
            name={plural[name]}
            data={dataWithUserCount}
            error={error}
            loading={isLoading}
            plural={plural[name]}
            headCells={headCells}
            search={true}
            editColumn={false}
            checkbox={false}
            showToolbarActions={true}
            toolbarActions={[]}
            onDelete={() => console.log("Delete Not Allowed")}
            selected={selected}
            setSelected={setSelected}
            maxTableHeight={type === "select" ? "calc(100vh - 400px)" : "calc(100vh - 360px)"}
            initialVisibleColumns={headCells.filter((cell) => cell.initialVisible).map((cell) => cell.label)}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
        />
    );
}
