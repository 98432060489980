import React, { useEffect, useState, useMemo } from 'react';
import { Typography, Divider, Button, Grid, Box, CircularProgress } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import MainCard from "../../components/cards/MainCard";
import ncscIcon from '../../assets/images/news-feed-icons/square-NCSC-logo.jpg';
import bleepingComputerIcon from '../../assets/images/news-feed-icons/square-bleepingComputer-logo.jpg';
import upguardIcon from '../../assets/images/news-feed-icons/square-upguard-logo.png'; 
import infosecurityIcon from '../../assets/images/news-feed-icons/square-infosecurity-logo.jpg'; 
import welivesecurityIcon from '../../assets/images/news-feed-icons/square-welivesecurity-logo.jpg'; 
import hackreadIcon from '../../assets/images/news-feed-icons/square-hackread-logo.jpg'; 
import securityAffairsIcon from '../../assets/images/news-feed-icons/square-securityAffairs-logo.jpg'; 
import gbHackersIcon from '../../assets/images/news-feed-icons/square-gbHackers-logo.jpg';
import mcAfeeIcon from '../../assets/images/news-feed-icons/square-McAfee-logo.jpg';
import { getCardColor } from "../../utils/colorUtils";


    /*
        feeds that need html element refactor: 
        https://www.troyhunt.com/rss/
        https://www.acunetix.com/blog/feed/
        https://www.teramind.co/blog/tag/cybersecurity/feed/
        https://www.webroot.com/blog/feed/
        https://heimdalsecurity.com/blog/feed/
        https://sectigostore.com/blog/feed/
        https://www.itgovernance.co.uk/blog/category/cyber-security/feed
        https://lab.wallarm.com/feed/
        https://www.cybertalk.org/feed/
        https://www.binarydefense.com/feed/
        https://www.logpoint.com/en/feed/
    
    
        feeds that need a unicode refactor:
        https://www.infoguardsecurity.com/feed/
        https://www.nist.gov/blogs/cybersecurity-insights/rss.xml
        https://securityaffairs.com/feed
        
    
        feeds that work as is (or almost work as is):
        https://www.ncsc.gov.uk/api/1/services/v1/all-rss-feed.xml > 20 articles
        https://www.bleepingcomputer.com/feed/  12 articles
        https://krebsonsecurity.com/feed/ 10 articles, article content a little ropey
        https://news.sophos.com/en-us/feed/ 10 articles, article content is very sophos focused
        https://www.infosecurity-magazine.com/rss/news/ 12 articles
        https://simeononsecurity.com/index.xml requires small refactor to categorys / is an individual with a small following will not use
        https://cyberbuilders.substack.com/feed / small following will not use
        https://www.techrepublic.com/rssfeeds/topic/security/?feedType=rssfeeds
        https://www.upguard.com/blog/rss.xml
        https://www.tripwire.com/blogs.xml has a strange Let/node/29512/ character
    */

/*
{
 url: 'https://www.webroot.com/blog/feed/',
 title: 'Webroot',
 imagePath: ncscIcon,
},
*/

const NEWS_SOURCES = [
    {
        url: 'https://www.ncsc.gov.uk/api/1/services/v1/all-rss-feed.xml',
        title: 'National Cyber Security Centre',
        imagePath: ncscIcon,
    },
    /* 
    {
        url: 'https://www.bleepingcomputer.com/feed/',
        title: 'Bleeping Computer',
        imagePath: bleepingComputerIcon,
    },
    */
    {
        url: 'https://www.welivesecurity.com/en/rss/feed/',
        title: 'Welivesecurity',
        imagePath: welivesecurityIcon,
    },
    {
        url: 'https://hackread.com/feed/',
        title: 'Hackread',
        imagePath: hackreadIcon,
    },
    {
        url: 'https://securityaffairs.com/feed',
        title: 'Security Affairs',
        imagePath: securityAffairsIcon,
    },
    {
        url: 'https://www.infosecurity-magazine.com/rss/news/',
        title: 'Infosecurity Magazine',
        imagePath: infosecurityIcon,
    },
    {
        url: 'https://gbhackers.com/feed/',
        title: 'GBHackers',
        imagePath: gbHackersIcon,
    },
    {
        url: 'https://www.mcafee.com/blogs/feed/',
        title: 'McAfee',
        imagePath: mcAfeeIcon,
    },
];

const PROXY_URL = 'https://corsproxy.io/?';

const MAX_ITEMS = 70;

const decodeHTMLEntities = (text) => {
    if (!text) return '';

    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
};

const cleanAndTruncateContent = (content, maxLength = 200) => {
    if (!content) return '';

    let decodedContent = decodeHTMLEntities(content);

    decodedContent = decodedContent.replace(/<[^>]*>/g, '');

    decodedContent = decodedContent.replace(/\s+/g, ' ').trim();

    if (decodedContent.length > maxLength) {
        return decodedContent.substring(0, maxLength) + '...';
    }

    return decodedContent;
};

const RSSFeed = ({ selectedFilter }) => {
    const [feedItems, setFeedItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";

    const parseFeedItem = (item, source) => {
        const title = item.querySelector('title')?.textContent || 'No title';
        const description = item.querySelector('description')?.textContent || 'No description';

        return {
            sourceName: source.title,
            sourceImg: source.imagePath,
            title: cleanAndTruncateContent(title),
            link: item.querySelector('link')?.textContent || '#',
            contentSnippet: cleanAndTruncateContent(description),
            pubDate: item.querySelector('pubDate')?.textContent || null,
        };
    };

    const fetchFeed = async (source) => {
        try {
            const response = await fetch(PROXY_URL + source.url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const text = await response.text();
            const parser = new DOMParser();
            const xml = parser.parseFromString(text, 'application/xml');

            const parserError = xml.querySelector('parsererror');
            if (parserError) {
                throw new Error('XML parsing error');
            }

            return Array.from(xml.querySelectorAll('item'))
                .map(item => parseFeedItem(item, source));
        } catch (err) {
            console.error(`Error fetching ${source.title} feed:`, err);
            return [];
        }
    };

    useEffect(() => {
        const fetchAllFeeds = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const allFeedPromises = NEWS_SOURCES.map(source => fetchFeed(source));
                const allFeedItems = await Promise.all(allFeedPromises);

                const combinedItems = allFeedItems
                    .flat()
                    .sort((a, b) => {
                        const dateA = a.pubDate ? new Date(a.pubDate).getTime() : 0;
                        const dateB = b.pubDate ? new Date(b.pubDate).getTime() : 0;
                        return dateB - dateA;
                    });

                setFeedItems(combinedItems);
            } catch (err) {
                setError('Failed to fetch RSS feeds');
                console.error('Error fetching feeds:', err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAllFeeds();
    }, []);

    // Filter items based on selected filter
    const filteredItems = useMemo(() => {
        if (selectedFilter === "All") {
            return feedItems.slice(0, MAX_ITEMS);
        }
        return feedItems
            .filter(item => item.sourceName === selectedFilter)
            .slice(0, MAX_ITEMS);
    }, [feedItems, selectedFilter]);

    if (isLoading) {
        return (
            <Box
                sx={{
                    height: '80vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: 2
                }}
            >
                <CircularProgress size={60} color="primary" />
                <Typography variant="h6">
                    Loading RSS Feeds...
                </Typography>
            </Box>
        );
    }

    if (error) {
        return (
            <Box
                sx={{
                    height: '80vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography variant="h5" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    return (
        <div className="h-screen overflow-y-scroll p-4">
            <Grid container spacing={1}>
                {filteredItems.map((item, index) => (
                    <Grid item xs={12} md={6} key={index}>
                        <MainCard
                            border={false}
                            boxShadow
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                bgcolor: getCardColor(lightMode),
                            }}
                            content={false}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minHeight: '100%',
                                }}
                            >
                                <Box sx={{ p: 2, flex: '1 1 auto' }}>
                                    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                                        <Box
                                            component="img"
                                            src={item.sourceImg}
                                            alt="Source icon"
                                            sx={{
                                                width: 50,
                                                height: 50,
                                                objectFit: 'contain',
                                                borderRadius: 1.5
                                            }}
                                        />
                                        <Box sx={{ flex: 1 }}>
                                            <Typography variant="h5">
                                                {item.sourceName}
                                            </Typography>
                                            {item.pubDate && (
                                                <Typography variant="caption">
                                                    Published on: {new Date(item.pubDate).toLocaleDateString()}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>

                                    <Typography variant="h5" gutterBottom>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2">
                                        {item.contentSnippet}
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        mt: 'auto',
                                        position: 'relative',
                                        p: 2,
                                        backgroundColor: getCardColor(lightMode),
                                        '&::before': {
                                            content: '""',
                                            position: 'absolute',
                                            top: 0,
                                            left: '16px',
                                            right: '16px',
                                            height: '1px',
                                            bgcolor: 'divider',
                                        }
                                    }}
                                >
                                    <Button
                                        size="small"
                                        href={item.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        variant="contained"
                                        color="warning"
                                    >
                                        Read More
                                    </Button>
                                </Box>
                            </Box>
                        </MainCard>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default RSSFeed;