'use client';
import { APP_URL } from "../../config";
import MainCard from "../../components/cards/MainCard";
import { useState, useEffect } from "react";
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useLazyGetCourseByIdQuery } from "../../store/services/university-service";

export default function TakeCourse() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const email = searchParams.get('emailAddress');
    const [courseData, setCourseData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [getCourseById] = useLazyGetCourseByIdQuery();

    const path = window.location.pathname;
    const encodedCourseId = path.split('takecourse/')[1]?.split('?')[0];
    const courseId = decodeURIComponent(encodedCourseId).replace('+', ' ');

    useEffect(() => {
        if (!email) {
            navigate('/');
            return;
        }

        const fetchCourseData = async () => {
            try {
                setIsLoading(true);
                setError(null);

                const response = await getCourseById({
                    courseId: courseId,
                    includes: ""
                }).unwrap();

                if (response.status === "rejected") {
                    throw new Error("Failed to fetch course content");
                }

                setCourseData(response);
            } catch (err) {
                setError(err.message || "Failed to load course");
                console.error("Error fetching course:", err);
            } finally {
                setIsLoading(false);
            }
        };

        if (courseId) {
            fetchCourseData();
        }
    }, [courseId, getCourseById, email, navigate]);

    if (!email) {
        return null;
    }

    if (isLoading) {
        return (
            <MainCard content={false} sx={{ width: "100%", bgcolor: "common.white" }} border={false} boxShadow={true}>
                <div className="flex items-center justify-center h-80vh">
                    Loading course content...
                </div>
            </MainCard>
        );
    }

    if (error || !courseData) {
        return (
            <MainCard content={false} sx={{ width: "100%", bgcolor: "common.white" }} border={false} boxShadow={true}>
                <div className="flex items-center justify-center h-80vh text-red-500">
                    {error || "Failed to load course content"}
                </div>
            </MainCard>
        );
    }

    return (
        <MainCard content={false} sx={{ width: "100%", bgcolor: "common.white" }} border={false} boxShadow={true}>
            <iframe
                style={{ width: "100%", height: "100vh", border: "none" }}
                title={`course-${courseId}`}
                src={`${APP_URL}/${courseData.courseContentPath}?useremailaddress=${email}&courseidentifier=${courseId}`}
            />
        </MainCard>
    );
}