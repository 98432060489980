import React from 'react';
import { Typography, Box, Divider, useTheme } from '@mui/material';
import { getTextColor } from '../../utils/colorUtils';

const typeColorMap = {
    new: 'primary',
    improved: 'secondary',
    fixed: 'warning'
};



const TypeIndicator = ({ type }) => {
    const theme = useTheme();

    const getBackgroundColor = (colorType) => {
        if (colorType === 'secondary') {
            return theme.palette.secondary.light; // Use dark shade for secondary
        }
        return theme.palette[colorType].main;
    };

    return (
        <Box
            component="span"
            sx={{
                display: 'inline-block',
                padding: '2px 6px',
                borderRadius: '12px',
                backgroundColor: getBackgroundColor(typeColorMap[type]),
                marginRight: 1
            }}
        >
            <Typography
                variant="caption"
                sx={{
                    color: getTextColor(getBackgroundColor(typeColorMap[type])),
                    fontWeight: 'bold'
                }}
            >
                {type.charAt(0).toUpperCase() + type.slice(1)}
            </Typography>
        </Box>
    );
};

const Highlights = ({ data }) => {
    const theme = useTheme();

    const sortedData = Object.entries(data).sort((a, b) => {
        return new Date(b[0]) - new Date(a[0]);
    });

    const typeOrder = ['new', 'improved', 'fixed'];

    return (
        <Box sx={{ maxHeight: '100%', overflow: 'auto' }}>
            <Typography variant="h4"> Highlights </Typography>
            <Divider sx={{ width: "100%", mb: 2 }} />
            {sortedData.map(([date, items]) => (
                <Box key={date} sx={{ mb: 3 }}>
                    <Typography variant="h5" gutterBottom>
                        {date}
                    </Typography>
                    {items
                        .sort((a, b) => typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type))
                        .map((item, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                <TypeIndicator type={item.type} />
                                <Typography variant="body2">{item.text}</Typography>
                            </Box>
                        ))
                    }
                </Box>
            ))}
        </Box>
    );
};

export default Highlights;
