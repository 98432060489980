import { Box, Card, CardContent, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useEffect } from "react";
import UsersTable from "../../users/UsersTable";
import MainCard from "../../../components/cards/MainCard";
import GroupTable from "../Tables/GroupTable";
import PropTypes from "prop-types";
import AdditionalDataTable from "../Tables/AdditionalDataTable";
import { useTheme } from "@mui/material/styles";

RecipientSelection.propTypes = {
    recipient: PropTypes.string.isRequired,
    handleRecipientChange: PropTypes.func.isRequired,
    setRecipientId: PropTypes.func.isRequired
};

export default function RecipientSelection({ recipient, handleRecipientChange, recipientId, setRecipientId }) {
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const isUser = user.role === "";

    // If the user has no admin privileges, we force the recipient to be "User"
    const effectiveRecipient = isUser ? "User" : recipient;

    // Simulate changing toggle button to "User" if the user does not have admin privileges
    useEffect(() => {
        if (isUser && recipient !== "User") {
            const event = {
                target: {
                    value: "User"
                }
            };
            handleRecipientChange(event, "User");
        }
    }, [isUser, recipient, handleRecipientChange]);

    return (
        <Box sx={{ width: "100%" }}>
            {!isUser && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <ToggleButtonGroup
                        exclusive
                        value={effectiveRecipient}
                        onChange={(event, newRecipient) => {
                            if (newRecipient === null) return;
                            handleRecipientChange(event, newRecipient);
                        }}
                        aria-label="recipient"
                        sx={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "center" }}
                    >
                        <ToggleButton value="Tenancy" aria-label="All-Users">
                            All Users
                        </ToggleButton>
                        <ToggleButton value="User" aria-label="Users">
                            User
                        </ToggleButton>
                        <ToggleButton value="Group" aria-label="Groups">
                            Groups
                        </ToggleButton>
                        <ToggleButton value="Department" aria-label="Departments">
                            Department
                        </ToggleButton>
                        <ToggleButton value="Location" aria-label="Locations">
                            Location
                        </ToggleButton>
                        <ToggleButton value="City" aria-label="Cities">
                            City
                        </ToggleButton>
                        <ToggleButton value="Country" aria-label="Countries">
                            Country
                        </ToggleButton>
                        <ToggleButton value="Company" aria-label="Companies">
                            Company
                        </ToggleButton>
                        <ToggleButton value="State" aria-label="States">
                            State
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            )}
            <Box sx={{ width: "100%", mt: 2, display: "flex", justifyContent: "center" }}>
                {effectiveRecipient === "Tenancy" && (
                    <MainCard sx={{
                        width: "50%",
                        textAlign: "center",
                        mt: 5,
                        borderRadius: 2,
                        height: "100%",
                        bgcolor: lightMode ? theme.palette.white : theme.palette.grey[700],
                    }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Courses/Simulations will be sent to all users.
                            </Typography>
                        </CardContent>
                    </MainCard>
                )}
                {effectiveRecipient === "User" && <UsersTable type={"select"} userIds={recipientId} setUserId={setRecipientId} CSVButton={null} />}
                {effectiveRecipient === "Group" && <GroupTable type={"select"} groupIds={recipientId} setGroupId={setRecipientId} />}
                {effectiveRecipient === "Department" && <AdditionalDataTable name={"Department"} type={"select"} selectedIds={recipientId} setSelectedIds={setRecipientId} />}
                {effectiveRecipient === "Location" && <AdditionalDataTable name={"Location"} type={"select"} selectedIds={recipientId} setSelectedIds={setRecipientId} />}
                {effectiveRecipient === "City" && <AdditionalDataTable name={"City"} type={"select"} selectedIds={recipientId} setSelectedIds={setRecipientId} />}
                {effectiveRecipient === "Country" && <AdditionalDataTable name={"Country"} type={"select"} selectedIds={recipientId} setSelectedIds={setRecipientId} />}
                {effectiveRecipient === "Company" && <AdditionalDataTable name={"CompanyName"} type={"select"} selectedIds={recipientId} setSelectedIds={setRecipientId} />}
                {effectiveRecipient === "State" && <AdditionalDataTable name={"State"} type={"select"} selectedIds={recipientId} setSelectedIds={setRecipientId} />}
            </Box>
        </Box>
    );
}