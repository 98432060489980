import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Autocomplete,
    Box,
    Button,
    FormControlLabel,
    Grid,
    IconButton,
    Switch,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { COURSE_CATEGORIES, SIM_AND_COURSE_FORMATS } from "../../config";
import { useGetTenanciesQuery } from "../../store/services/tenancy.service";
import MainCard from "../../components/cards/MainCard";
import { useTheme } from "@mui/material/styles";
import HelpIcon from "@mui/icons-material/HelpOutline";
import IncludeInRandomSwitch from "../../utils/randomSendSwitch";

CourseForm.propTypes = {
    initialData: PropTypes.object,
    onSave: PropTypes.func,
    mode: PropTypes.string
};

export default function CourseForm(props) {
    const { initialData, onSave, mode } = props;
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";
    const user = JSON.parse(localStorage.getItem("user"));

    const isAdmin = user.role === "Administrators";

    const [courseData, setCourseData] = useState(initialData);
    const [loading, setLoading] = useState(false);
    const [isRandomSend, setIsRandomSend] = useState(initialData.randomSend ?? false);

    const courseLanguageCodeOptions = COURSE_CATEGORIES.split(",");
    const courseFormatOptions = SIM_AND_COURSE_FORMATS.split(",");

    const { data: tenanciesData } = useGetTenanciesQuery({ perPage: 1000, page: 0, includes: "", query: "", additionalQueryParams: "" });

    useEffect(() => {
        setCourseData(prevData => ({
            ...prevData,
            randomSend: isRandomSend
        }));
    }, [isRandomSend]);

    const handleRandomSendChange = (event) => {
        setIsRandomSend(event.target.checked);
    };

    const handleSave = () => {
        setLoading(true);
        onSave({ ...courseData, randomSend: Boolean(isRandomSend)});
        setLoading(false);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Course Name"
                    value={courseData.courseName}
                    size="medium"
                    fullWidth
                    onChange={(e) => setCourseData({ ...courseData, courseName: e.target.value })}
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="Scored Out Of"
                    value={courseData.scoredOutOf}
                    size="medium"
                    fullWidth
                    onChange={(e) => setCourseData({ ...courseData, scoredOutOf: e.target.value })}
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="Course Content Path"
                    value={courseData.courseContentPath}
                    size="medium"
                    fullWidth
                    onChange={(e) => setCourseData({ ...courseData, courseContentPath: e.target.value })}
                    sx={{ mb: 2 }}
                    disabled={mode === "view"}
                />
                <Autocomplete
                    id="language-code"
                    size="medium"
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    value={courseData.languageCode}
                    options={courseLanguageCodeOptions}
                    sx={{ mb: 2 }}
                    onChange={(e, value) => setCourseData({ ...courseData, languageCode: value })}
                    renderInput={(params) => <TextField {...params} label="Language" />}
                />
                <Autocomplete
                    id="format-type"
                    size="medium"
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    value={courseData.format}
                    options={courseFormatOptions}
                    sx={{ mb: 2 }}
                    onChange={(e, value) => setCourseData({ ...courseData, format: value })}
                    renderInput={(params) => <TextField {...params} label="Format Type" />}
                />
                <MainCard content={true} boxShadow={false} border={false} sx={{
                    mb: 2,
                    bgcolor: (courseData.published ? (lightMode ? 'primary.light' : 'primary.darker') : (lightMode ? 'grey.200' : 'grey.700'))
                }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <Typography variant="h4">
                            Publish To All Tenancies
                        </Typography>
                        <Switch
                            checked={courseData.published}
                            onChange={(e) => setCourseData({ ...courseData, published: e.target.checked })}
                            inputProps={{ "aria-label": "controlled" }}
                            disabled={mode === "add-on-demand"}
                        />
                    </Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                        mt: 2
                    }}>
                        {!courseData.published && tenanciesData && (
                            <Autocomplete
                                id="tenancy-id"
                                size="small"
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                options={tenanciesData ? tenanciesData : []}
                                disabled={mode === "add-on-demand"}
                                value={tenanciesData.find(row => row.tenancyId === courseData.tenancyId) || null}
                                getOptionLabel={(option) => option.name}
                                sx={{ mt: 2, width: "100%" }}
                                onChange={(e, value) => setCourseData({
                                    ...courseData,
                                    tenancyId: value ? value.tenancyId : 0
                                })}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Tenancy To Publish To"
                                        InputLabelProps={{
                                            style: { backgroundColor: "transparent" }
                                        }}
                                    />
                                )}
                            />
                        )}
                    </Box>
                </MainCard>
                <div>
                   <IncludeInRandomSwitch
                        key={isRandomSend}
                        isChecked={isRandomSend}
                        onChange={handleRandomSendChange}
                    />
                </div>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    color="warning"
                    fullWidth
                >
                    SAVE
                </Button>


            </Grid>
        </Grid>
    );
}
