import React, { useState } from "react";
import OnDemandStepper from "./OnDemandStepper";
import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import ScheduledContentTable from "./Tables/ScheduledContentTable";
import HistoryLogsTable from "./Tables/HistoryLogsTable";
import { ReactComponent as SimulationIcon } from "assets/navigation-icons/simulation.svg";
import { ReactComponent as CourseIcon } from "assets/navigation-icons/course.svg";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`ondemand-tabpanel-${index}`}
            aria-labelledby={`ondemand-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `ondemand-tab-${index}`,
        'aria-controls': `ondemand-tabpanel-${index}`,
    };
}

const OnDemand = () => {
    const [tabValue, setTabValue] = useState(0);
    const [addContent, setAddContent] = useState(false);
    const [format, setFormat] = useState(null);
    const user = JSON.parse(localStorage.getItem('user')) || {};

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleAddContent = (value) => {
        setFormat(value);
        setAddContent(true);
    };

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} md={12} lg={12}>
                {addContent ? (
                    <Box sx={{mb: 2}}>
                        <OnDemandStepper format={format} setAddContent={setAddContent} />
                    </Box>
                ) : (
                    <>
                        <Box display="flex" justifyContent="space-between" sx={{mb: 2}} alignItems="flex-end">
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="on-demand content tabs"
                            >
                                <Tab label="Schedule" {...a11yProps(0)} />
                                <Tab label="History" {...a11yProps(1)} />
                            </Tabs>
                        </Box>

                        <TabPanel value={tabValue} index={0}>
                            <Box mb={2}>
                                <Typography variant="h6" sx={{ textAlign: "left", mb: 2 }}>
                                    Select the type of content to send
                                </Typography>

                                <Button
                                    size="medium"
                                    variant="contained"
                                    sx={{ mb: 2, bgcolor: "primary.light", mr: 2, minWidth: 150}}
                                    startIcon={<SimulationIcon />}
                                    onClick={() => handleAddContent("simulation")}
                                >
                                    Simulation
                                </Button>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    sx={{ mb: 2, bgcolor: "primary.light", minWidth: 150}}
                                    startIcon={<CourseIcon />}
                                    onClick={() => handleAddContent("course")}
                                >
                                    Course
                                </Button>
                            </Box>
                            <Box>
                                <Typography variant="h6" sx={{ textAlign: "left", mb: 2 }}>
                                    Scheduled to send
                                </Typography>
                                    {user?.role === "" ?  
                                    <Typography variant="h6" sx={{ textAlign: "left", mb: 2 }}>
                                    You must be a tenancy admin to view scheduled sends
                                    </Typography> : 
                                    <ScheduledContentTable />}
                            </Box>
                        </TabPanel>

                        <TabPanel value={tabValue} index={1}>
                            {user?.role === "" ?
                            <Typography variant="h6" sx={{ textAlign: "left", mb: 2 }}>
                                You must be a tenancy admin to view send history
                            </Typography> :
                            <HistoryLogsTable />}
                        </TabPanel>
                    </>
                )}
            </Grid>
        </Grid>
    );
}

export default OnDemand;