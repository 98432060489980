import { Alert, Button, Grid, Snackbar, Typography } from "@mui/material";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import MainCard from "../../components/cards/MainCard";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCourseByIdQuery, useCreateCourseMutation, useUpdateCourseMutation } from "../../store/services/university-service";
import CourseForm from "./CourseForm";
import { useState } from "react";
import CourseSkeletonComponent from "../../components/Skeleton/CourseSkeletonComponent";

export default function CourseDetails() {
    const navigate = useNavigate();
    const { id } = useParams();
    const isEditMode = Boolean(id);
    const [createCourse] = useCreateCourseMutation();
    const [updateCourse] = useUpdateCourseMutation();
    const { data: courseData, isLoading } = useGetCourseByIdQuery({ courseId: id, includes: "" }, { skip: !isEditMode });

    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const initialData = isEditMode ? courseData : {
        courseName: "",
        scoredOutOf: "",
        courseContentPath: "",
        languageCode: "",
        format: "",
        published: false,
        tenancyId: 0
    };

    const handleSaveCourse = async (data) => {
        if (isEditMode) {
            data.universityCourseId = id;

            try {
                const response = await updateCourse({ courseData: data });
                if (response.data && response.data.message === "Ok") {
                    setSnackbarMessage("Course updated successfully");
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                } else {
                    setSnackbarMessage("Error occurred while updating course");
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            } catch (e) {
                setSnackbarMessage("Error occurred while updating course");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }

        } else {
            try {
                data.name = data.courseName;
                const response = await createCourse(data);
                if (response.data && response.data.message === "Ok") {
                    setSnackbarMessage("Course created successfully");
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                } else {
                    setSnackbarMessage("Error occurred while creating course");
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            } catch (e) {
                setSnackbarMessage("Error occurred while creating course");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        }
    };

    if (isLoading && isEditMode) return <CourseSkeletonComponent />;

    return (
        <>
            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                <Grid item xs={4}>
                    <Grid container justifyContent="space-between">
                        <Button onClick={() => navigate(-1)} variant="contained" startIcon={<KeyboardArrowLeftTwoToneIcon />}>
                            Back
                        </Button>
                    </Grid>
                    <MainCard sx={{ mt: 2, bgcolor: 'background.default' }} content={true} boxShadow={true} border={false}>
                        <Grid container justifyContent="space-between" sx={{ mb: 2 }}>
                            <Typography variant="h2">
                                {isEditMode ? "View Course" : "Create Course"}
                            </Typography>
                        </Grid>
                        <CourseForm initialData={initialData} onSave={handleSaveCourse} mode={isEditMode ? "view" : "add"} />
                    </MainCard>
                </Grid>
            </Grid>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={snackbarOpen} autoHideDuration={3000}
                      onClose={() => setSnackbarOpen(false)}>
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};
