export function getStatusMessage(statusCode) {
    const messages = {
        200: "OK: The request has succeeded.",
        201: "Created: The request has been fulfilled and has resulted in a new resource being created.",
        202: "Accepted: The request has been accepted for processing, but the processing has not been completed.",
        204: "No Content: The server successfully processed the request, but is not returning any content.",
        301: "Moved Permanently: The requested resource has been assigned a new permanent URI.",
        302: "Found: The requested resource resides temporarily under a different URI.",
        304: "Not Modified: The resource has not been modified since the version specified by the request headers.",
        400: "Bad Request: The server could not understand the request due to invalid syntax.",
        401: "Unauthorized: The client must authenticate itself to get the requested response.",
        403: "Forbidden: The client does not have access rights to the content.",
        404: "Not Found: The server can not find the requested resource.",
        500: "Internal Server Error: The server has encountered a situation it doesn't know how to handle.",
        502: "Bad Gateway: The server, while acting as a gateway or proxy, received an invalid response from the upstream server.",
        503: "Service Unavailable: The server is not ready to handle the request.",
        504: "Gateway Timeout: The server is acting as a gateway or proxy and did not receive a timely response from the upstream server."
    };

    return messages[statusCode] || "Unknown Status Code: Please check the input.";
}
