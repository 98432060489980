import React from 'react';
import { Box, useTheme, Tooltip } from '@mui/material';

const ThemeSquare = ({ theme, onClick, isSelected }) => {
    const muiTheme = useTheme();

    return (
        <Tooltip title={theme.name}>
            <Box
                onClick={() => onClick(theme)}
                sx={{
                    width: 55,
                    height: 55,
                    cursor: 'pointer',
                    boxShadow: muiTheme.customShadows.z1,
                    borderRadius: muiTheme.shape.borderRadius,
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    background: `linear-gradient(135deg, ${theme.primary} 50%, ${theme.secondary} 50%, ${theme.secondary} 80%, ${theme.tertiary} 80%)`,
                    '&:hover': {
                        transform: 'scale(1.1)',
                        boxShadow: muiTheme.customShadows.z16,
                        border: isSelected ? '2px solid black' : '2px solid white',
                    },
                    border: isSelected ? '2px solid black' : 'none',
                    transform: isSelected ? 'scale(1.1)' : 'scale(1)',
                }}
            />
        </Tooltip>
    );
}

export default ThemeSquare;