import MainLayout from "layout/MainLayout";
import Journeys from "../pages/journeys";
import Courses from "../pages/courses";
import Simulations from "../pages/simulations";
import CourseDetails from "../pages/courses/CourseDetails";
import SimulationDetails from "../pages/simulations/SimulationDetails";
import JourneyDetails from "../pages/journeys/JourneyDetails";


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    element: <MainLayout />,
    children: [
        {
            path: "journeys",
            element: <Journeys />
        },
        {
            path: "journeys/:id",
            element: <JourneyDetails />
        },
        {
            path: "journeys/add",
            element: <JourneyDetails />
        },
        {
            path: "courses",
            element: <Courses />
        },
        {
            path: "courses/:id",
            element: <CourseDetails />
        },
        {
            path: "courses/add",
            element: <CourseDetails />
        },
        {
            path: "simulations",
            element: <Simulations />
        },
        {
            path: "simulations/:id",
            element: <SimulationDetails />
        },
        {
            path: "simulations/add",
            element: <SimulationDetails />
        },
        {
            path: "simulations/edit/:id",
            element: <SimulationDetails />
        }

    ]
};

export default MainRoutes;
