import {
    Box,
    Typography,
    Button,
    Grid,
} from "@mui/material";
import MainCard from "../../../components/cards/MainCard";
import { useTheme } from "@mui/material/styles";
import { getCardColor } from "../../../utils/colorUtils";

export default function ReviewAndEdit(props) {
    const theme = useTheme();
    
    
    const {
        format,
        simsAndCoursesToSend,
        delivery,
        scheduledDate,
        startDate,
        endDate,
        pauseOnWeekends,
        recipients,
        recipientsId,
        handleCardClick
    } = props;

    return (
        <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 3 }}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Section
                        title="Content"
                        onEditClick={handleCardClick(0)}
                        content={
                            <Box>
                                <Typography variant="subtitle1">
                                    {format === "simulation" ? "Simulation" : "Course"}
                                </Typography>
                                <Box mt={1}>
                                    {simsAndCoursesToSend.map((item, index) => (
                                        <Typography key={index} variant="body2">
                                            {item.id}
                                        </Typography>
                                    ))}
                                </Box>
                            </Box>
                        }
                        theme={theme}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Section
                        title="Recipients"
                        onEditClick={handleCardClick(1)}
                        content={
                            <Box>
                                <Typography variant="subtitle1">
                                    {recipients === "Tenancy" ? "All Users" : `${recipients}`}
                                </Typography>
                                <Box mt={1}>
                                    {recipients === "User" && recipientsId.map((item, index) => (
                                        <Typography key={index} variant="body2">
                                            {item.sendEmailTo}
                                        </Typography>
                                    ))}
                                    {recipients !== "Tenancy" && recipients !== "User" && recipientsId.map((item, index) => (
                                        <Typography key={index} variant="body2">
                                            {item.name}
                                        </Typography>
                                    ))}
                                </Box>
                            </Box>
                        }
                        theme={theme}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Section
                        title="Schedule"
                        onEditClick={handleCardClick(2)}
                        content={
                            <Box>
                                <Typography variant="subtitle1">
                                    {delivery === "schedule" ? "Scheduled" : delivery === "batchSend" ? "Batch Send" : "Send Immediately"}
                                </Typography>
                                <Box mt={1}>
                                    {delivery === "schedule" && (
                                        <Typography variant="body2">
                                            {scheduledDate?.toLocaleString()}
                                        </Typography>
                                    )}
                                    {delivery === "batchSend" && (
                                        <Typography variant="body2">
                                            {`${startDate?.toLocaleString()} to ${endDate?.toLocaleString()}${pauseOnWeekends ? " (Pausing on weekends)" : ""}`}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        }
                        theme={theme}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

function Section({ title, onEditClick, content, theme }) {
    const lightMode = theme.palette.mode === 'light';

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4">
                    {title}
                </Typography>
                <Button
                    onClick={onEditClick}
                    size="small"
                    variant="contained"
                    color="warning"
                >
                    Edit
                </Button>
            </Box>
            <MainCard
                content={true}
                boxShadow={true}
                border={false}
                sx={{ bgcolor: getCardColor(lightMode)}}
            >
                {content}
            </MainCard>
        </Box>
    );
}
