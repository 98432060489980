// ==============================|| OVERRIDES - MENU ITEM ||============================== //

export default function MenuItem(theme) {
    return {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey["600"] : theme.palette.grey["300"],
                    },
                    borderRadius: theme.borderRadius.md,
                },

            }
        }
    };
}