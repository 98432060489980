function parseJourneyDataMessage(option, startDate) {
    let message;

    // Clone the start date to avoid modifying the original
    let newDate = new Date(startDate);

    // Calculate total days to add
    const daysToAdd = (option.month - 1) * 30 + (option.day - 1);

    // Add the calculated days
    newDate.setDate(newDate.getDate() + daysToAdd);

    // Check if date is on weekend; if so, add days until it's a weekday
    while (newDate.getDay() === 0 || newDate.getDay() === 6) {
        newDate.setDate(newDate.getDate() + 1);
    }

    message = `will be sent on ${formatDate(newDate)}`;

    return { message, isSent: false };
}

function formatDate(date) {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const dayOfWeek = daysOfWeek[date.getDay()];
    const day = date.getDate();
    const month = monthsOfYear[date.getMonth()];
    const year = date.getFullYear();

    const daySuffix = getDaySuffix(day);

    return `${dayOfWeek} ${day}${daySuffix} ${month} ${year}`;
}

function getDaySuffix(day) {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }
}

export default parseJourneyDataMessage;