import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";

import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ open }) => {
    const theme = useTheme();

    const removeData = () => {
        localStorage.removeItem("user");
        document.cookie = "access_token=; Max-Age=0";
        setTimeout(() => window.location.replace("/login"), 200);
    };

    return (
        <List component="nav" sx={{ p: 0, "& .MuiListItemIcon-root": { minWidth: 32, color: theme.palette.grey[500] } }}>
            <ListItemButton
                onClick={removeData}
                sx={{
                    zIndex: 1201,
                    pl: 2,
                    py: 1,
                    marginLeft: 1,
                    marginRight: 1,
                    marginBottom: 1,
                    borderRadius: theme.borderRadius.sm,
                    "&:hover": {
                        bgcolor: theme.palette.secondary.lighter
                    },
                    "&.Mui-selected": {
                        bgcolor: theme.palette.secondary.lighter,
                        color: theme.palette.secondary.main,
                        "&:hover": {
                            bgcolor: theme.palette.secondary.lighter,
                            color: theme.palette.secondary.main
                        }
                    }
                }}
            >
                <ListItemIcon>
                    <LogoutOutlinedIcon id="logout-button" color="primary" />
                </ListItemIcon>
                {open && (
                    <ListItemText
                        primary={
                            <Typography variant="h6" color={"text.primary"}>
                                Logout
                            </Typography>
                        }
                    />
                )}
            </ListItemButton>
        </List>
    );
};

ProfileTab.propTypes = {
    open: PropTypes.bool,
    handleLogout: PropTypes.func
};

export default ProfileTab;
