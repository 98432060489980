import PropTypes from 'prop-types';
import { useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Divider,
    Drawer,
    useMediaQuery
} from "@mui/material";

// project import
import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';
import { APP_URL, drawerWidth } from "config";
import Profile from "../Header/HeaderContent/Profile";
import logoSmall from 'assets/images/icons/icon-home-boxphish.svg';
import { useGetTenancyBasicsQuery } from "../../../store/services/tenancy.service";
import { useSelector } from "react-redux";

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

const MainDrawer = ({ open, handleDrawerToggle, window }) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchDownXS = useMediaQuery(theme.breakpoints.down('xs'));
    const { tenancyId } = useSelector((state) => state.tenancy);

    // responsive drawer container
    const container = window !== undefined ? () => window().document.body : undefined;

    const { data: tenancyData, error: tenancyError, isLoading: tenancyLoading } = useGetTenancyBasicsQuery({
        tenancyId: tenancyId
    });

    // header content
    const drawerHeader = useMemo(() => <DrawerHeader open={open} handleDrawerToggle={handleDrawerToggle} />, [open, handleDrawerToggle]);
    const drawerContent = useMemo(() => <DrawerContent open={open} handleDrawerToggle={handleDrawerToggle} />, [open, handleDrawerToggle]);

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1300 }} aria-label="mailbox folders">
            {!matchDownSM ? (
                <MiniDrawerStyled variant="permanent" open={open}>
                    {open && drawerHeader}
                    {!open &&
                        <>
                            {!tenancyLoading && !tenancyError && tenancyData && tenancyData.logo ?
                                <img src={`${APP_URL}/${tenancyData.logo}`} alt="Logo" width="48" height="48" style={{ objectFit: "contain", marginTop: 8, alignSelf: 'center' }} /> :
                                <span style={{ marginLeft: 20, marginTop: 20 }}><img src={logoSmall} alt="small logo" width={32} height={32} /></span>
                            }
                        </>
                    }

                    {drawerContent}

                    { open && <Divider/> }
                    <Profile open={open} />
                </MiniDrawerStyled>
            ) : (
                <>
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={open}
                        onClose={handleDrawerToggle}
                        ModalProps={{ keepMounted: true }}
                        sx={{
                            display: { xs: 'block', lg: 'none' },
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: { xs: 170, md: drawerWidth },
                                backgroundImage: 'none',
                            },
                        }}
                    >
                        {matchDownXS && <img src={logoSmall} alt="small logo" width={50} height={50} /> }
                        {open && drawerContent}
                    </Drawer>
                </>
            )}
        </Box>
    );
};

MainDrawer.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default MainDrawer;
