import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, CircularProgress, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import GoogleIcon from "@mui/icons-material/Google";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import CloudIcon from '@mui/icons-material/Cloud';
import ErrorIcon from '@mui/icons-material/Error';
import { getCardColor } from "../../utils/colorUtils";

const StatusCard = styled(Box)(({ theme }) => ({
    display: 'flex',
    borderRadius: theme.borderRadius.sm, 
    height: 50,
    width: '100%',
    overflow: 'hidden',
    boxShadow: theme.customShadows.z1,
    ':hover': {
        boxShadow: theme.customShadows.z1
    },
}));

const ColoredSection = styled(Box)(({ theme, bgcolor }) => ({
    width: '75%',
    backgroundColor: bgcolor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: theme.borderRadius.sm, 
    borderBottomLeftRadius: theme.borderRadius.sm,
}));

const IconSection = styled(Box)(({ theme }) => ({
    width: '25%',
    backgroundColor: getCardColor(theme.palette.mode === 'light'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: theme.borderRadius.sm, 
    borderBottomRightRadius: theme.borderRadius.sm,
}));

const SyncStatusCard = ({ status }) => {
    const theme = useTheme();
    const lightMode = theme.palette.mode === 'light';

    const getColor = () => {
        if (status === 'Google' || status === 'Microsoft' || status === "Other") return `#74E1BF`
        else if (status === 'Error') return 'error.main';
        else return getCardColor(lightMode);
    };

    const getText = () => {
        if (status === 'Google' || status === 'Microsoft' || status === "Other") return 'active'
        else if (status === 'Error') return 'error';
        else return 'loading';
    };

    const getIcon = () => {
        switch (status) {
            case 'Google':
                return <GoogleIcon />;
            case 'Microsoft':
                return <MicrosoftIcon />;
            case 'Other':
                return <CloudIcon />;
            case 'Loading':
                return <CircularProgress />;
            case 'Error':
                return <ErrorIcon />;
            default:
                return <ErrorIcon />;
        }
    };

    return (
        <StatusCard>
            <ColoredSection bgcolor={getColor()}>
                <Typography variant="h3" color="text.primary">
                    {getText().toUpperCase()}
                </Typography>
            </ColoredSection>
            <IconSection>
                {getIcon()}
            </IconSection>
        </StatusCard>
    );
};

SyncStatusCard.propTypes = {
    status: PropTypes.oneOf(['Active', 'Loading', 'Error']).isRequired,
    syncType: PropTypes.oneOf(['Google', 'Microsoft', 'Other', 'Error']).isRequired,
};

export default SyncStatusCard;