export default function AutoComplete(theme) {
    return {
        MuiAutocomplete: {
            styleOverrides: {
                listbox: {
                    // Dropdown options styling
                    '& .MuiAutocomplete-option': {
                        fontSize: '13px',
                        fontFamily: 'Inter',
                    },
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey["800"] : theme.palette.background.default,
                },

                // Styles for the text field within the Autocomplete
                inputRoot: {
                    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
                        fontSize: '14px',
                        fontFamily: 'Inter',
                        fontWeight: 500,

                        '&.MuiInputLabel-outlined': {
                            backgroundColor: 'transparent',
                        },
                    },
                },
                // Adding styles for the paper component that houses the options
                paper: {
                    borderRadius: theme.borderRadius.sm,
                    bgcolor: theme.palette.background.default,
                },
                // Adding styles for the label to be transparent
                inputLabel: {
                    '&.MuiInputLabel-root': {
                        color: 'transparent',
                    },
                },
            },
        },
    };
}
