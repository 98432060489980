import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Divider } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { getTextColor } from "../utils/colorUtils";

const ConfirmationDialog = ({ open, title, content, onConfirm, onCancel }) => {
    const theme = useTheme();

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { borderRadius: "8px", backgroundColor: theme.palette.background.default } }}
        >
            <DialogTitle id="alert-dialog-title" sx={{ fontSize: theme.typography.h5.fontSize }}>
                {title}
            </DialogTitle>
            <Divider sx={{ marginRight: 2, marginLeft: 2, marginTop: -1 }} />
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ color: getTextColor(theme.palette.background.default) }}>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                    size="small"
                    variant="outlined"
                    color="black"
                >
                    Cancel
                </Button>
                <Button
                    onClick={onConfirm}
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;