import {
    Grid, Stack, Typography, CircularProgress,
} from "@mui/material";
import MainCard from "../../components/cards/MainCard";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useCreateOfficeTenantImportMutation, } from "../../store/services/tenancy.service";
import { useNavigate } from "react-router-dom";

export default function OfficeSyncPluginHandler() {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem("user"));
    const { tenancyId } = useSelector((state) => state.tenancy);
    const urlParams = new URLSearchParams(window.location.search);
    const tenant = urlParams.get("tenant");

    const [createOfficeTenantImportMutation] = useCreateOfficeTenantImportMutation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (tenant) {
            createOfficeTenantImportMutation({
                tenancyId: tenancyId,
                tenant: tenant,
                email: user.email
            })
                .unwrap()
                .then(() => {
                    setLoading(false);
                    navigate("/users?tab=1&importGroup=true");
                })
                .catch((err) => {
                    setError("Failed to start the import. Please try again later.");
                    setLoading(false);
                });
        }
    }, [tenant, tenancyId, user.email, createOfficeTenantImportMutation]);


    if (loading) {
        return (
            <MainCard boxShadow={true} border={false}
                      sx={{
                          minHeight: "calc(100vh)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                      }}>
                <CircularProgress />
            </MainCard>
        );
    }

    if (error) {
        return (
            <MainCard boxShadow={true} border={false}
                      sx={{
                          minHeight: "calc(100vh)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                      }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={8}>
                        <Stack direction="column" spacing={4} alignItems="center">
                            <Typography variant="h4" align="center" color="error">
                                There was an error starting the import. Please try again later.
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
        );
    }

    return (
        <></>
    );
}
