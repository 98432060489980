import { useState } from "react";
import { useCancelOnDemandScheduledContentMutation, useGetOnDemandAuditLogQuery } from "../../../store/services/ondemand-service";
import { useSelector } from "react-redux";
import Table from "../../../components/Table/Table";
import { Circle } from "@mui/icons-material";
import { Alert, Button, Snackbar } from "@mui/material";
import { formatDateTime } from "../../../utils/formatDateTime";
import ConfirmationDialog from "../../../utils/confirmationDialog";


export default function ScheduledContentTable() {
    const { tenancyId } = useSelector((state) => state.tenancy);
    const { data: auditData, isLoading: auditLoading, error: auditError } = useGetOnDemandAuditLogQuery({ tenancyId });

    const [cancelOnDemandScheduledContent] = useCancelOnDemandScheduledContentMutation();

    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    // Confirmation Dialog
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogContent, setDialogContent] = useState("");
    const [dialogAction, setDialogAction] = useState(null);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 100,
        page: 0
    });

    const handleCancelScheduledContent = (onDemandId) => {
        setDialogTitle("Cancel Scheduled Content");
        setDialogContent("Are you sure you want to cancel sending this content? This action is irreversible.");
        setDialogAction(() => () => {
            cancelOnDemandScheduledContent({ tenancyId, onDemandId })
                .unwrap()
                .then(() => {
                    setSnackbarMessage("Content successfully cancelled");
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                })
                .catch((error) => {
                    console.error("Error cancelling content", error);
                    setSnackbarMessage("Error cancelling content");
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                });
        });
        setDialogOpen(true);
    }

    const headCells = [
        { id: "sentTypeName", label: "Content", initialVisible: true },
        { id: "sentType", label: "Format", initialVisible: true },
        { id: "dateTime", label: "Date to send", initialVisible: true },
        {id:"createdAt", label:"Created on", initialVisible: true },
        { id: "sentTo", label: "Recipients", initialVisible: true },
        { id: "status", label: "Status", initialVisible: true },
        {
            id: "status-label",
            label: " ",
            initialVisible: true, 
            render: (item) =>
                item.status === "Waiting to send the email" ? (
                    <Circle sx={{ height: 8, width: 8 }} color="warning" />
                ) : item.status === "Completed sending emails" ? (
                    <Circle sx={{ height: 8, width: 8 }} color="primary" />
                ) : (
                    <Circle sx={{ height: 8, width: 8 }} color="error" />
                )
        },
        {
            id: "action",
            label: "Action",
            initialVisible: true,
            render: (item) => (
                <Button
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={() => handleCancelScheduledContent(item.stepFunctionId)}
                >
                    Cancel
                </Button>
            )
        }
    ];

    const formattedAuditData = auditData ? auditData.map(item => ({
        ...item,
        dateTime: formatDateTime(item.waitingTime),
        createdAt: formatDateTime(item.dateTime)
    })) : [];

    return (
        <> 
            <Table
                name={"scheduled on-demand sims and courses"}
                data={formattedAuditData}
                error={auditError}
                loading={auditLoading}
                headCells={headCells}
                search={false}
                editColumn={false}
                checkbox={false}
                toolbarActions={null}
                showToolbarActions={false}
                selected={[]}
                setSelected={[]}
                maxTableHeight="calc(100vh - 250px)"
                initialVisibleColumns={headCells.filter(cell => cell.initialVisible).map(cell => cell.label)}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
            />
        
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled" sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <ConfirmationDialog
                open={dialogOpen}
                title={dialogTitle}
                content={dialogContent}
                onConfirm={() => {
                    setDialogOpen(false);
                    dialogAction();
                }}
                onCancel={() => {
                    setDialogOpen(false);
                }}
            />
        </>

    );
}
