import React, { useState } from "react";
import { Modal, Box, Button, Typography, CircularProgress, IconButton, Snackbar, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useUploadToS3Mutation } from "../../../store/services/user-service";
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';

ImportCsvModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};

const validateCSVContent = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const text = event.target.result;
            const lines = text.split('\n');

            // Skip header row and empty last line if exists
            const dataLines = lines.slice(1).filter(line => line.trim());

            const errors = [];

            dataLines.forEach((line, index) => {
                const columns = line.split(',');
                const email = columns[0]?.trim();

                if (!email) {
                    errors.push(`Line ${index + 2}: Missing email address`);
                } else if (!validateEmail(email)) {
                    errors.push(`Line ${index + 2}: "${email}" is not a valid email address`);
                }
            });

            if (errors.length > 0) {
                reject({ errors });
            } else {
                resolve(true);
            }
        };

        reader.onerror = () => reject({ errors: ['Error reading file'] });
        reader.readAsText(file);
    });
};

export default function ImportCsvModal({ open, onClose }) {
    const theme = useTheme();
    const { tenancyId } = useSelector((state) => state.tenancy);
    const [csvFile, setCsvFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [validationErrors, setValidationErrors] = useState([]);

    const [uploadToS3] = useUploadToS3Mutation();

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        setCsvFile(file);
        setValidationErrors([]);

        if (file) {
            try {
                await validateCSVContent(file);
            } catch (error) {
                setValidationErrors(error.errors);
                setSnackbarMessage("Please fix the validation errors before uploading.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        }
    };

    const handleDownloadCSV = () => {
        const link = document.createElement('a');
        link.href = "/csv-files/template.csv";
        link.download = 'add-users-template.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleUploadCSV = async () => {
        if (!csvFile) {
            setSnackbarMessage("Please select a CSV file.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        if (validationErrors.length > 0) {
            setSnackbarMessage("Please fix validation errors before uploading.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        setIsUploading(true);
        if (csvFile.type === "application/vnd.ms-excel" || csvFile.type === "text/csv") {
            try {
                const response = await uploadToS3({ tenancyId, file: csvFile }).unwrap();
                if (response.success) {
                    setSnackbarMessage("Users uploaded successfully.");
                    setSnackbarSeverity("success");
                } else {
                    setSnackbarMessage("Error uploading users.");
                    setSnackbarSeverity("error");
                }
            } catch (error) {
                console.log(`Error uploading users: ${error}`);
                setSnackbarMessage("Error uploading users.");
                setSnackbarSeverity("error");
            }
        } else {
            setSnackbarMessage("Invalid file type. Please upload a CSV file.");
            setSnackbarSeverity("error");
        }
        setSnackbarOpen(true);
        setIsUploading(false);
        onClose();
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="import-csv-modal-title"
                aria-describedby="import-csv-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80%",
                        maxWidth: 650,
                        boxShadow: theme.customShadows.z1,
                        backgroundColor: theme.palette.background.default,
                        borderRadius: theme.borderRadius.sm,
                        p: "26px 32px"
                    }}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography id="import-csv-modal-title" variant="h4" gutterBottom>
                            IMPORT USERS VIA CSV
                        </Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <input
                        type="file"
                        accept=".csv,application/vnd.ms-excel,text/csv"
                        onChange={handleFileChange}
                        style={{
                            width: "100%",
                            marginBottom: '20px',
                            border: '2px dashed #000',
                            padding: '10px',
                            borderRadius: '4px',
                        }}
                    />

                    {validationErrors.length > 0 && (
                        <Alert
                            severity="error"
                            sx={{
                                mb: 2,
                                '& .MuiAlert-message': {
                                    width: '100%'
                                }
                            }}
                        >
                            <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                Validation Errors
                            </Typography>
                            <Box
                                component="ul"
                                sx={{
                                    m: 0,
                                    pl: 2,
                                    maxHeight: '150px',
                                    overflowY: 'auto'
                                }}
                            >
                                {validationErrors.map((error, index) => (
                                    <Typography
                                        component="li"
                                        key={index}
                                        variant="body2"
                                        sx={{ mb: 0.5 }}
                                    >
                                        {error}
                                    </Typography>
                                ))}
                            </Box>
                        </Alert>
                    )}

                    <Box mt={3} display="flex" justifyContent="flex-start">
                        <Button
                            onClick={handleDownloadCSV}
                            variant="outlined"
                            color="inherit"
                            sx={{ mr: 2, flexGrow: 1 }}
                        >
                            Download CSV Template
                            <DownloadIcon sx={{ ml: 1 }} />
                        </Button>
                        <Button
                            onClick={handleUploadCSV}
                            variant="contained"
                            color="primary"
                            disabled={isUploading || validationErrors.length > 0}
                            sx={{ flexGrow: 1 }}
                        >
                            {isUploading ? <CircularProgress size={24} /> : <>Import Users <UploadIcon sx={{ ml: 1 }} /></>}
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }} variant="filled">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}