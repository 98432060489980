import { getTextColor } from '../../utils/colorUtils';
export default function Alert(theme) {
    return {
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: theme.borderRadius.sm,
                    color: getTextColor(theme.palette.primary.main),
                },
                filledSuccess: {
                    backgroundColor: theme.palette.primary.main,
                    color: getTextColor(theme.palette.primary.main)
                },
                filledError: {
                    backgroundColor: theme.palette.error.main,
                    color: getTextColor(theme.palette.error.main)
                },
                filledInfo: {
                    backgroundColor: theme.palette.secondary.light,
                    color: getTextColor(theme.palette.secondary.light)
                },
                filledWarning: {
                    backgroundColor: theme.palette.warning.main,
                    color: getTextColor(theme.palette.warning.main)
                },
                standardSuccess: {
                    backgroundColor: theme.palette.primary.main,
                    color: getTextColor(theme.palette.primary.main)
                },
                standardError: {
                    backgroundColor: theme.palette.error.main,
                    color: getTextColor(theme.palette.error.main)
                },
                standardInfo: {
                    backgroundColor: theme.palette.secondary.light,
                    color: getTextColor(theme.palette.secondary.light)
                },
                standardWarning: {
                    backgroundColor: theme.palette.warning.dark,
                    color: getTextColor(theme.palette.warning.dark)
                }
            },
        },
    };
}