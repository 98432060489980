import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import JourneyTable from "./JourneyTable";

JourneyHistory.propTypes = {
    user: PropTypes.object.isRequired,
};

export default function JourneyHistory(props) {
    const { user } = props;
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <JourneyTable type={'History'} user={user} />
            </Grid>
        </Grid>
    );
};