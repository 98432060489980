import React, { createContext, useState, useContext, useEffect } from 'react';
import { getThemeColors, getThemeMode, saveThemeColors, saveThemeMode } from './themeColourManager';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [customColors, setCustomColors] = useState(() => getThemeColors() || {
        primary: '#74E1BF',
        secondary: '#4281EE',
        warning: '#FDC95D'
    });
    const [themeMode, setThemeMode] = useState(() => getThemeMode() || 'light');

    const updateThemeColors = async (newColors) => {
        await saveThemeColors(newColors);
        setCustomColors(newColors);
    };

    const updateThemeMode = async (newMode) => {
        await saveThemeMode(newMode);
        setThemeMode(newMode);
    };

    useEffect(() => {
        const loadedColors = getThemeColors();
        if (loadedColors) {
            setCustomColors(loadedColors);
        }
        const mode = getThemeMode();
        if (mode) {
            setThemeMode(mode);
        }
    }, []);

    return (
        <ThemeContext.Provider value={{ customColors, themeMode, updateThemeColors, updateThemeMode }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useThemeContext = () => useContext(ThemeContext);