import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const JourneySkeletonComponent = () => {
    return (
        <Box>
            <Skeleton variant="rounded" width={100} height={40} sx={{ mb: 2 }} />
            <Skeleton variant="text" width="10%" height={80} sx={{ mb: 4 }} />

            <Stack spacing={3} direction="column">
                <Skeleton variant="rounded" width="50%" height={30} />
                <Skeleton variant="rounded" width="100%" height={100} />
            </Stack>

            <Skeleton variant="rounded" width={'10%'} height={40} sx={{ my: 3 }} />

            <Skeleton variant="text" width="15%" height={80} sx={{ mb: 2 }} />
            <Skeleton variant="rounded" width="50%" height={40} sx={{ mb: 2 }} />
            <Skeleton variant="rounded" width="100%" height={40} sx={{ mb: 4 }} />

            <Stack spacing={2} direction="row" justifyContent={'space-between'} sx={{ mb: 4}}>
                <Skeleton variant="circular" width={40} height={40} sx={{ mb: 4 }} />
                <Skeleton variant="rounded" width="80%" height={40} sx={{ mb: 4 }} />
                <Skeleton variant="rounded" width="10%" height={40} sx={{ mb: 4 }} />
            </Stack>

            <Stack spacing={2} direction="row" justifyContent={'space-between'} sx={{ mb: 4}}>
                <Skeleton variant="circular" width={40} height={40} sx={{ mb: 4 }} />
                <Skeleton variant="rounded" width="80%" height={40} sx={{ mb: 4 }} />
                <Skeleton variant="rounded" width="10%" height={40} sx={{ mb: 4 }} />
            </Stack>

        </Box>
    );
};

export default JourneySkeletonComponent;
