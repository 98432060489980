import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));

export const register = createAsyncThunk("auth/register", async ({ name, email, password, passwordConfirm, company }, thunkAPI) => {
    try {
        const response = await AuthService.register(name, email, password, passwordConfirm, company);
        thunkAPI.dispatch(setMessage(response.data.message));
        return response.data;
    } catch (error) {
        let message = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue(message);
    }
});

export const login = createAsyncThunk("auth/login", async ({ email, password }, thunkAPI) => {
    try {
        const data = await AuthService.login(email, password);
        return { user: data };
    } catch (error) {
        let message = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
        if (error?.response?.status === 401 || error?.response?.status === 403)
        {
            message = "Incorrect Username or Password"
        }
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue(message);
    }
});


export const ssoAuth = createAsyncThunk("auth/ssoauth", async ({ token }, thunkAPI) => {
    try {
        const data = await AuthService.ssoLogin(token);
        return { user: data };
    } catch (error) {
        console.log("Error: ", error);
        const message = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue(message);
    }
});

export const googleSsoAuth = createAsyncThunk("auth/google-sso", async ({ token }, thunkAPI) => {
    try {
        const data = await AuthService.GoogleSsoLogin(token);
        return { user: data };
    } catch (error) {
        console.log("Error: ", error);
        const message = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue(message);
    }
});


export const logout = createAsyncThunk("auth/logout", async () => {
    await AuthService.logout();
});


export const forgot = createAsyncThunk("auth/login", async ({ email, siteUrl }, thunkAPI) => {
    try {
        const data = await AuthService.forgot(email, siteUrl);
        return { user: data };
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue(message);
    }
});

export const resetPassword = createAsyncThunk("auth/resetPassword", async ({ email, token, newPassword }, thunkAPI) => {
        try {
            const response = await AuthService.resetPassword(email, token, newPassword);
            thunkAPI.dispatch(setMessage("Password reset successfully"));
            return response.data;
        } catch (error) {
            console.error('Error in resetPassword thunk:', error);
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const token = createAsyncThunk("auth/logout", async () => {
    //debugger
});

const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null };

const authSlice = createSlice({
    name: "auth",
    initialState,
    extraReducers: {
        [register.fulfilled]: (state) => {
            state.isLoggedIn = false;
        },
        [register.rejected]: (state) => {
            state.isLoggedIn = false;
        },
        [login.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [login.rejected]: (state) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        [ssoAuth.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [ssoAuth.rejected]: (state) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        [googleSsoAuth.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [googleSsoAuth.rejected]: (state) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        [logout.fulfilled]: (state) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        [token.fulfilled]: (state, action) => {
            state.token = action.payload.token;
            state.isLoggedIn = true;
        },
    }
});

const { reducer } = authSlice;
export default reducer;

/*
export const forgot = createAsyncThunk("auth/login", async ({ email }, thunkAPI) => {
    try {
        const data = await AuthService.forgot(email);
        return { user: data };
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue(message);
    }
});
*/