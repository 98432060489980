import { useEffect, useState } from "react";
import { Grid, Stack, Typography, Step, StepLabel, Stepper, Box } from "@mui/material";
import MainCard from "../../components/cards/MainCard";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useGetImportStatusQuery } from "../../store/services/tenancy.service";
import { useSelector } from "react-redux";
import ColorLibConnector from "../../components/ColorLibConnector";
import CustomStepIcon from "../../components/Icon/CustomStepIcon";
import ImportGroupTable from "../users/ImportGroupTable";

const STEPS = ["Start", "In Progress", "Success"];

export default function OfficeAuthHandler() {
    const { tenancyId } = useSelector((state) => state.tenancy);
    const { data: importStatusData, isLoading: importStatusLoading, error: importStatusError, refetch } = useGetImportStatusQuery({ tenancyId });
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});

    // Function to update the stepper based on the status
    const updateStepper = (status) => {
        switch (status) {
            case "Start":
                setActiveStep(0);
                setCompleted({});
                break;
            case "In Progress":
                setActiveStep(1);
                setCompleted({});
                break;
            case "Success":
                setActiveStep(3);
                /*setCompleted({ 2: true }); // Mark the "Success" step as completed*/
                break;
            case "Error":
                setActiveStep(2);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        // Immediately update the stepper on initial render
        if (importStatusData && !importStatusLoading) {
            updateStepper(importStatusData[1].status);
        }

        // Define the polling function
        const pollImportStatus = () => {
            if (importStatusData[1].status !== "Success" && importStatusData[1].status !== "Error") {
                refetch(); // Refetch the import status data
            }
        };

        // Set up an interval to poll the status every 2 seconds
        const intervalId = setInterval(pollImportStatus, 2000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [importStatusData, refetch]);

    useEffect(() => {
        // Update the stepper whenever the importStatusData changes
        if (importStatusData && !importStatusLoading) {
            updateStepper(importStatusData[1].status);
        }
    }, [importStatusData]);

    return (
        <MainCard boxShadow={true} border={false} sx={{ minHeight: "calc(100vh)", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={12} lg={12}>
                    <Stack direction="column" spacing={4} alignItems="center">
                        <CheckCircleOutlineIcon color="primary" style={{ fontSize: 50 }} />
                        <Typography variant="h4" align="center">
                            The import of your Microsoft 365 groups {importStatusData && importStatusData[1].status === "Success" ? "has been completed" : "has been started"}.
                        </Typography>
                        <Box width={"100%"}>
                            <Stepper activeStep={activeStep} alternativeLabel connector={<ColorLibConnector />}>
                                {STEPS.map((label, index) => (
                                    <Step key={label} completed={completed[index]}>
                                        <StepLabel StepIconComponent={(props) => <CustomStepIcon {...props} />}>
                                            {label}
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>

                        {importStatusError && (
                            <Typography color="error" align="center">
                                There was an error with the import process. Please try again.
                            </Typography>
                        )}

                        {(activeStep === 1 || activeStep === 0) && (
                            <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                                The import process may take some time. You can check back later to select the groups once the import is complete in the User Sync section.
                            </Typography>
                        )}
                    </Stack>
                    {activeStep === 3 && (
                        <Box mt={4} width={"100%"}>
                            <ImportGroupTable closeModal={() => console.log("Close Modal")} />
                        </Box>

                    )}
                </Grid>
            </Grid>
        </MainCard>
    );
}
