import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ONDEMAND_API_URL } from "config";

const baseQuery = fetchBaseQuery({
    baseUrl: ONDEMAND_API_URL,
    prepareHeaders: (headers) => {
        const user = localStorage.getItem("user");

        if (user) {
            let userToken = JSON.parse(user).token;
            headers.set("authorization", `Bearer ${userToken}`);
        }
        return headers;
    },
    credentials: "omit"
});

export const onDemandApi = createApi({
    reducerPath: "onDemandApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getOnDemandAuditLog: builder.query({
            query: ({ tenancyId }) => `tenancy/${tenancyId}/audit/ondemand`,
            transformResponse(baseQueryReturnValue) {
                return baseQueryReturnValue.filter(audit => audit.type === "OnDemand" && audit.status === "Waiting to send the email");
            },
            transformErrorResponse(baseQueryReturnValue) {
                return baseQueryReturnValue.status.query();
            }

        }),
        getOnDemandHistoryLogs: builder.query({
            query: ({ tenancyId }) => `tenancy/${tenancyId}/audit`,
            transformResponse(baseQueryReturnValue) {
                return baseQueryReturnValue.filter(audit => audit.type === "OnDemand" && audit.status === "Completed sending emails");
            },
            transformErrorResponse(baseQueryReturnValue) {
                return baseQueryReturnValue.status.query();
            }
        }),
        // Separate mutation for audit log
        createOnDemandAuditLog: builder.mutation({
            query: ({ tenancyId, onDemandId }) => ({
                url: `tenancy/${tenancyId}/audit/ondemand/${onDemandId}`,
                method: "POST",
                body: {}
            })
        }),
        // Updated cancel mutation
        cancelOnDemandScheduledContent: builder.mutation({
            query: ({ tenancyId, onDemandId }) => ({
                url: `tenancy/${tenancyId}/ondemand/${onDemandId}/stop`,
                method: "POST",
                body: {}
            }),
            async onQueryStarted({ tenancyId, onDemandId }, { dispatch, queryFulfilled }) {
                try {
                    // Wait for the stop request to complete
                    await queryFulfilled;

                    // Trigger the audit log mutation
                    await dispatch(
                        onDemandApi.endpoints.createOnDemandAuditLog.initiate({
                            tenancyId,
                            onDemandId
                        })
                    ).unwrap();

                    // Invalidate the queries to refresh the data
                    dispatch(
                        onDemandApi.util.invalidateTags([
                            'OnDemandAuditLog',
                            'OnDemandHistoryLogs'
                        ])
                    );
                } catch (error) {
                    console.error('Error in cancelOnDemandScheduledContent:', error);
                    throw error; // Re-throw to ensure the error is caught by the component
                }
            }
        })
    })
});

export const {
    useGetOnDemandAuditLogQuery,
    useGetOnDemandHistoryLogsQuery,
    useCancelOnDemandScheduledContentMutation
} = onDemandApi;
