import {Grid,} from "@mui/material";
import SimulationTable from "./SimulationTable";

// ==============================|| SIMULATION - DEFAULT ||============================== //

const SimulationsDefault = () => {

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} md={12} lg={12}>
                <SimulationTable type={'edit'} />
            </Grid>
        </Grid>
    );
};

export default SimulationsDefault;
