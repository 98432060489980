import React, { useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import { useTheme } from "@mui/material/styles";
import Table from "../../components/Table/Table";
import { useGetCachedOfficeDataQuery, useUpdateAutoSyncMutation } from "../../store/services/tenancy.service";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";


export default function ImportGroupTable({closeModal}) {
    const theme = useTheme();
    const lightMode = theme.palette.mode === 'light';
    const { tenancyId } = useSelector((state) => state.tenancy);

    const {
        data: officeData,
        isLoading: officeDataLoading,
        error: officeDataError
    } = useGetCachedOfficeDataQuery({ tenancyId: tenancyId });

    const [updateAutoSync] = useUpdateAutoSyncMutation();

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0
    });

    const [selected, setSelected] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [autoSync, setAutoSync] = useState(false);

    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSaveGroups = () => {
        setLoading(true);

        const updatedGroups = selectedGroups.map(group => ({
            ...group,
            AutoSync: autoSync,
            CreateMe: true,
        }));
        let type = "google";

        if (updatedGroups.length > 0 && updatedGroups[0].Origin.Type === "office") {
            type = "office";
        }

        const body = {
            TenancyId: tenancyId,
            Users: [],
            Groups: updatedGroups,
            AutoSync: autoSync,
            Origin: { type: type},
            Import: true
        };

        updateAutoSync({ tenancyId, data: body })
            .unwrap()
            .then(() => {
                setLoading(false);
                setSuccess(true);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    const headCells = [
        {
            id: "select",
            label: "Select",
            render: (dataRow) => {
                const isSelected = selectedGroups.some(group => group.Id === dataRow.Id);
                return (
                    <IconButton
                        size="small"
                        onClick={() => {
                            if (isSelected) {
                                setSelectedGroups(selectedGroups.filter(group => group.Id !== dataRow.Id));
                            } else {
                                setSelectedGroups([...selectedGroups, dataRow]);
                            }
                        }}
                    >
                        {isSelected ? <CheckBoxRoundedIcon color="warning" /> : <CheckBoxOutlineBlankRoundedIcon color={lightMode ? "black" : "white"} />}
                    </IconButton>
                );
            },
            initialVisible:true
        },
        { id: "DisplayName", label: "Name", initialVisible: true },
    ];

    const toolbarActions = [
        {
            render: () => (
                <Box
                    display="flex"
                    alignItems="center"
                    gap={6}
                >
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1">
                            Auto Sync
                        </Typography>
                        <IconButton
                            size="medium"
                            onClick={() => setAutoSync(!autoSync)}
                            sx={{
                                color: autoSync ? theme.palette.warning.main : lightMode ? "text.primary" : "text.secondary",
                            }}
                        >
                            {autoSync ? <CheckBoxRoundedIcon /> : <CheckBoxOutlineBlankRoundedIcon />}
                        </IconButton>
                    </Box>

                    <Button
                        size="medium"
                        variant="contained"
                        sx={{ textTransform: "capitalize" }}
                        aria-haspopup="true"
                        onClick={loading ? null : handleSaveGroups}
                        disabled={selectedGroups.length === 0}
                    >
                        {loading ? "Importing..." : "Import Groups"}
                    </Button>
                </Box>

            )
        }
    ];

    return (
        <>
            {success ? (
                    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" m={4}>
                        <CheckCircleIcon sx={{ color: "primary.main", fontSize: 80, mb: 2 }} />
                        <Typography variant="h5">Importing Groups</Typography>
                        <Button
                            sx={{ mt: 4, width: "50%" }}
                            variant="contained"
                            color="primary"
                            onClick={closeModal}
                        >
                            Close
                        </Button>
                    </Box>
                ) : (
                <Table
                    name={"group"}
                    data={officeData && officeData.Groups || []}
                    error={officeDataError}
                    loading={officeDataLoading}
                    headCells={headCells}
                    search={true}
                    editColumn={false}
                    checkbox={false}
                    showToolbarActions={true}
                    toolbarActions={toolbarActions}
                    onDelete={() => console.log("Delete Groups Not Allowed")}
                    selected={selected}
                    setSelected={setSelected}
                    maxTableHeight={"calc(100vh - 400px)"}
                    initialVisibleColumns={headCells.filter(cell => cell.initialVisible).map(cell => cell.label)}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                />
            )}
        </>

    );
}
