// ==============================|| OVERRIDES - TAB ||============================== //

export default function Tab(theme) {
    return {
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: 46,
                    color: theme.palette.text.primary,
                    transition: 'none',
                    disableRipple: true,

                    '&.Mui-selected': {
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                    },

                    '&:hover': {
                        color: theme.palette.primary.main,
                        fontWeight: 600
                    },
                }
            }
        }
    };
}
