import { getTextColor } from '../../utils/colorUtils';

export default function ToggleButton(theme) {
    return {
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    flex: 1,
                    '&.MuiToggleButton-root': {
                        backgroundColor: `${theme.palette.primary.dark} !important`, 
                        color: `${getTextColor(theme.palette.primary.main)} !important`,
                        '&:hover': {
                            backgroundColor: `${theme.palette.primary.main} !important`, 
                        },
                    },
                    '&.MuiToggleButton-root.Mui-selected': {
                        backgroundColor: `${theme.palette.primary.main} !important`,
                        color: `${getTextColor(theme.palette.primary.main)} !important`,
                        '&:hover': {
                            backgroundColor: `${theme.palette.primary.dark} !important`,
                            color: `${getTextColor(theme.palette.primary.dark)} !important`,
                        },
                    },
                },
            },
        },
    };
}