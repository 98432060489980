export default function DatePicker(theme) {
    return {
        MuiDateCalendar: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.background.default,
                    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
                }),
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.background.paper,
                    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
                }),
                label: ({ theme }) => ({
                    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
                }),
                switchViewButton: ({ theme }) => ({
                    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
                }),
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.background.paper,
                    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
                    '&:hover': {
                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200],
                    },
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                        },
                    },
                }),
            },
        },
        MuiPickersYear: {
            styleOverrides: {
                yearButton: ({ theme }) => ({
                    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
                }),
            },
        },
        MuiPickersMonth: {
            styleOverrides: {
                monthButton: ({ theme }) => ({
                    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
                }),
            },
        },
    };
}