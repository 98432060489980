import { Box, Skeleton } from "@mui/material";
import Stack from "@mui/material/Stack";

const TenancySettingSkeletonComponent = () => {
    return (
        <Box>
            <Skeleton variant="rounded" width={"20%"} height={40} sx={{ mb: 2 }} />

            <Stack spacing={3} direction="row">
                <Skeleton variant="rounded" width="50%" height={200} />
                <Skeleton variant="rounded" width="50%" height={200} />
            </Stack>


            <Stack spacing={3} direction="row" mt={4}>
                <Skeleton variant="rounded" width="50%" height={200} />
                <Skeleton variant="rounded" width="50%" height={200} />
            </Stack>

            <Stack spacing={3} direction="row" mt={4}>
                <Skeleton variant="rounded" width="50%" height={200} />
                <Skeleton variant="rounded" width="50%" height={200} />
            </Stack>
        </Box>
    );
};

export default TenancySettingSkeletonComponent;
