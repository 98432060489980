// ==============================|| OVERRIDES - CHIP ||============================== //

export default function Chip(theme) {
    return {
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: theme.borderRadius['sm'],
                    fontWeight: 600,
                    padding: '6px 12px',
                },
                contained: {
                    '&.MuiChip-containedWarning': {
                        color: theme.palette.black.main
                    },
                    // default
                    '&.MuiChip-containedDefault': {
                        color: theme.palette.common.white,
                        backgroundColor: theme.palette.black.main,
                        '&:hover': {
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.common.black
                        }
                    },
                }
            }
        }
    };
}
