// assets
import { ReactComponent as Home } from '../assets/navigation-icons/course.svg';
import FeedIcon from '@mui/icons-material/Feed';

// icons
const icons = {
    Home,
    FeedIcon
};
// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const userItems = {
    id: 'userItems',
    type: 'group',
    title: 'User',
    divider: false,
    children: [
        {
            id: 'home1',
            title: 'Your Courses',
            type: 'item',
            url: '/',
            icon: icons.Home,
        },
        {
            id: 'feed1',
            title: 'Feed',
            type: 'item',
            url: '/feed',
            icon: icons.FeedIcon,
        },
    ]
};

export default userItems;
