import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import Table from "../../../components/Table/Table";
import { useGetTenancyBasicsQuery } from "../../../store/services/tenancy.service";
import { useLocation, useNavigate } from "react-router-dom";
import {useGetUsersQuery} from "../../../store/services/user-service";

RoleTable.propTypes = {
    type: PropTypes.string.isRequired,
};

export default function RoleTable({ type, setDepartmentId }) {
    const { tenancyId } = useSelector((state) => state.tenancy);
    const [selected, setSelected] = useState([]);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 100,
        page: 0
    });

    const { data: usersData, error: usersError, isLoading: usersLoading } = useGetUsersQuery({
        perPage: paginationModel.pageSize,
        page: paginationModel.page,
        includes: "",
        query: "",
        searchTerm: "",
        tenancyId: tenancyId,
        adminFilter: false,
        extendedResults: true
    });

    let rolesWithUserCount = [
        {
            role: "TENANCYADMINISTRATORS",
            userCount: 0
        },
        {
            role: "USER",
            userCount: 0
        }
    ];

    for (const item of rolesWithUserCount) {
        for (const user of usersData.data) {
            if (item.role.toUpperCase() === user.normalizedRole) {
                item.userCount++;
            }
        }
    }


    let headCells = [
        { id: "role", label: "Role", initialVisible: true },
        { id: "userCount", label: "User Count", initialVisible: true },
    ];


    if (type === "select") {
        headCells.push({
            id: "select",
            label: "Select",
            initialVisible: true,
            render: (dataRow) => (
                <>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => setDepartmentId(dataRow.id)}
                        sx={{ textTransform: "capitalize", ml: 1 }}
                    >
                        Select
                    </Button>
                </>
            )
        });
    }

    return (
        <Table
            name={"Roles"}
            data={usersData ? rolesWithUserCount : []}
            error={usersError}
            loading={usersLoading}
            headCells={headCells}
            search={true}
            editColumn={false}
            checkbox={false}
            showToolbarActions={true}
            toolbarActions={[]}
            onDelete={() => console.log("Delete Roles Not Allowed")}
            selected={selected}
            setSelected={setSelected}
            maxTableHeight={type === "select" ? "calc(100vh - 500px)" : "calc(100vh - 360px)"}
            initialVisibleColumns={headCells.filter(cell => cell.initialVisible).map(cell => cell.label)}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
        />
    );
}
