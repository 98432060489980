export default function Menu(theme) {
    return {
        MuiMenu: {
            styleOverrides: {
                paper: {
                    boxShadow: theme.customShadows.z20,
                    borderRadius: theme.borderRadius.md,
                    backgroundColor: theme.palette.background.default,
                },
            },
        },
    };
}