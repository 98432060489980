import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import UsersTable from "./UsersTable";

const AdditionalData = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);

    const filterBy = searchParams.keys().next().value;
    const filterId = searchParams.get(filterBy);

    const additionalData = location.state?.additionalData;

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} md={12} lg={12}>
                <Grid container justifyContent="space-between">
                    <Button onClick={() => navigate("/users?tab=2")} variant="contained" startIcon={<KeyboardArrowLeftTwoToneIcon />}>
                        Back
                    </Button>
                </Grid>
                <Grid>
                    <Grid item xs={12} mb={4} mt={4}>
                        <Typography variant="h4">Users at {additionalData?.name}</Typography>
                    </Grid>
                    <UsersTable
                        type={"filter"}
                        filterBy={filterBy}
                        filterId={filterId}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AdditionalData;
