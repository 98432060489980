// material-ui
import { Box, IconButton, Tooltip, Typography } from "@mui/material";

// project import
import NavGroup from "./NavGroup";
import { useEffect, useState } from "react";
import menuItems from "menu-items";

import KeyboardDoubleArrowRightTwoToneIcon from "@mui/icons-material/KeyboardDoubleArrowRightTwoTone";
import PropTypes from "prop-types";


// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //
const Navigation = ({ open, handleDrawerToggle }) => {
    const [menu, setMenu] = useState({ items: [] });

    useEffect(() => {
        const fetchMenuItems = () => {
            const items = menuItems();
            setMenu(items);
        };

        fetchMenuItems();
    }, []);

    const navGroups = menu.items.map((item) => {
        switch (item.type) {
            case "group":
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    if (open) {
        return (
            <Box sx={{ pt: 2 }}>
                {navGroups}
            </Box>
        );
    } else {
        return (
            <Box sx={{ pt: 2 }}>
                <Tooltip title="Expand toolbar" placement="right" arrow>
                    <IconButton onClick={handleDrawerToggle} style={{ marginTop: -20, marginLeft: 20 }}>
                        <KeyboardDoubleArrowRightTwoToneIcon color="primary" />
                    </IconButton>
                </Tooltip>

                {navGroups}
            </Box>
        );
    }
};

Navigation.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func
};

export default Navigation;
