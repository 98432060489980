export default function ToggleButtonGroupStyles(theme) {
    return {
        MuiToggleButtonGroup: {
            styleOverrides: {
                grouped: {
                    justifyContent: 'center',
                    borderRadius: theme.borderRadius['sm'],
                    borderWidth: '0px',
                    borderStyle: 'solid',
                    borderColor: theme.palette.divider,
                    margin: theme.spacing(1),
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.black.main,
                    fontWeight: 400,

                    '&.Mui-selected': {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.black.main,
                        fontWeight: 700,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.common.darker,
                            fontWeight: 700,
                        },
                    },
                    '&:hover': {
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.common.white,
                        fontWeight: 700,
                    },

                    '&:active': {
                        boxShadow: 'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    }
                },
            },
        },
    };
}
