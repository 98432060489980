import OfficeAuthHandler from "../pages/extra-pages/OfficeAuthHandler";
import MinimalLayout from "../layout/MinimalLayout";
import OfficeSyncPluginHandler from "../pages/extra-pages/OfficeSyncPluginHandler";
import GoogleSyncPluginHandler from "../pages/extra-pages/GoogleSyncPluginHandler";

const SyncRoutes = {
    element: <MinimalLayout />,
    children: [
        {
            path: 'office365handler',
            element: <OfficeAuthHandler />
        },
        {
            path: 'googlehandler',
            element: <GoogleSyncPluginHandler />
        },
        {
            path: 'officesync',
            element: <OfficeSyncPluginHandler />
        }
    ]
};

export default SyncRoutes;
