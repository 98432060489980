import { Alert, Button, Grid, Snackbar, Stack, Typography } from "@mui/material";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import MainCard from "../../components/cards/MainCard";
import { useNavigate, useParams } from "react-router-dom";
import SimulationForm from "./SimulationForm";
import {
    useCreateSimulationMutation,
    useGetSimulationByIdQuery,
    useUpdateSimulationMutation
} from "../../store/services/simulation-service";
import { useState } from "react";
import SimulationSkeletonComponent from "../../components/Skeleton/SimulationSkeletonComponent";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { getStatusMessage } from "../../utils/getStatusMessage";

export default function SimulationDetails() {
    const navigate = useNavigate();
    const { id } = useParams();
    const isEditMode = Boolean(id);
    const [createSimulation] = useCreateSimulationMutation();
    const [updateSimulation] = useUpdateSimulationMutation();
    const { data: simulationData, isLoading, error: isError } = useGetSimulationByIdQuery({ simId: id, includes: "" }, { skip: !isEditMode });

    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const initialData = isEditMode ? simulationData : {
        simulationName: "",
        emailSubject: "",
        emailDisplayName: "",
        emailFromAddress: "",
        simulationType: "Other",
        simsDomainName: "",
        difficultyRating: 2,
        published: false,
        tenancyId: 0,
        content: "",
        linkedUniversityCourseId: "Phishing 101",
    };

    const handleSaveSimulation = async (simsData) => {
        if (isEditMode) {
            simsData.emailSimulationId = id;

            try {
                const response = await updateSimulation({ simData: simsData });
                if (response.data && response.data.message === "Ok") {
                    setSnackbarMessage("Simulation updated successfully");
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                } else {
                    setSnackbarMessage("Error occurred while updating simulation");
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            } catch (e) {
                setSnackbarMessage("Error occurred while updating simulation");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        } else {
            try {
                simsData.name = simsData.simulationName;
                const response = await createSimulation(simsData);
                if (response.data && response.data.message === "Ok") {
                    setSnackbarMessage("Simulation created successfully");
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                } else {
                    setSnackbarMessage("Error occurred while creating simulation");
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            } catch (e) {
                setSnackbarMessage("Error occurred while creating simulation");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        }
    };

    if (isLoading && isEditMode) return <SimulationSkeletonComponent />;

    if (!isLoading && isError) {
        return (
            <MainCard boxShadow={true} border={false} sx={{ minHeight: "calc(100vh - 200px)", display: "flex", justifyContent: "center", alignItems: "center", bgcolor: 'background.default' }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={8}>
                        <Stack direction="column" spacing={4} alignItems="center">
                            <ErrorOutlineIcon color="error" style={{ fontSize: 80 }} />
                            <Typography variant="h3" align="center" color="textPrimary">
                                {getStatusMessage(isError)}
                            </Typography>
                            <Typography variant="body1" align="center" color="textSecondary">
                                Please refresh the page or contact the administrator.
                            </Typography>
                            <Button onClick={() => navigate(-1)} variant="contained" startIcon={<KeyboardArrowLeftTwoToneIcon />}>
                                Back
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
        );
    }

    return (
        <>
            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                        <Button onClick={() => navigate(-1)} variant="contained" startIcon={<KeyboardArrowLeftTwoToneIcon />}>
                            Back
                        </Button>
                    </Grid>
                    <MainCard sx={{ mt: 2, bgcolor: 'background.default' }} content={true} boxShadow={true} border={false}>
                        <Grid container justifyContent="space-between" sx={{ mb: 4 }}>
                            <Typography variant="h2">
                                {isEditMode ? initialData?.simulationName || 'View Simulation' : "Create Simulation"}
                            </Typography>
                        </Grid>
                        <SimulationForm initialData={initialData} onSave={handleSaveSimulation} mode={isEditMode ? "view" : "add"} />
                    </MainCard>
                </Grid>
            </Grid>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={snackbarOpen} autoHideDuration={3000}
                      onClose={() => setSnackbarOpen(false)}>
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};
