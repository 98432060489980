import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { IconButton, Stack, Tooltip } from "@mui/material";

// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";
import LogoSection from "components/Logo";
import KeyboardDoubleArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardDoubleArrowLeftTwoTone";

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open, handleDrawerToggle }) => {
    const theme = useTheme();

    return (
        <DrawerHeaderStyled theme={theme} open={open}>
            <Stack direction="row" spacing={1} alignItems="center">
                <LogoSection />
                {open &&
                    <Tooltip title="Collapse toolbar" placement="right" arrow>
                        <IconButton onClick={handleDrawerToggle}>
                            <KeyboardDoubleArrowLeftTwoToneIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                }

            </Stack>
        </DrawerHeaderStyled>
    );
};

DrawerHeader.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func
};

export default DrawerHeader;
