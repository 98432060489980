import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { AppBar, Toolbar, useMediaQuery } from "@mui/material";

// project import
import AppBarStyled from "./AppBarStyled";
import HeaderContent from "./HeaderContent";


// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open }) => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));

    // common header
    const mainHeader = (
        <Toolbar>
            <HeaderContent drawerOpen={open} />
        </Toolbar>
    );

    // app-bar params
    const appBar = {
        position: "fixed",
        color: "inherit",
        elevation: 0,
        sx: {
            // give some space above the app bar
            pt: 0,
            pd: 0,
            bgcolor: 'transparent'
        },
    };

    return (
        <>
            {!matchDownMD ? (
                <AppBarStyled open={open} {...appBar}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                <AppBar {...appBar}>
                    {mainHeader}
                </AppBar>
            )}
        </>
    );
};

Header.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func
};

export default Header;
