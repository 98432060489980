import React from 'react';
import { Tooltip, IconButton, FormControlLabel, Switch } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

const IncludeInRandomSwitch = ({ isChecked, onChange }) => {
    return (
        <div>
            <Tooltip title="Include this Simulation in the Random Sims to send through On-Demand">
                <IconButton>
                    <HelpIcon sx={{ color: "secondary.main" }} />
                </IconButton>
            </Tooltip>
            <FormControlLabel
                control={<Switch checked={isChecked} onChange={onChange} />}
                label="Include in Random"
            />
        </div>
    );
};

export default IncludeInRandomSwitch;