import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UNIVERSITY_API_URL } from "config";

const baseQuery = fetchBaseQuery({
    baseUrl: UNIVERSITY_API_URL,
    prepareHeaders: (headers) => {
        const user = localStorage.getItem("user");

        if (user) {
            let userToken = JSON.parse(user).token;
            headers.set("authorization", `Bearer ${userToken}`);
        }
        return headers;
    },
    credentials: "omit"
});

export const universityApi = createApi({
    reducerPath: "universityApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getCourseById: builder.query({
            query: ({ courseId, includes }) => `universitycourse/${courseId}/?includes=${includes}`,
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
            providesTags: (result, error, id) => [{ type: "CourseByIdData", id }]
        }),
        getCourses: builder.query({
            query: ({ perPage, page, includes, query, additionalQueryParams }) =>
                `universitycourse/?perPage=${perPage}&page=${page}&includes=${includes}&query=${query}${additionalQueryParams}`,
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status.query(),
            providesTags: (result) => {
                return result ?
                    [...result.map(({ id }) => ({ type: "Courses", id })), { type: "Courses", id: "LIST" }]
                    :
                    [{ type: "Courses", id: "LIST" }];
            }
        }),
        getValidCourses: builder.query({
            query: ({ perPage, page, includes, query, additionalQueryParams }) =>
                `universitycourse/?perPage=${perPage}&page=${page}&includes=${includes}&query=${query}${additionalQueryParams}`,
            transformResponse: (response) => response.data.filter(x => !!x?.courseContentPath),
            transformErrorResponse: (response) => response.status.query(),
            providesTags: (result) => {
                return result ?
                    [...result.map(({ id }) => ({ type: "Courses", id })), { type: "Courses", id: "LIST" }]
                    :
                    [{ type: "Courses", id: "LIST" }];
            }
        }),
        createCourse: builder.mutation({
            query: (courseData) => ({
                url: "universitycourse/",
                method: "POST",
                body: courseData
            }),
            invalidatesTags: [{ type: "Courses", id: "LIST" }]
        }),
        getUniversityCourseSettings: builder.query({
            query: ({ tenancyId }) => `universitycourse/settings/${tenancyId}`,
            providesTags: (result, error, id) => [{ type: "UniversityCourseSettings", id }]
        }),
        saveUniversityCourseSettings: builder.mutation({
            query: ({ tenancyId, settingsData }) => ({
                url: `universitycourse/settings/${tenancyId}`,
                method: "PUT",
                body: settingsData
            }),
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status.query(),
            invalidatesTags: (result, error, id) => [{ type: "UniversityCourseSettings", id }]
        }),
        updateCourse: builder.mutation({
            query: ({ courseData }) => ({
                url: `universitycourse`,
                method: "PUT",
                body: courseData
            }),
            invalidatesTags: (result, error, id) => [{ type: "Courses", id: "LIST" }, { type: "CourseByIdData", id: id }]
        }),
        uploadCourseCsv: builder.mutation({
            queryFn: async ({ file }, api, extraOptions, baseQuery) => {
                try {
                    const fileType = file.type;

                    if (!['text/csv', 'application/vnd.ms-excel'].includes(fileType)) {
                        return { error: { status: 400, data: `Unknown File Type: ${fileType}` } };
                    }

                    const preSignedUrlResponse = await baseQuery(`universitycourse/getpresignedurl?type=${fileType}`, api, extraOptions );

                    if (preSignedUrlResponse.error) {
                        return { error: preSignedUrlResponse.error };
                    }

                    const { presignedUrl } = preSignedUrlResponse.data;

                    const uploadResponse = await fetch(presignedUrl, {
                        method: 'PUT',
                        body: file,
                        headers: {
                            'x-amz-acl': 'bucket-owner-full-control'
                        }
                    });

                    if (!uploadResponse.ok) {
                        return { error: { status: uploadResponse.status, data: `Request failed, reason: ${uploadResponse.statusText}`} };
                    }

                    return { data: { success: true, message: 'Upload successful' } };
                } catch (error) {
                    return { error: { status: 500, data: `Request failed, reason: ${error.message}`} };
                }
            },
        transformResponse: (response) => response,
        transformErrorResponse: (response) => response.status,
        invalidatesTags: [{ type: "Courses", id: "LIST" }]
    }),
        uploadToS3: builder.mutation({
            queryFn: async ({ fileType, id, file }, api, extraOptions, baseQuery) => {
                // First, get the pre-signed URL
                const preSignedUrlResponse = await baseQuery(`universitycourse/${id}/zippresignedurl?type=${fileType}`, api, extraOptions);

                if (preSignedUrlResponse.error) {
                    return { error: preSignedUrlResponse.error };
                }

                const { presignedUrl } = preSignedUrlResponse.data;

                const uploadResponse = await fetch(presignedUrl, {
                    method: "PUT",
                    body: file,
                    headers: {
                        "x-amz-acl": "bucket-owner-full-control",
                        "Content-Type": fileType
                    }
                });

                if (!uploadResponse.ok) {
                    return { error: { status: uploadResponse.status, statusText: uploadResponse.statusText } };
                }

                return { data: { success: true } };
            },
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status.query(),
            invalidatesTags: [{ type: "Courses", id: "LIST" }]
        }),
    })
});

export const {
    useGetCourseByIdQuery,
    useLazyGetCourseByIdQuery,
    useGetCoursesQuery,
    useGetValidCoursesQuery,
    useCreateCourseMutation,
    useGetUniversityCourseSettingsQuery,
    useSaveUniversityCourseSettingsMutation,
    useUpdateCourseMutation,
    useUploadCourseCsvMutation,
    useUploadToS3Mutation
} = universityApi;
