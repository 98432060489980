import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

// material-ui
import { ButtonBase } from '@mui/material';

// project import
import {Logo200, Logo100} from './Logo';
import { useSelector } from "react-redux";
import { useGetTenancyBasicsQuery } from "../../store/services/tenancy.service";
import { APP_URL } from "../../config";
import { useTheme } from "@mui/material/styles";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const lightMode = theme.palette.mode === "light";

    const { tenancyId } = useSelector((state) => state.tenancy);
    const { isLoggedIn } = useSelector((state) => state.auth);

    const { data: tenancyData, error: tenancyError, isLoading: tenancyLoading } = useGetTenancyBasicsQuery({
        tenancyId: tenancyId
    });

    const handleClick = () => {
        if (!isLoggedIn) {
            navigate('/login');
            return;
        }

        navigate('/');
    };

    return(
        <ButtonBase disableRipple onClick={handleClick}>
            {!tenancyLoading && !tenancyError && tenancyData && tenancyData.logo ?
                <img src={`${APP_URL}/${tenancyData.logo}`} alt="Logo" width="180" height="100" style={{objectFit: 'contain', marginTop: 8}} /> :
                (lightMode ? <Logo200 /> : <Logo100 />)
            }
        </ButtonBase>
    );
};

export const LogoSectionBlue = () => {
    const navigate = useNavigate();
    const { isLoggedIn } = useSelector((state) => state.auth);

    const handleClick = () => {
        if (!isLoggedIn) {
            navigate('/login');
            return;
        }

        navigate('/');
    };

    return (
        <ButtonBase disableRipple onClick={handleClick}>
            <Logo100 />
        </ButtonBase>
    );
};

LogoSection.propTypes = {
    sx: PropTypes.object,
    to: PropTypes.string
};

export default LogoSection;
