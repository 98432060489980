import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { SEND_FROM_ADDRESSES, SIMS_ADDRESSES, SIMULATION_CATEGORIES } from "../../config";
import { useGetTenanciesQuery } from "../../store/services/tenancy.service";
import { useLazyGetTinyJwtTokenQuery } from "../../store/services/simulation-service";
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    Switch,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import MainCard from "../../components/cards/MainCard";
import InfoIcon from "@mui/icons-material/Info";
import { useGetCoursesQuery } from "../../store/services/university-service";
import { useTheme } from "@mui/material/styles";
import IncludeInRandomSwitch from "../../utils/randomSendSwitch";


SimulationForm.propTypes = {
    initialData: PropTypes.object,
    onSave: PropTypes.func,
    mode: PropTypes.string
};


export default function SimulationForm(props) {
    const { initialData, onSave, mode } = props;

    const theme = useTheme();
    const user = JSON.parse(localStorage.getItem("user"));
    const isAdmin = user.role === "Administrators";
    const lightMode = theme.palette.mode === "light";

    const [simulationData, setSimulationData] = useState(initialData);
    const [coursesOptions, setCoursesOptions] = useState([
        { id: "Phishing 101", name: "Phishing 101" }
    ]);
    const [loading, setLoading] = useState(false);
    const [isRandomSend, setIsRandomSend] = useState(initialData.randomSend || false);


    const [triggerJwtToken, { data: jwtToken, isLoading: jwtTokenLoading, error: jwtTokenError }] = useLazyGetTinyJwtTokenQuery();

    const sendFromAddressOptions = SEND_FROM_ADDRESSES.split(",");
    const simulationTypeOptions = SIMULATION_CATEGORIES.split(",");
    const simulationDomainNameOptions = SIMS_ADDRESSES.split(",");
    const simulationDifficultyRatingOptions = ["0", "1", "2", "3"];

    const [content, setContent] = useState(simulationData.content);

    const { data: tenanciesData, isLoading: tenanciesDataLoading } = useGetTenanciesQuery({
        perPage: 1000,
        page: 0,
        includes: "",
        query: "",
        additionalQueryParams: ""
    });

    const { data: coursesData, isLoading: coursesLoading } = useGetCoursesQuery({
        perPage: 100,
        page: 0,
        includes: "",
        query: "",
        additionalQueryParams: ""
    });


    useEffect(() => {
        if (coursesData) {
            setCoursesOptions(prevOptions => [...prevOptions, ...coursesData]);
        }
    }, [coursesData]);


    const handleRandomSendChange = (event) => {
        setIsRandomSend(event.target.checked);
        setSimulationData({ ...simulationData, randomSend: event.target.checked });
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            await onSave({ ...simulationData, randomSend: isRandomSend });
        } catch (error) {
            console.error("Error saving simulation:", error);
        } finally {
            setLoading(false);
        }
    };

    const placeholders = [
        { action: "{user.action}", message: "Button that the user clicks on" },
        { action: "{user.email}", message: "Recipient's email address" },
        { action: "{user.firstname}", message: "Recipient's first name" },
        { action: "{user.lastname}", message: "Recipient's last name" },
        { action: "{tenancy.name}", message: "Tenancy Name" }
    ];

    const editorStyle = `
        body { 
            background-color: ${lightMode ? 'white' : theme.palette.black.main}; 
            color: ${lightMode ? 'black' : theme.palette.grey[50]}; 
        }
    `;

    const tinydriveTokenProvider = useCallback((callback) => {
        const getToken = async () => {
            try {
                const response = await triggerJwtToken().unwrap();
                if (response) {
                    callback(response);
                } else {
                    console.warn("JWT token not available in the response");
                    callback(null);
                }
            } catch (error) {
                console.error("Error fetching JWT token:", error);
                callback(null);
            }
        };

        getToken();
    }, [triggerJwtToken]);

    useEffect(() => {
        const style = document.createElement('style');
        style.textContent = `
        .tox-tinymce {
            border-color: ${lightMode ? theme.palette.grey[200] : theme.palette.black.light} !important;
        }
        .tox .tox-statusbar {
            background-color: ${lightMode ? 'inherit' : theme.palette.black.light} !important;
            color: ${lightMode ? 'inherit' : theme.palette.grey[300]} !important;
        }
        // ... (other styles)
    `;
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, [theme, lightMode]);

    return (
        <>
            <Grid container spacing={2} mb={4}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Simulation Name"
                        required={true}
                        value={simulationData.simulationName}
                        size="medium"
                        fullWidth
                        onChange={(e) => {
                            setSimulationData({ ...simulationData, simulationName: e.target.value });
                        }}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Email Subject"
                        value={simulationData.emailSubject}
                        size="medium"
                        fullWidth
                        onChange={(e) => {
                            setSimulationData({ ...simulationData, emailSubject: e.target.value });
                        }}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Email Display Name"
                        value={simulationData.emailDisplayName}
                        size="medium"
                        fullWidth
                        onChange={(e) => {
                            setSimulationData({ ...simulationData, emailDisplayName: e.target.value });
                        }}
                        sx={{ mb: 2 }}
                    />
                    <Autocomplete
                        id="email-from-address"
                        size="medium"
                        fullWidth
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        value={simulationData.emailFromAddress}
                        options={sendFromAddressOptions}
                        sx={{ mb: 2 }}
                        onChange={(e, value) => {
                            setSimulationData({ ...simulationData, emailFromAddress: value });
                        }}
                        renderInput={(params) => <TextField {...params} label="Email From Address" />}
                    />
                    <Autocomplete
                        id="simulation-type"
                        size="medium"
                        fullWidth
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        value={simulationData.simulationType}
                        disabled={!isAdmin}
                        options={simulationTypeOptions}
                        sx={{ mb: 2 }}
                        onChange={(e, value) => {
                            setSimulationData({ ...simulationData, simulationType: value });
                        }}
                        renderInput={(params) => <TextField {...params} label="Simulation Type" />}
                    />
                    <Autocomplete
                        id="simulation-domain-name"
                        size="medium"
                        fullWidth
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        value={simulationData.simsDomainName}
                        options={simulationDomainNameOptions}
                        sx={{ mb: 2 }}
                        onChange={(e, value) => {
                            setSimulationData({ ...simulationData, simsDomainName: value });
                        }}
                        renderInput={(params) => <TextField {...params} label="Simulation Domain Name" />}
                    />
                    <Autocomplete
                        id="simulation-difficulty-rating"
                        size="medium"
                        disableClearable
                        fullWidth
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        value={simulationData.difficultyRating}
                        options={simulationDifficultyRatingOptions}
                        sx={{ mb: 3 }}
                        onChange={(e, value) => {
                            setSimulationData({ ...simulationData, difficultyRating: value });
                        }}
                        renderInput={(params) => <TextField {...params} label="Simulation Difficulty Rating" />}
                    />
                    <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                        {isAdmin &&
                            <IncludeInRandomSwitch
                                key={isRandomSend}
                                isChecked={isRandomSend}
                                onChange={handleRandomSendChange}
                            /> }
                        <Button onClick={handleSave} variant="contained" color="warning" sx={{ width: "50%", mt: 2 }}>
                            {loading ? <CircularProgress
                                size={24}
                                sx={{
                                    color: (theme) => theme.palette.getContrastText(theme.palette.warning.main)
                                }}
                            /> : mode === "view" ? "Update Simulation" : "Create Simulation"}
                        </Button>
                    </div>

                </Grid>

                {isAdmin && <Grid item xs={12} sm={6}>
                    {tenanciesDataLoading ?
                        <MainCard content={true} boxShadow={false} border={true}
                                  sx={{ bgcolor: "background.default", alignContent: "center", mb: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                                <CircularProgress size={32} sx={{ mr: 2 }} />
                                <Typography variant="h6">
                                    Loading Send To Tenancies Setting
                                </Typography>
                            </Box>
                        </MainCard>
                        :
                        <MainCard content={true} boxShadow={false} border={false} sx={{ bgcolor: (simulationData.published ? (lightMode ? 'primary.light' : 'grey.600'): (lightMode ? 'grey.200' : 'grey.600')), mb: 2 }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                <Typography variant="h4">
                                    Publish To All Tenancies
                                </Typography>

                                <Switch
                                    checked={simulationData.published}
                                    onChange={(e) => setSimulationData({ ...simulationData, published: e.target.checked })}
                                    inputProps={{ "aria-label": "controlled" }}
                                    disabled={mode === "add-on-demand"}
                                />
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%", mt: 2 }}>
                                {!simulationData.published && tenanciesData && (
                                    <Autocomplete
                                        id="tenancy-id"
                                        size="small"
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        options={tenanciesData ? tenanciesData : []}
                                        isOptionEqualToValue={(option, value) => option.tenancyId === value.tenancyId}
                                        value={tenanciesData && tenanciesData.find(row => row.tenancyId === simulationData.tenancyId) || null}
                                        disabled={mode === "add-on-demand"}
                                        getOptionLabel={(option) => option.name}
                                        sx={{ mt: 2, width: "100%" }}
                                        onChange={(e, value) => setSimulationData({
                                            ...simulationData,
                                            tenancyId: value ? value.tenancyId : 0
                                        })}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Tenancy To Publish To"
                                                InputLabelProps={{
                                                    style: { backgroundColor: "transparent" }
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            </Box>
                        </MainCard>
                    }

                    {coursesLoading ?
                        <MainCard content={true} boxShadow={false} border={true} sx={{ bgcolor: "background.default", alignContent: "center", mb: 2 }}>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                                <CircularProgress size={32} sx={{ mr: 2 }} />
                                <Typography variant="h6">
                                    Loading Course Linking Setting
                                </Typography>
                            </Box>
                        </MainCard>
                        :
                        <MainCard content={true} boxShadow={false} border={false} sx={{ bgcolor: ((simulationData.linkedUniversityCourseId !== null && simulationData.linkedUniversityCourseId !== "") ? (lightMode ? 'primary.light' : 'grey.600'): (lightMode ? 'grey.200' : 'grey.600')) }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                <Typography variant="h4">
                                    Linked University Course
                                </Typography>

                                <Switch
                                    checked={simulationData.linkedUniversityCourseId !== null && simulationData.linkedUniversityCourseId !== ""}
                                    onChange={(e) => setSimulationData({
                                        ...simulationData,
                                        linkedUniversityCourseId: e.target.checked ? "Phishing 101" : null
                                    })}
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                            </Box>
                            {simulationData.linkedUniversityCourseId !== null && simulationData.linkedUniversityCourseId !== "" &&
                                <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%", mt: 2 }}>
                                    <Autocomplete
                                        id="tenancy-id"
                                        size="small"
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        options={coursesOptions ? coursesOptions : []}
                                        isOptionEqualToValue={(option, value) => option.id === value.linkedUniversityCourseId}
                                        value={coursesOptions && coursesOptions.find(row => row.id === simulationData.linkedUniversityCourseId) || null}
                                        getOptionLabel={(option) => option.id}
                                        sx={{ mt: 2, width: "100%" }}
                                        onChange={(e, value) => setSimulationData({
                                            ...simulationData,
                                            linkedUniversityCourseId: value ? value.id : ""
                                        })}
                                        renderInput={(params) => (<TextField {...params} label="" />)}
                                    />
                                </Box>
                            }
                        </MainCard>
                    }

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", mt: 4 }}>
                        <Tooltip
                            sx={{ backgroundColor: "red", m: 0 }}
                            title={
                                <Card>
                                    <CardContent>
                                        {placeholders.map((placeholder, index) => (
                                            <Typography key={index} variant="body2">
                                                <strong>{placeholder.action}</strong>: {placeholder.message}
                                            </Typography>
                                        ))}
                                    </CardContent>
                                </Card>
                            }
                            arrow
                        >
                            <Button variant="text" color="black" sx={{ width: "50%" }} endIcon={<InfoIcon />}>
                                Placeholders
                            </Button>
                        </Tooltip>
                    </Box>
                </Grid>}
            </Grid>
            <Editor
                apiKey="j2g2hy77ifpdh2oe7hfarnk6vvls82qlv49guynyzw6d22ha"
                value={simulationData.content}
                onEditorChange={(newContent) => setSimulationData({ ...simulationData, content: newContent })}
                init={{
                    
                    //skin: lightMode ? 'oxide' : 'oxide-dark',
                    //content_css: lightMode ? 'default' : 'dark',
                    skin: lightMode ? 'oxide' : 'oxide-dark',
                    content_css: lightMode ? 'default' : 'dark',
                    content_style: editorStyle,
                    plugins: [
                        "advlist", "autolink", "lists", "link", "image", "charmap", "preview", "anchor",
                        "searchreplace", "visualblocks", "code", "fullscreen",
                        "insertdatetime", "media", "table", "code", "help", "wordcount", "template", "tinydrive"
                    ],
                    images_file_types: "jpg,png,jpeg,webp",
                    tinydrive_token_provider: tinydriveTokenProvider,
                    toolbar:
                        "template image | code | undo redo | fontselect fontsize fontsizeselect bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
                    templates: [
                        {
                            title: "User name",
                            description: "Insert the users full name",
                            content: "{user.name}"
                        }
                    ],
                    height: "1000",
                    promotion: false,
                }}
            ></Editor>
        </>
    );
}
