import PropTypes from "prop-types";
import { useGetCourseByIdQuery } from "../../store/services/university-service";
import { APP_URL } from "../../config";
import { Box, Typography } from "@mui/material";

CoursePreview.propTypes = {
    courseId: PropTypes.string.isRequired,
}

export default function CoursePreview(props) {
    const { courseId } = props;

    const {data: courseData, isLoading: courseLoading, error: courseError} = useGetCourseByIdQuery({
        courseId: courseId,
        includes: '',
    })

    const course_gif = courseData ? `${APP_URL}/${courseData.courseContentPath.replace('index.html', 'card.gif')}` : '';
    const course_img = courseData ? `${APP_URL}/${courseData.courseContentPath.replace('index.html', 'card.png')}` : '';
    const courseURL = courseData?.courseContentPath
        ? `${APP_URL}/${courseData.courseContentPath}`
        : '';

    if (courseLoading) {
        return <div>Loading...</div>;
    }

    if (courseError) {
        return <div>ERROR</div>;
    }

    if (courseData) {
        return (
            <div>
                <Box sx={{p:2}}>
                    <Typography variant="h4">Course Name: {courseData.courseName}</Typography>
                    <Typography variant="h4">Scored Out of: {courseData.scoredOutOf}</Typography>
                    <Typography variant="h4">Language: {courseData.languageCode}</Typography>
                    <Typography variant="h4">Format: {courseData.format}</Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "center", p:2 }}>
                    <iframe src={courseURL} title="Course Data" width="80%" height="800px"></iframe>
                </Box>

            </div>
        )

    }
    return null;
};