export default function Modal(theme) {
    return {
        MuiModal: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '& > .MuiBox-root': {
                        ...(theme.palette.mode === 'dark' && {
                            border: '1px solid white', // Add white border in dark mode
                        }),
                    },
                }),
            },
        },
    };
}