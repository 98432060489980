import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

CustomStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.number,
    onClick: PropTypes.func
};

function CustomStepIcon(props) {
    const theme = useTheme();
    const { active, completed, icon, onClick } = props;

    return (
        <Box
            onClick={onClick}
            sx={{
                backgroundColor: "white",
                zIndex: 1,
                borderRadius: '6px',
                width: 28,
                height: 28,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                border: `2px solid ${theme.palette.secondary.light}`,

                ...(active && {
                    backgroundColor: 'white',
                    border: `3px solid ${theme.palette.secondary.main}`,
                    color: theme.palette.secondary.main,
                }),

                ...(completed && {
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    border: `2px solid ${theme.palette.primary.main}`,
                    "&:hover": {
                        backgroundColor: theme.palette.primary.dark,
                        color: "white"
                    }
                })
            }}
        >
            {completed ? <DoneRoundedIcon /> : null}
            {active ? <SquareRoundedIcon
                sx={{
                    borderRadius: '30px', // Make it appear more circular
                    fontSize: '1.5rem',  // Adjust size as needed
                }}
            /> : null}
        </Box>
    );
}

export default CustomStepIcon;
