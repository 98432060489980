import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
    useDeleteAllUsersMutation,
    useDeleteJourneysAndSchedulesMutation,
    useDeleteTenancyMutation, useUpdateTenancyLogoMutation,
    useUpdateTenancyMutation
}
    from "../../store/services/tenancy.service";
import {
    Alert,
    Box,
    Button,
    Grid, IconButton,
    Snackbar,
    TextField,
    Tooltip,
    Typography,
    CircularProgress,
    Divider
} from "@mui/material";
import MainCard from "../../components/cards/MainCard";
import { useGetUniversityCourseSettingsQuery, useSaveUniversityCourseSettingsMutation } from "../../store/services/university-service";
import { useNavigate } from "react-router-dom";
import HelpIcon from "@mui/icons-material/HelpOutline";
import { APP_URL } from "../../config";
import { v4 as uuidv4 } from "uuid";
import { useTheme } from "@mui/material/styles";
import { saveThemeColors, getThemeColors } from "../../themes/themeColourManager";
import { getCardColor, isValidHexCode } from "../../utils/colorUtils";
import { useThemeContext } from '../../themes/ThemeContext';
import ThemeSquare from '../../components/ThemeSquare';
import { PRESET_THEMES } from '../../themes/PresetThemes';
import ColorPicker from '../../components/ColorPicker';

AdminSettings.propTypes = {
    data: PropTypes.object
};

export default function AdminSettings(props) {
    const { data } = props;
    const navigate = useNavigate();
    const theme = useTheme();
    const { customColors, updateThemeColors } = useThemeContext();
    const [ selectedTheme, setSelectedTheme ] = useState(null);

    const lightMode = theme.palette.mode === 'light';

    const [tenancyData, setTenancyData] = useState(data);

    const [primaryColor, setPrimaryColor] = useState(theme.palette.primary.main);
    const [secondaryColor, setSecondaryColor] = useState(theme.palette.secondary.main);
    const [tertiaryColor, setTertiaryColor] = useState(theme.palette.warning.main);

    const [isSaving, setIsSaving] = useState(false);
    const [isUploadingLogo, setIsUploadingLogo] = useState(false);

    const {
        data: universitySettings,
        error: universitySettingsError,
        isLoading: universitySettingsLoading
    } = useGetUniversityCourseSettingsQuery({ tenancyId: tenancyData.tenancyId });

    const [updateTenancy] = useUpdateTenancyMutation();
    const [updateUniversityCourseSettings] = useSaveUniversityCourseSettingsMutation();
    const [deleteAllUsers] = useDeleteAllUsersMutation();
    const [deleteTenancy] = useDeleteTenancyMutation();
    const [deleteJourneysSchedules] = useDeleteJourneysAndSchedulesMutation();
    const [updateTenancyLogo] = useUpdateTenancyLogoMutation();

    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const [enableOnDemandBox, setEnableOnDemandBox] = useState(tenancyData.enableOndemand);
    const [unauthSendingBox, setUnauthSendingBox] = useState(universitySettings ? universitySettings?.allowUnauthorisedTakeCourse : false);
    const [anonymiseReportingBox, setAnonymiseReportingBox] = useState(tenancyData.anonymiseReports);

    const handleSave = useCallback(async (savedData) => {
        setIsSaving(true);
        try {
            if (!isValidHexCode(primaryColor) || !isValidHexCode(secondaryColor) || !isValidHexCode(tertiaryColor)) {
                throw new Error("Invalid hex code detected");
            }

            // Use provided saved data or current tenancy data
            const dataToUpdate = savedData || tenancyData;

            const updatedTenancyData = {
                ...dataToUpdate,
                primaryHex: primaryColor,
                secondaryHex: secondaryColor,
                tertiaryHex: tertiaryColor
            };

            await updateTenancy({
                data: updatedTenancyData,
                tenancyId: tenancyData.tenancyId
            }).unwrap();

            // Update theme colors
            updateThemeColors({
                primary: primaryColor,
                secondary: secondaryColor,
                warning: tertiaryColor
            });

            setTenancyData(updatedTenancyData);
            setSnackbarMessage("Settings saved successfully");
            setSnackbarSeverity("success");
        } catch (error) {
            console.error("Error saving settings:", error);
            setSnackbarMessage(`Error saving settings: ${error.message}`);
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
            setIsSaving(false);
        }
    }, [tenancyData, primaryColor, secondaryColor, tertiaryColor, updateTenancy, updateThemeColors]);

    const handlePresetThemeSelect = (theme) => {
        setSelectedTheme(theme);
        const newColors = {
            primary: theme.primary,
            secondary: theme.secondary,
            warning: theme.tertiary
        };
        setPrimaryColor(theme.primary);
        setSecondaryColor(theme.secondary);
        setTertiaryColor(theme.tertiary);
        updateThemeColors(newColors);
    }

    const handleColorChange = (colorKey, newValue) => {
        const newColors = { ...customColors, [colorKey]: newValue };
        updateThemeColors(newColors);
    };


    const handleSaveUniversityCourseSettings = () => {
        updateUniversityCourseSettings({
            tenancyId: tenancyData.tenancyId,
            settingsData: { tenancyId: tenancyData.tenancyId, allowUnauthorisedTakeCourse: unauthSendingBox }
        })
        .then(() => {
            setSnackbarMessage("University course settings updated successfully.");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        })
        .catch((error) => {
            console.log("Error updating university course settings:", error);
            setSnackbarMessage("Error updating university course settings.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        });
    };

    /*

    const handleDeleteTenancy = () => {
        if (!window.confirm("Are you sure you want to delete this tenancy?")) {
            return;
        }

        deleteTenancy({ tenancyId: tenancyData.tenancyId }).unwrap().then((response) => {
            setSnackbarMessage("Tenancy deleted successfully.");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            console.log(response);
            navigate(-1);
        }).catch((error) => {
            setSnackbarMessage("Error deleting tenancy.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            console.log("Error deleting tenancy:", error);
        });
    };

    const handleDeleteUsers = () => {
        if (!window.confirm("Are you sure you want to delete all users?")) {
            return;
        }

        deleteAllUsers({ tenancyId: tenancyData.tenancyId }).unwrap().then((response) => {
            setSnackbarMessage("Request sent");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            console.log(response);
        }).catch((error) => {
            setSnackbarMessage("Error sending request");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            console.log("Error:", error);
        });
    };

    const handleDeleteJourneysSchedules = () => {
        if (!window.confirm("Are you sure you want to delete all active journeys and schedules for this tenancy?")) {
            return;
        }

        deleteJourneysSchedules({ tenancyId: tenancyData.tenancyId }).unwrap().then((response) => {
            setSnackbarMessage("Request sent");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            console.log(response);
        }).catch((error) => {
            setSnackbarMessage("Error sending request");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            console.log("Error:", error);
        });
    };
    */

    const handleSaveLogo = async (file) => {
        setIsUploadingLogo(true);
        try {
            // Check if file is defined
            if (!file) {
                throw new Error("No file selected");
            }
            // Check if the file is a PNG
            if (file.type !== 'image/png') {
                throw new Error("Please upload a PNG file");
            }
            // Check the image dimensions
            const img = await new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = () => reject(new Error("Error loading image file"));
                img.src = URL.createObjectURL(file);
            });
            if (img.width > 300 || img.height > 300) {
                throw new Error("Image dimensions should not exceed 300x300 pixels");
            }
            // If all checks pass, proceed with the upload
            const id = uuidv4();
            const fileName = `assets/logos/${id}.png`;
            const data = {
                file: file,
                tenancyId: tenancyData.tenancyId,
                fileName: fileName
            };

            // Upload the logo first
            await updateTenancyLogo({ data: data, tenancyId: tenancyData.tenancyId }).unwrap();

            // Update local state only - don't call handleSave
            setTenancyData({ ...tenancyData, logo: fileName });

            setSnackbarMessage("Company logo uploaded successfully");
            setSnackbarSeverity("success");
        } catch (error) {
            console.error("Error uploading company logo:", error);
            setSnackbarMessage(error.message || "Error uploading company logo");
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
            setIsUploadingLogo(false);
        }
    };

    if (tenancyData) {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} sx={{ mb: 4 }}>
                            <Grid container spacing={2} sx={{ mt: 2 }}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <MainCard
                                        content={true}
                                        boxShadow={true}
                                        border={false}
                                        sx={{
                                            height: '100%',
                                            bgcolor: getCardColor(lightMode),
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        {/* Header */}
                                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mb: 1 }}>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Tooltip title="Upload your company logo here. The logo will be displayed in the top left in the sidebar for all users.">
                                                    <IconButton>
                                                        <HelpIcon sx={{ color: "secondary.main" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography variant="h4">
                                                    Upload Logo
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Divider sx={{ width: "100%", mb: 2 }} />

                                        {/* Main content area */}
                                        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            {tenancyData.logo && (
                                                <Box sx={{ display: "flex", justifyContent: "center", mb: 6, mt: 6 }}>
                                                    <img src={`${APP_URL}/${tenancyData.logo}`} alt="Logo" width={120} height="auto"
                                                        className="logo-image" style={{ objectFit: "contain" }} />
                                                </Box>
                                            )}
                                        </Box>

                                        {/* Buttons at the bottom */}
                                        <Box sx={{ mt: 'auto', display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                component="label"
                                                fullWidth
                                            >
                                                {isUploadingLogo ? <CircularProgress /> : tenancyData.logo ? "Change Logo" : "Upload Logo"}
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    hidden
                                                    onChange={(e) => handleSaveLogo(e.target.files[0])}
                                                />
                                            </Button>
                                            {tenancyData.logo && (
                                                <Button
                                                    variant="outlined"
                                                    color="black"
                                                    fullWidth
                                                    onClick={() => {
                                                        setTenancyData({ ...tenancyData, logo: "" });
                                                        handleSave({ ...tenancyData, logo: "" });
                                                    }}
                                                >
                                                    Remove Logo
                                                </Button>
                                            )}
                                            <Alert severity="warning" sx={{ mt: 2 }}>
                                                Logos must be a .png file with maximum dimensions of 300x300
                                            </Alert>
                                        </Box>
                                    </MainCard>
                                </Grid>

                                <Grid item xs={12} md={6} lg={4}>
                                    <MainCard
                                        content={true}
                                        boxShadow={true}
                                        border={false}
                                        sx={{
                                            height: '100%',
                                            bgcolor: getCardColor(lightMode),
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        {/* Custom Theme Selector Card Content */}
                                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mb: 1 }}>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Tooltip title="Choose custom colors that will be used as the theme for all users.">
                                                    <IconButton>
                                                        <HelpIcon sx={{ color: "secondary.main" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography variant="h4">
                                                    Custom Theme Selector
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Divider sx={{ width: "100%", mb: 2 }} />
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Grid container justifyContent="flex-left" alignItems="flex-start">
                                                <Grid item xs={12} sm={6} >
                                                    <ColorPicker label="Primary" color={primaryColor} onChange={setPrimaryColor}/>
                                                </Grid>
                                                <Grid item xs={12} sm={6} >
                                                    <ColorPicker label="Secondary" color={secondaryColor} onChange={setSecondaryColor} />
                                                </Grid>
                                                <Grid item xs={12} sm={6} >
                                                    <ColorPicker label="Tertiary" color={tertiaryColor} onChange={setTertiaryColor} />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        {/*
                                            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                                <Box sx={{ mb: 2 }}>
                                                    <Typography variant="h5" sx={{ mb: 1 }}>Primary</Typography>
                                                    <MuiColorInput format="hex" value={primaryColor} onChange={(newValue) => setPrimaryColor(newValue)} />
                                                </Box>
                                                <Box sx={{ mb: 2 }}>
                                                    <Typography variant="h5" sx={{ mb: 1 }}>Secondary</Typography>
                                                    <MuiColorInput format="hex" value={secondaryColor} onChange={(newValue) => setSecondaryColor(newValue)} />
                                                </Box>
                                                <Box>
                                                    <Typography variant="h5" sx={{ mb: 1 }}>Tertiary</Typography>
                                                    <MuiColorInput format="hex" value={tertiaryColor} onChange={(newValue) => setTertiaryColor(newValue)} />
                                                </Box>
                                            </Box>*/}
                                    </MainCard>
                                </Grid>

                                <Grid item xs={12} md={6} lg={4}>
                                    <MainCard
                                        content={true}
                                        boxShadow={true}
                                        border={false}
                                        sx={{
                                            height: '100%',
                                            bgcolor: getCardColor(lightMode),
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        {/* Preset Theme Selector Card Content */}
                                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mb: 1 }}>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Tooltip title="Choose preset colors that will be used as the theme for all users.">
                                                    <IconButton>
                                                        <HelpIcon sx={{ color: "secondary.main" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography variant="h4">
                                                    Preset Theme Selector
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Divider sx={{ width: "100%", mb: 2 }} />

                                        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                            <Typography variant="h5" sx={{ mb: 2 }}>Preset Themes</Typography>
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>
                                                {PRESET_THEMES.map((theme) => (
                                                    <ThemeSquare
                                                        key={theme.name}
                                                        theme={theme}
                                                        onClick={() => handlePresetThemeSelect(theme)}
                                                        isSelected={selectedTheme?.name === theme.name}
                                                    />
                                                ))}
                                            </Box>
                                        </Box>
                                    </MainCard>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ mt: -1 }}>
                            <Button variant="outlined" color="black" onClick={() => navigate(location.from ? location.from : -1)}
                                    sx={{ width: "10%" }}>
                                Cancel
                            </Button>
                            <Button onClick={() => handleSave(tenancyData)} variant="contained" color="warning"
                                    sx={{ ml: 2, width: "10%" }}>
                                {isSaving ? <CircularProgress size={24} color="inherit" /> : "Save"}
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>

                <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={snackbarOpen} autoHideDuration={3000}
                          onClose={() => setSnackbarOpen(false)}>
                    <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled" sx={{ width: "100%" }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </>
        );
    }
}
/* 
<MainCard content={true} boxShadow={true} border={false} sx={{ height: "100%", bgcolor: getCardColor(lightMode) }}>
    <Box sx={{ mb: 2 }}>
        <Typography variant="h5" sx={{ mb: 1 }}>Primary</Typography>
        <MuiColorInput format="hex" value={themeColors.primary} onChange={(newValue) => handleColorChange('primary', newValue)} />
    </Box>
    <Box sx={{ mb: 2 }}>
        <Typography variant="h5" sx={{ mb: 1 }}>Secondary</Typography>
        <MuiColorInput format="hex" value={themeColors.secondary} onChange={(newValue) => handleColorChange('secondary', newValue)} />
    </Box>
    <Box>
        <Typography variant="h5" sx={{ mb: 1 }}>Warning (Tertiary)</Typography>
        <MuiColorInput format="hex" value={themeColors.warning} onChange={(newValue) => handleColorChange('warning', newValue)} />
    </Box>
</MainCard>  */