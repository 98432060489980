import { getContrastRatio } from '@mui/material/styles';

export const isLightColor = (color) => {
    if (typeof color !== 'string') {
        return false; 
    }

    const contrastRatio = getContrastRatio(color, '#fff');
    return contrastRatio < 3;
};

export const getTextColor = (color) => {
    const contrastRatio = getContrastRatio(color, '#fff');
    return (contrastRatio < 2) ? '#000' : '#fff';
};

export const getCardColor = (lightMode, on = true) => {
    if (lightMode) {
        return on ?  `#ffffff` : `grey.200`;
    } else {
        return on ? `#69686b` : `#3c3e40`;
    }
}

export const isValidHexCode = (color) => {
    const hexRegex = /^#([A-Fa-f0-9]{3}){1,2}$/;
    return hexRegex.test(color);
}