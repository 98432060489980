import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
    Alert,
    Box,
    Button,
    IconButton,
    Modal,
    Paper,
    Snackbar,
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Typography,
    CircularProgress,
    TextField,
    InputAdornment
} from "@mui/material";
import Table from "../../../components/Table/Table";
import { useGetTenancyBasicsQuery, useUpdateAutoSyncMutation } from "../../../store/services/tenancy.service";
import { useGetUsersFromGroupQuery } from "../../../store/services/user-service";
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import { useTheme } from "@mui/material/styles";
import GroupUsersTable from "../../users/GroupUsersTable";
import { SearchOutlined } from "@mui/icons-material";
import { getCardColor } from "../../../utils/colorUtils"; 


GroupTable.propTypes = {
    type: PropTypes.string.isRequired,
    groupIds: PropTypes.array,
    setGroupId: PropTypes.func
};

export default function GroupTable({ type, setGroupId, groupIds }) {
    const theme = useTheme();
    const lightMode = theme.palette.mode === 'light';
    const { tenancyId } = useSelector((state) => state.tenancy);
    const [selected, setSelected] = useState([]);
    const [editGroups, setEditGroups] = useState(false);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [originalGroups, setOriginalGroups] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 100,
        page: 0
    });
    const [userPaginationModel, setUserPaginationModel] = useState({
        pageSize: 10,
        page: 0
    });

    const { data: tenancyData, error: tenancyError, isLoading: tenancyLoading, refetch } = useGetTenancyBasicsQuery({
        tenancyId: tenancyId
    });

    const { data: groupData, error: groupError, isLoading: groupLoading } = useGetUsersFromGroupQuery({
        groupId: selectedGroupId,
        tenancyId: tenancyId
    }, {
        skip: !selectedGroupId
    });

    const [updateAutoSync] = useUpdateAutoSyncMutation();

    const handleRefresh = useCallback(async () => {
        setIsRefreshing(true);
        await refetch();
        const initialSelectedGroups = tenancyData.groups.filter(group => group.autoSync);
        setSelectedGroups(initialSelectedGroups);

        handleUpdateAutoSync().then(() => {
            setSnackbarSeverity("success");
            setSnackbarMessage("Refreshing users in the selected groups.");
            setSnackbarOpen(true);
        });
        setIsRefreshing(false);
    }, [refetch]);

    useEffect(() => {
        if (tenancyData && tenancyData.groups) {
            const initialSelectedGroups = tenancyData.groups.filter(group => group.autoSync);
            setSelectedGroups(initialSelectedGroups);
            setOriginalGroups(initialSelectedGroups);
        }
    }, [tenancyData]);

    useEffect(() => {
        if (!modalOpen) {
            setUserPaginationModel({
                pageSize: 10,
                page: 0
            });
        }
    }, [modalOpen]);

    const validateAutoSync = () => {
        if (selectedGroups.length === 0) return true;
        const origin = selectedGroups[0].origin;
        return selectedGroups.every(group => group.origin === origin);
    };

    const handleUpdateAutoSync = async () => {
        setIsRefreshing(true);

/*        {"TenancyId":3,"Users":[],
            "Groups":[{"userGroupId":56,"tenancyId":3,"origin":{"type":"office"},
            "name":"without-servers","isManagementAdmin":false,"isTenancyAdmin":false,
            "Id":"9feb08b9-74bd-4fc3-b64e-582888ed11fb","autoSync":true,"refreshToken":"",
            "status":"","googleIdentifier":null,"userGroupLink":[],"searchString":"",
            "dateCreated":"2022-11-25T16:17:00","dateUpdated":"2022-11-25T16:17:29.4043121","AutoSync":true,"CreateMe":true}],
            "AutoSync":true,"Origin":{"type":"office"},"Import":true}*/


        const updatedGroups = selectedGroups.map(group => ({
            ...group,
            CreateMe: true,
            origin: {
                type: "office"
            },
            Id: group.office365Identifier
        }));

        const body = {
            TenancyId: tenancyId,
            Users: [],
            Groups: updatedGroups,
            AutoSync: true,
            Origin: { type: "office" },
            Import: true
        };

        updateAutoSync({ tenancyId, data: body })
            .unwrap()
            .then(() => {
                setIsRefreshing(false);
            })
            .catch((error) => {
                console.error(error);
                setIsRefreshing(false);
            });
    };


    const handleEditGroups = () => {
        if (editGroups) {
            handleUpdateAutoSync().then(() => {
                setSnackbarSeverity("success");
                setSnackbarMessage("Updated auto sync groups.");
                setSnackbarOpen(true);
            });
        } else {
            setOriginalGroups(selectedGroups);
        }
        setEditGroups(!editGroups);
    };

    const handleCancelEdit = () => {
        setSelectedGroups(originalGroups);
        setEditGroups(false);
    };

    const handleOpenModal = (groupId) => {
        setSelectedGroupId(groupId);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedGroupId(null);
        setUserPaginationModel({
            pageSize: 10,
            page: 0
        });
    };

    const getButtonBackgroundColor = (isSelected) => {
        if (isSelected) {
            return !editGroups ? theme.palette.primary.light : theme.palette.primary.main;
        }
        return !editGroups ? theme.palette.grey[100] : theme.palette.grey[200];
    };

    const getButtonColor = (isSelected) => {
        return isSelected && !editGroups ? 'white' : 'inherit';
    };

    const getButtonDisabledBackgroundColor = (isSelected) => {
        return isSelected ? theme.palette.primary.light : theme.palette.grey[100];
    };

    const getButtonDisabledColor = (isSelected) => {
        return isSelected ? 'black' : theme.palette.grey[400];
    };

    const headCells = [
        {
            id: "autoSync",
            label: "Auto Sync",
            render: (dataRow) => {
                const isSelected = selectedGroups.some(group => group.userGroupId === dataRow.userGroupId);
                return (
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            if (editGroups) {
                                let newSelectedGroups;
                                if (isSelected) {
                                    newSelectedGroups = selectedGroups.filter(group => group.userGroupId !== dataRow.userGroupId);
                                } else {
                                    newSelectedGroups = [...selectedGroups, dataRow];
                                }
                                setSelectedGroups(newSelectedGroups);
                            }
                        }}
                        disabled={!editGroups}
                        color={isSelected ? "primary" : "inherit"}
                        sx={{
                            minWidth: 100,
                            '&.Mui-disabled': {
                                color: `${getButtonDisabledColor(isSelected)}!important`,
                            },
                            '&:hover': {
                                backgroundColor: (!isSelected && editGroups) ? lightMode ? theme.palette.grey[100] : theme.palette.black.light : undefined,
                            },
                        }}
                        /*
                        sx={{
                            minWidth: 100,
                            backgroundColor: getButtonBackgroundColor(isSelected),
                            color: getButtonColor(isSelected),
                            '&.Mui-disabled': {
                                backgroundColor: `${getButtonDisabledBackgroundColor(isSelected)}!important`,
                                color: `${getButtonDisabledColor(isSelected)}!important`,
                            },
                            '&:hover': {
                                backgroundColor: !isSelected && editGroups ? theme.palette.grey[50] : undefined,
                            },
                        }}
                        */

                    >
                        {isSelected ? "ON" : "OFF"}
                    </Button>
                );
            },
            initialVisible: type === "edit"
        },
        {
            id: "select",
            label: "Select",
            render: (dataRow) => {
                const isSelected = groupIds?.map(group => group.userGroupId).includes(dataRow.userGroupId);
                return (
                    <IconButton
                        size="small"
                        onClick={() => {
                            if (isSelected) {
                                setGroupId(groupIds.filter(group => group.userGroupId !== dataRow.userGroupId));
                            } else {
                                setGroupId([...groupIds, dataRow]);
                            }
                        }}
                    >
                        {isSelected ? <CheckBoxRoundedIcon color="warning" /> : <CheckBoxOutlineBlankRoundedIcon color={lightMode ? "black" : "white"} />}
                    </IconButton>
                );
            },
            initialVisible: type === "select"
        },
        {
            id: "viewUsers",
            label: "View Users",
            render: (dataRow) => (
                <Button
                    size="small"
                    variant="contained"
                    onClick={() => handleOpenModal(dataRow.userGroupId)}
                    color="warning"
                >
                    View Users
                </Button>
            ),
            initialVisible: true
        },
        { id: "name", label: "Name", initialVisible: true },
        { id: "origin", label: "Origin", initialVisible: true }
        
    ];

    const toolbarActions = [
        {
            render: () => (
                <Box>
                    {type === "edit" && (
                        <>
{/*                            {!editGroups && <Button
                                size="medium"
                                variant="contained"
                                onClick={handleRefresh}
                                sx={{ mr: 1 }}
                                color='warning'
                            >
                                {isRefreshing ? <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} /> : "Refresh"}
                            </Button>}*/}
                            <Button
                                size="medium"
                                variant="contained"
                                sx={{ textTransform: "capitalize", mr: 1 }}
                                aria-haspopup="true"
                                onClick={handleEditGroups}
                            >
                                {editGroups ? "Update Auto Sync" : "Edit Groups"}
                            </Button>
                            {editGroups && (
                                <Button
                                    size="medium"
                                    variant="outlined"
                                    sx={{ textTransform: "capitalize" }}
                                    aria-haspopup="true"
                                    onClick={handleCancelEdit}
                                >
                                    Cancel
                                </Button>
                            )}
                        </>
                    )}
                </Box>
            )
        }
    ];

    /* ------------------- OLD GROUP USERS COMPONENT -------------------

    const UserTable = () => {
        const [searchTerm, setSearchTerm] = useState('');

        const handleChangePage = (event, newPage) => {
            setUserPaginationModel(prev => ({ ...prev, page: newPage }));
        };

        const handleChangeRowsPerPage = (event) => {
            setUserPaginationModel({
                page: 0,
                pageSize: parseInt(event.target.value, 10)
            });
        };

        const filteredData = useMemo(() => {
            return (groupData || []).filter(user =>
                `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                new Date(user.dateCreated).toLocaleDateString().includes(searchTerm)
            );
        }, [groupData, searchTerm]);

        if (tenancyLoading || groupLoading) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                    <CircularProgress />
                </Box>
            );
        }

        return (
            <Paper
                sx={{
                    width: '100%',
                    overflow: 'hidden',
                    display: 'grid',
                    gridTemplateRows: 'auto auto 1fr auto',
                    height: 550, // Increased height to accommodate search bar
                    boxShadow: 3,
                    borderRadius: 2
                }}
            >
                <Box sx={{ p: 2, borderBottom: '1px solid rgba(224, 224, 224, 1)'}}>
                    <TextField
                        size="small"
                        variant="outlined"
                        placeholder="Search Users"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <TableContainer>
                    <MuiTable stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Date Created</TableCell>
                                <TableCell>Email</TableCell>
                            </TableRow>
                        </TableHead>
                    </MuiTable>
                </TableContainer>
                <TableContainer sx={{ overflowY: 'auto' }}>
                    <MuiTable>
                        <TableBody>
                            {filteredData
                                .slice(
                                    userPaginationModel.page * userPaginationModel.pageSize,
                                    userPaginationModel.page * userPaginationModel.pageSize + userPaginationModel.pageSize
                                )
                                .map((user) => (
                                    <TableRow key={user.id}>
                                        <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                                        <TableCell>{new Date(user.dateCreated).toLocaleDateString()}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </MuiTable>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={filteredData.length}
                    page={userPaginationModel.page}
                    onPageChange={handleChangePage}
                    rowsPerPage={userPaginationModel.pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        borderTop: '1px solid rgba(224, 224, 224, 1)',
                        '.MuiTablePagination-toolbar': {
                            height: '52px',
                        }
                    }}
                />
            </Paper>
        );
    };
    */

    return (
        <>
            <Table
                name={"group"}
                data={tenancyData && tenancyData.groups ? tenancyData.groups : []}
                error={tenancyError}
                loading={tenancyLoading}
                headCells={headCells}
                search={true}
                editColumn={false}
                checkbox={false}
                showToolbarActions={true}
                toolbarActions={toolbarActions}
                onDelete={() => console.log("Delete Groups Not Allowed")}
                selected={selected}
                setSelected={setSelected}
                maxTableHeight="calc(100vh - 400px)"
                initialVisibleColumns={headCells.filter(cell => cell.initialVisible).map(cell => cell.label)}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                plural={"groups"}
            />
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled" sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 900,
                    bgcolor: theme.palette.background.default,
                    boxShadow: 24,
                    borderRadius: 2,
                    p: 4,

                }}>
                    <Typography id="modal-modal-title" variant="h4" component="h2" gutterBottom>
                        Group Users
                    </Typography>
                    <GroupUsersTable
                        selectedGroupId={selectedGroupId}
                        closeModal={handleCloseModal}
                    />
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mt:2 }}>
                        <Button onClick={handleCloseModal} variant="outlined" color="black">Close</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
