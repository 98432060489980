import { Grid } from "@mui/material";
import CourseTable from "./CourseTable";

// ==============================|| COURSES - DEFAULT ||============================== //

const CoursesDefault = () => {
    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} md={12} lg={12}>
                <CourseTable type={"edit"} />
            </Grid>
        </Grid>
    );
};

export default CoursesDefault;
