import Navigation from "./Navigation";
import SimpleBar from "components/third-party/SimpleBar";
import PropTypes from "prop-types";

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = ({ open, handleDrawerToggle }) => (
    <SimpleBar
        sx={{
            "& .simplebar-content": {
                display: "flex",
                flexDirection: "column"
            }
        }}
    >
        <Navigation open={open} handleDrawerToggle={handleDrawerToggle} />
    </SimpleBar>
);

DrawerContent.propTypes = {
    handleDrawerToggle: PropTypes.func,
    open: PropTypes.bool
};

export default DrawerContent;
